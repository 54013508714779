import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { API_LISTS_CENTER, API_REPORTS_CENTER_WISE_OLD_ADMISSIONS } from "../../config/Api";

function ViewOldAdmissionReport(props) {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        center_id: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        fetchCenterOptions();
    }, []);

    const fetchCenterOptions = () => {
        props.callRequest("GET", API_LISTS_CENTER, true, null).then((res) => {
            setCenterOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'center_id') {
            setFormValues({ ...formValues, center_id: value });
        }
        else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        props.callRequest("POST", API_REPORTS_CENTER_WISE_OLD_ADMISSIONS, true, formValues).then((res) => {
            setSearchData(res.data.results);
        }).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (formValues.center_id === '') {
            setSearchData([]);
        }
    }, [formValues]);

    return (
        <>
            <Form >
                <Row className="border-bottom pb-3">
                    <Col lg={8}>
                        <Form.Group controlId="center_id">
                            <Form.Label>Franchisee</Form.Label>
                            <Form.Select name="center_id" onChange={handleChange}>
                                <option value="">
                                    All
                                </option>
                                {
                                    Object.keys(centerOptions).map((key) => (
                                        <option key={key} value={key}>
                                            {centerOptions[key]}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            <small className="error">{formValues.center_id === "" && formErrors.center_id}</small>
                        </Form.Group>
                    </Col>

                    <Col lg={4} className="text-end">
                        <div className="mt-3 pt-1"></div>
                        <Button onClick={() => handleSubmit()} className="btn btn-primary mt-2">Search</Button>
                    </Col>
                </Row>

                <Row className="border-bottom py-3">
                    <Col lg={12}>
                        {
                            searchData.length > 0

                                ? (
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Code</th>
                                                <th>Franchisee</th>
                                                <th>Old Admission Count</th>
                                                <th>Old Admission Entry</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchData.map((data) => (

                                                <tr key={data.center_ref}>
                                                    <td className="text-center">{data.center_code}</td>
                                                    <td>
                                                        <Link to={`/study-centers/view/${data.center_ref}`}>{data.center_name}</Link>
                                                    </td>
                                                    <td className="text-center">{data.ongoing_count}</td>
                                                    <td className="text-center">{data.stud_count}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className="d-flex justify-content-center align-items-center p-2">
                                        No records to display
                                    </div>
                                )
                        }
                    </Col>
                </Row>
            </Form>
        </>
    );

}

export default ViewOldAdmissionReport;