import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { API_LISTS_CENTER, API_LISTS_FINANCIAL_YEAR, API_REPORTS_CENTER_WISE_REVENUE } from "../../config/Api";

function ViewCenterRevenueReport(props) {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        center_id: "",
        finacial_year: ""
    });
    const [formErrors, setFormErrors] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [finYearOptions, setFinYearOptions] = useState([]);
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        fetchCenterOptions();
        fetchFinYearOptions();
    }, []);

    const fetchCenterOptions = () => {
        props.callRequest("GET", API_LISTS_CENTER, true, null).then((res) => {
            setCenterOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const fetchFinYearOptions = () => {
        props.callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null).then((res) => {
            setFinYearOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'center_id') {
            setFormValues({ ...formValues, center_id: value });
        }
        else if (name === 'finacial_year') {
            setFormValues({ ...formValues, finacial_year: value });
        }
        else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const validateSubmit = () => {
        const { center_id, finacial_year } = formValues;
        const errors = {};
        let isValid = true;

        if (center_id === "") {
            isValid = false;
            errors.center_id = "Please select the center";
        }
        if (finacial_year === "") {
            isValid = false;
            errors.finacial_year = "Please select the financial year";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateSubmit()) {
            return false;
        }

        props.callRequest("POST", API_REPORTS_CENTER_WISE_REVENUE, true, formValues).then((res) => {
            setSearchData(res.data);
        }).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (formValues.finacial_year === '' || formValues.center_id === '') {
            setSearchData([]);
        }
    }, [formValues]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row className="border-bottom pb-3">
                    <Col lg={4}>
                        <Form.Group controlId="finacial_year">
                            <Form.Label>Financial Year</Form.Label>
                            <Form.Select name="finacial_year" onChange={handleChange}>
                                <option value="">
                                    Select Financial Year
                                </option>
                                {
                                    Object.keys(finYearOptions).map((key) => (
                                        <option key={key} value={finYearOptions[key]}>
                                            {finYearOptions[key]}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            <small className="error">{formValues.finacial_year === "" && formErrors.finacial_year}</small>
                        </Form.Group>
                    </Col>

                    <Col lg={4}>
                        <Form.Group controlId="center_id">
                            <Form.Label>Center</Form.Label>
                            <Form.Select name="center_id" onChange={handleChange}>
                                <option value="">
                                    Select Center
                                </option>
                                {
                                    Object.keys(centerOptions).map((key) => (
                                        <option key={key} value={key}>
                                            {centerOptions[key]}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            <small className="error">{formValues.center_id === "" && formErrors.center_id}</small>
                        </Form.Group>
                    </Col>

                    <Col lg={4} className="text-end">
                        <div className="mt-3 pt-1"></div>
                        <Button type="submit" className="btn btn-primary mt-2">Search</Button>
                    </Col>
                </Row>

                <Row className="border-bottom py-3">
                    <Col lg={4}>
                        {
                            searchData.count > 0 && searchData
                                ?
                                <div className="">
                                    <h6>Total <strong>{searchData.count}</strong> record(s) found</h6>
                                    <Link to={searchData.file_path} target="_blank">
                                        <div className="d-flex align-items-center">
                                            <i className="las la-file-download me-2"></i> Download in excel
                                        </div>
                                    </Link>
                                </div>

                                : "No data found"
                        }
                    </Col>
                </Row>
            </Form>
        </>
    );

}

export default ViewCenterRevenueReport;