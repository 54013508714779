import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_ROLE_CREATE } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";


function NewRoleForm(props) {
  const navigate = useNavigate();
  const [btnEnable, setBtnEnable] = useState(false);
  const [formValues, setFormValues] = useState({
    role_name: "",
  });
  const [formErrors, setFormErrors] = useState({});



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateRoleSubmit = () => {
    const { role_name } = formValues;
    const errors = {};
    let isValid = true;

    if (role_name === "") {
      isValid = false;
      errors.role_name = "Role name is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleRoleSubmit = (e) => {
    e.preventDefault();
    if (!validateRoleSubmit()) {
      return false;
    }
    setBtnEnable(true);
    props
      .callRequest("POST", API_ROLE_CREATE, true, formValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          navigate("/roles");
        }, 5000);
      })
      .catch((e) => {
        setBtnEnable(false);
        console.log(e);
        toast.error(`${e.res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };


  return (
    <>
      <Form onSubmit={handleRoleSubmit}>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="role_name">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                name="role_name"
                type="text"
                value={formValues.role_name ?? ""}
                placeholder="Enter Role Name"
                autoComplete="off"
                onChange={handleChange}
              ></Form.Control>
              <small className="error">{formValues.role_name === "" && formErrors.role_name}</small>
            </Form.Group>
          </Col>

          <Col>
            <div className="mt-3 text-end">
              <Button disabled={btnEnable ? true : false} type="submit" className="btn btn-primary mt-2">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default NewRoleForm;
