import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_LISTS_CENTER, API_USERS_CREATE } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

function NewUserForm(props) {
    const navigate = useNavigate();

    const getRoleOption = [
        { label: "Select Role", value: "" },
        { label: "Super Admin", value: "1" },
        { label: "Management", value: "2" },
        { label: "Operation Admin", value: "3" },
        { label: "Data Entry Operator", value: "4" },
        { label: "Study Center", value: "5" },
    ];

    const initialValues = {
        user_name: "",
        password: "",
        fullname: "",
        email: "",
        phone: "",
        u_role_id: "",
        u_ct_ref: "",
        alternate_email: "",
        alternate_phone: "",
    };

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [isCenterOptionsVisible, setIsCenterOptionsVisible] = useState(false);
    const [btnEnable, setBtnEnable] = useState(false);

    useEffect(() => {
        fetchCenterOptions();
    }, []);

    const fetchCenterOptions = () => {
        props.callRequest("GET", API_LISTS_CENTER, true, null).then((res) => {
            setCenterOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const validateUser = () => {
        const { user_name, password, fullname, email, phone, u_role_id, u_ct_ref, alternate_email } = formValues;
        const errors = {};
        let isValid = true;

        if (user_name === "") {
            isValid = false;
            errors.user_name = "Username is required";
        }
        if (password === "") {
            isValid = false;
            errors.password = "Password is required";
        }
        if (email === "") {
            isValid = false;
            errors.email = "Email is required";
        } else if (email !== "" && !props.validateEmail(email)) {
            isValid = false;
            errors.email_not_valid = "Email is not valid";
        }
        if (alternate_email !== "" && !props.validateEmail(alternate_email)) {
            isValid = false;
            errors.alternate_email_not_valid = "Alternate email is not valid";
        }
        if (fullname === "") {
            isValid = false;
            errors.fullname = "Full name is required";
        } else if (fullname !== "" && !props.checkAlphaOnly(fullname)) {
            isValid = false;
            errors.fullname_not_valid = "Valid entry only alphabets, space and dot";
        }
        if (u_role_id === "") {
            isValid = false;
            errors.u_role_id = "Role is required";
        }
        if (u_role_id === '5' && u_ct_ref === "") {
            isValid = false;
            errors.u_ct_ref = " Franchisee is required";
        }
        if (phone === "") {
            isValid = false;
            errors.phone = "Phone number is required";
        } else if (phone.length > 10) {
            isValid = false;
            errors.phone = "Phone number must be 10 digit";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleUserChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleUserRoleChange = (event) => {
        const { value } = event.target;
        setFormValues({ ...formValues, u_role_id: value });
        setIsCenterOptionsVisible(value === "5");
    };

    const handleUserSubmit = (e) => {
        e.preventDefault();
        if (!validateUser()) {
            return false;
        }
        setBtnEnable(true);
        props.callRequest("POST", API_USERS_CREATE, true, formValues).then((res) => {
            toast.success(`${res.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
            });
            setTimeout(() => {
                navigate("/users");
            }, 5000);

        }).catch((e) => {
            setBtnEnable(false);
            toast.error(`${e.response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
            });
            console.log(e);
        });
    };

    return (
        <>
            <Form onSubmit={handleUserSubmit}>
                <ToastContainer />
                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group controlId="u_role_id">
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                name="u_role_id"
                                value={formValues.u_role_id || ""}
                                onChange={handleUserRoleChange}
                                className="form-select"
                            >
                                {getRoleOption.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                            <small className="error">{formValues.u_role_id === "" && formErrors.u_role_id}</small>
                        </Form.Group>
                    </Col>

                    {isCenterOptionsVisible && (
                        <Col lg={8}>
                            <Form.Group controlId="u_ct_ref">
                                <Form.Label>Franchisee</Form.Label>
                                <select
                                    name="u_ct_ref"
                                    value={formValues.u_ct_ref}
                                    onChange={handleUserChange}
                                    className="form-select"
                                >
                                    <option value="">
                                        Select  Franchisee
                                    </option>
                                    {
                                        Object.keys(centerOptions).map((key) => (
                                            <option key={key} value={key}>
                                                {centerOptions[key]}
                                            </option>
                                        ))
                                    }
                                </select>
                                <small className="error">{formValues.u_ct_ref === "" && formErrors.u_ct_ref}</small>
                            </Form.Group>
                        </Col>
                    )}
                    <Col lg={4}>
                        <Form.Group controlId="user_name">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                name="user_name"
                                type="text"
                                value={formValues.user_name}
                                placeholder="Enter Username"
                                autoComplete="off"
                                onChange={handleUserChange}
                            ></Form.Control>
                            <small className="error">{formValues.user_name === "" && formErrors.user_name}</small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="password"
                                type="password"
                                value={formValues.password}
                                placeholder="Enter Password"
                                autoComplete="off"
                                onChange={handleUserChange}
                            ></Form.Control>
                            <small className="error">{formValues.password === "" && formErrors.password}</small>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group controlId="fullname">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                name="fullname"
                                type="text"
                                value={formValues.fullname}
                                placeholder="Enter Full Name"
                                onChange={handleUserChange}
                            ></Form.Control>
                            <small className="error">
                                {formValues.fullname === ""
                                    ? formErrors.fullname
                                    : formValues.fullname !== "" &&
                                        !props.checkAlphaOnly(formValues.fullname)
                                        ? formErrors.fullname_not_valid
                                        : ""}
                            </small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={formValues.phone}
                                placeholder="Enter Phone Number"
                                onChange={handleUserChange}
                                onKeyDown={props.handleKeyPress}
                                maxLength={10}
                            />
                            <small className="error">{formValues.phone === "" && formErrors.phone}</small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="alternate_phone">
                            <Form.Label> Alternate Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="alternate_phone"
                                value={formValues.alternate_phone}
                                placeholder="Enter alternate phone number"
                                onChange={handleUserChange}
                                onKeyDown={props.handleKeyPress}
                                maxLength={10}
                            />
                            <small className="error">{formValues.alternate_phone === "" && formErrors.alternate_phone}</small>
                        </Form.Group>
                    </Col>
                </Row>



                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group controlId="email">
                            <Form.Label>Email </Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                value={formValues.email}
                                placeholder="Enter Email Address"
                                onChange={handleUserChange}
                            />
                            <small className="error">
                                {formValues.email === ""
                                    ? formErrors.email
                                    : formValues.email !== "" &&
                                        !props.validateEmail(formValues.email)
                                        ? formErrors.email_not_valid
                                        : ""}
                            </small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="alternate_email">
                            <Form.Label> Alternate Email </Form.Label>
                            <Form.Control
                                type="text"
                                name="alternate_email"
                                value={formValues.alternate_email}
                                placeholder="Enter alternate Email Address"
                                onChange={handleUserChange}
                            />
                            <small className="error">
                                {formValues.alternate_email !== "" &&
                                    !props.validateEmail(formValues.alternate_email)
                                    && formErrors.alternate_email_not_valid
                                }
                            </small>
                        </Form.Group>
                    </Col>
                </Row>

                <div className="mt-2 text-end">
                    <Button disabled={btnEnable ? true : false} type="submit" className="btn btn-primary mt-2">
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default NewUserForm;
