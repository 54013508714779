import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  API_ADMISSIONS_ASSIGN_BATCH,
  API_ADMISSIONS_VIEW,
  API_BATCHES,
  API_WEB_DOMAIN,
} from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

const BatchAssign = (props) => {
  const { studentRef } = props;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [batchData, setBatchData] = useState([]);
  const [formValues, setFormValues] = useState({
    registration_num: "",
    batch_id: "",
  });

  const [btnEnable, setBtnEnable] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [apiData, setApiData] = useState(null);
  const [batchApiData, setBatchApiData] = useState([]);
  const [batchListOptions, setBatchListOptions] = useState([]);
  const [batchScheduleData, setBatchScheduleData] = useState([]);

  let center_ref = "";
  let user_role = "";

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    user_role = user.role.role_name;

    if (user_role == "CENTER ADMIN") {
      center_ref = "/" + center.ref;
    }
  }

  useEffect(() => {
    fetchBatchList();
    fetchStudent();
  }, []);

  const fetchBatchList = () => {
    setLoading(true);
    props
      .callRequest("GET", API_BATCHES + center_ref, true, null)
      .then((res) => {
        setLoading(false);
        setBatchData(res.data.batches.length > 0 ? res.data.batches : []);
        setBatchListOptions(
          res.data.batches.length > 0 ? res.data.batches : []
        );
        setBatchApiData(res.data.batches.length > 0 ? res.data.batches : []);
        setBatchScheduleData(
          res.data.batches.length > 0 ? res.data.batches : []
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchStudent = () => {
    props
      .callRequest("GET", API_ADMISSIONS_VIEW + "/" + studentRef, true, null)
      .then((res) => {
        setFormValues({
          ...formValues,
          registration_num: res.data.admission_info.student_info.stud_reg_no,
        });
        setApiData(res.data.admission_info);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleBatchSelect = (e) => {
    const { value } = e.target;

    const foundBatch = batchData.find(
      (item) => item.ba_id === Number(value)
    );

    if (foundBatch) {
      setSelectedBatch({
        ...foundBatch,
        ba_end_date: props.getFormatedDate(foundBatch.ba_end_date),
        ba_start_date: props.getFormatedDate(foundBatch.ba_start_date),
      });
      const ba_schedule = JSON.parse(foundBatch.ba_schedule);
      const temp_ba_schedule = ba_schedule !== null ? ba_schedule : [];
      setBatchScheduleData(temp_ba_schedule);
    } else {
      setSelectedBatch({});
      setBatchScheduleData([]);
    }
    setFormValues({ ...formValues, batch_id: value });
  };

  const validateBatchAssign = () => {
    const errors = {};
    let isValid = true;

    if (formValues.batch_id === "") {
      isValid = false;
      errors.batch_id = "Batch Name is required.";
    }

    setFormErrors(errors);
    return isValid;
  };

  const saveBatchAssign = (e) => {
    e.preventDefault();
    if (!validateBatchAssign()) {
      return false;
    }
    setBtnEnable(true);

    props
      .callRequest("POST", API_ADMISSIONS_ASSIGN_BATCH, true, formValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          navigate(`/admissions/view/${studentRef}`);
        }, 5000);
      })
      .catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      <div>
        {apiData ? (
          <div className="project">
            <div className="row border-bottom pb-3">
              <div className="col-xl-8 col-lg-8">
                <div className="d-flex align-items-center justify-content-start">
                  <img
                    src={API_WEB_DOMAIN + apiData.student_info.stud_pic}
                    alt=""
                    className="admission-profile-thumb me-3"
                  />
                  <div className="ms-3">
                    <h4 className="fw-bold m-0 me-2">
                      {apiData.student_info.stud_fullname}
                    </h4>
                    <h5>
                      <Link
                        to={`/admissions/view/${apiData.student_info.stud_ref}`}
                        target="_blank"
                      >
                        {apiData.student_info.stud_reg_no}
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="d-flex align-items-center">
                  <i className="las la-phone me-2"></i>
                  <span>{apiData.student_info.stud_phone}</span>
                  <span>&nbsp;/&nbsp;{apiData.student_info.stud_phone2}</span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="las la-envelope me-2"></i>
                  <span>{apiData.student_info.stud_email}</span>
                </div>
              </div>
            </div>

            <div className="row border-bottom py-3">
              <Col lg={12}>
                <h5 className="fw-bold">
                  <Link
                    to={`/courses/view/${apiData.course_info.course_ref}`}
                    target="_blank"
                  >
                    {apiData.course_info.course_name} - [
                    {apiData.course_info.course_code}]
                  </Link>
                </h5>
              </Col>
              <Col lg={4}>
                <div className="text-muted lbl-w72">Fees:</div>{" "}
                {props.getCurrencyFormat(apiData.course_info.course_attributes.ca_fees)}
              </Col>
              <Col lg={4}>
                <div className="text-muted lbl-w72">Duration:</div>{" "}
                {apiData.course_info.course_attributes.ca_duration_hours} hours /{" "}
                {apiData.course_info.course_attributes.ca_duration_months} months
              </Col>
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>

      <div className="row border-bottom py-3">
        <Form>
          <ToastContainer />
          {batchApiData.length > 0 ? (
            <Row>
              <input
                type="hidden"
                name="registration_num"
                value={formValues.registration_num || ""}
                onChange={handleChange}
              />

              <Col lg={4}>
                <Form.Group controlId="batch_list">
                  <Form.Label>Batch</Form.Label>
                  <Form.Select name="batch_list" onChange={handleBatchSelect}>
                    <option value="">Select Batch</option>
                    {batchListOptions.map((batch) => (
                      <option key={batch.ba_id} value={batch.ba_id}>
                        {batch.ba_batch_name}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.batch_id}</small>
                </Form.Group>
              </Col>

              <Col lg={2}>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  style={{ marginTop: "1.8rem" }}
                  onClick={(e) => {
                    saveBatchAssign(e);
                  }}
                  disabled={btnEnable ? true : false}
                >
                  Assign
                </Button>
              </Col>
            </Row>
          ) : loading ? null : (
            <span>
              No batch available,{" "}
              <Link to="/batches/new" target="_blank">
                create a new batch
              </Link>
            </span>
          )}
        </Form>
      </div>
      <br />
      {batchApiData.length > 0 ? (
        <>
          <div className="row border-bottom pb-3">
            <div className="col-xl-3 col-sm-6 mt-4">
              <h5 className="fw-bold text-muted">Batch Name</h5>

              <span>{selectedBatch.ba_batch_name}</span>
            </div>

            <div className="col-xl-3 col-sm-6 mt-4">
              <h5 className="fw-bold text-muted">Financial Year</h5>
              <span>{selectedBatch.ba_fy}</span>
            </div>
            <div className="col-xl-3 col-sm-6 mt-4">
              <h5 className="fw-bold text-muted">Start Date</h5>
              <span>{selectedBatch.ba_start_date}</span>
            </div>
            <div className="col-xl-3 col-sm-6 mt-4">
              <h5 className="fw-bold text-muted">End Date</h5>
              <span>{selectedBatch.ba_end_date}</span>
            </div>
          </div>

          <div className="row border-bottom pb-3">
            <div className="col-xl col-sm mt-4">
              <h5 className="fw-bold text-muted">Batch Schedule</h5>
              <Table className="table table-bordered table-striped" responsive>
                <thead>
                  <tr>
                    <th>Days</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Duration</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedBatch !== "" &&
                    batchScheduleData.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{items.day_name}</td>
                          <td>{items.day_start_time}</td>
                          <td>{items.day_end_time}</td>
                          <td>{items.day_duration}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default BatchAssign;
