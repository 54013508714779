import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_COURSE_UPDATE, API_COURSE_VIEW } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

function EditCourseForm(props) {
  const { courseRef, convertDateStringToYMD } = props;

  const getCourseOption = [
    { label: "Select Course", value: "" },
    { label: "Cerificate Course", value: "1" },
    { label: "Diploma Course", value: "2" },
  ];

  const initialValues = {
    course_name: "",
    course_code: "",
    course_eligibility_criteria: "",
    course_content: "",
    course_duration_hours: "",
    course_duration_months: "",
    course_fees: "",
    course_royalty: "",
    course_impact: "0",
    course_type: "",
    fees_effective_from: "",
    fees_effective_to: "",
    course_isactive: "1",
    ca_id: "",
    admission_amount: []
  };

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [btnEnable, setBtnEnable] = useState(false);
  const [course, setCourse] = useState({
    course_pay_amt: 0,
  });

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = () => {
    let courseParamsDate = convertDateStringToYMD(new Date());
    props.callRequest("GET", API_COURSE_VIEW + "/" + courseParamsDate + "/" + courseRef, true, null)
      .then((res) => {
        let ins_amt_arr = JSON.parse(res.data.course.course_attributes.ca_payment_terms);
        let temp_amt_arr = ins_amt_arr.map((item) => {
          item.status = false
          return item
        })
        setFormValues({
          ...formValues,
          course_name: res.data.course.course_name,
          course_code: res.data.course.course_code,
          course_eligibility_criteria: res.data.course.course_attributes.ca_eligibility_criteria,
          course_content: res.data.course.course_attributes.ca_content,
          course_duration_hours: res.data.course.course_attributes.ca_duration_hours,
          course_duration_months: res.data.course.course_attributes.ca_duration_months,
          course_fees: res.data.course.course_attributes.ca_fees,
          course_type: res.data.course.course_type,
          course_royalty: res.data.course.course_attributes.ca_royalty,
          admission_amount: temp_amt_arr,
          fees_effective_from: res.data.course.course_attributes.ca_valid_from,
          fees_effective_to: res.data.course.course_attributes.ca_valid_to,
          course_impact: res.data.course.is_impact,
          ca_id: res.data.course.course_attributes.ca_id,
          course_isactive: res.data.course.is_active,
        });
      }).catch((e) => {
        console.log(e);
      });
  };

  const handlePaymentChange = (instalment_num, field, value) => {
    const temp_state = formValues.admission_amount.map((item) => {
      if (item.instalment_num === instalment_num) {
        item[field] = value
      }
      return item;
    })
    setFormValues({ ...formValues, admission_amount: temp_state });
  };

  const validateCourse = () => {
    const {
      course_name,
      course_code,
      course_eligibility_criteria,
      course_content,
      course_duration_hours,
      course_duration_months,
      course_fees,
      course_royalty,
      course_type,
      admission_amount,
      course_date_from,
      course_date_to,
    } = formValues;
    const errors = {};
    let isValid = true;
    let course_pay_amount = 0;

    if (course_name === "") {
      isValid = false;
      errors.course_name = "Course name is required";
    }
    if (course_code === "") {
      isValid = false;
      errors.course_code = "Course code is required";
    }
    if (course_eligibility_criteria === "") {
      isValid = false;
      errors.course_eligibility_criteria =
        "Course Eligibility Criteria is required";
    }
    if (course_content === "") {
      isValid = false;
      errors.course_content = "Course Content is required";
    }
    if (course_duration_hours === "") {
      isValid = false;
      errors.course_duration_hours = "Course Duration hours is required";
    }

    if (course_duration_months === "") {
      isValid = false;
      errors.course_duration_months = "Course Duration Months is required";
    }

    if (course_fees === "") {
      isValid = false;
      errors.course_fees = "Course Fees is required";
    }
    if (course_royalty === "") {
      isValid = false;
      errors.course_royalty = "Royalty is required";
    }
    if (course_type === "") {
      isValid = false;
      errors.course_type = "Course Type is required";
    }
    if (course_date_from === "") {
      isValid = false;
      errors.course_date_from = "Fees effective from is required";
    }
    if (course_date_to === "") {
      isValid = false;
      errors.course_date_to = "Fees effective to is required";
    }

    admission_amount.map((item) => {
      course_pay_amount += Number(item.instalment_amt);
      setCourse(prevState => { return { ...prevState, course_pay_amt: course_pay_amount }; });
      if (item.instalment_amt === "") {
        isValid = false;
        errors.instalment_amt = "Installment amount is requird";
      }
      if (course_pay_amount > course_fees) {
        isValid = false;
        errors.instalment_amt_error = "Please check course fees amount"
      }
      if (item.instalment_amt) {
        const updatedFormData = formValues.admission_amount.map((item) => {
          item.status = true;
          return item
        })
        setFormValues({ ...formValues, admission_amount: updatedFormData });
      }
    });

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "course_isactive" || name === "course_impact") {
      setFormValues({
        ...formValues,
        [name]: checked ? 1 : 0,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleCourseTypeChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, course_type: value });
  };

  const handleCourseSubmit = (e) => {
    e.preventDefault();
    if (!validateCourse()) {
      return false;
    }
    setBtnEnable(true);
    const tempArr = formValues.admission_amount.map((item) => {
      const { status, ...rest } = item
      return rest
    })
    const postData = { ...formValues, admission_amount: tempArr }
    props
      .callRequest("POST", API_COURSE_UPDATE + "/" + courseRef, true, postData)
      .then((res) => {
        console.log(res, 'res');
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      })
      .catch((e) => {
        setBtnEnable(false);
        console.log(e);
      });
  };



  return (
    <>
      <Form onSubmit={handleCourseSubmit}>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={8}>
            <Form.Group controlId="course_name">
              <Form.Label>Course Name</Form.Label>
              <Form.Control
                type="text"
                name="course_name"
                value={formValues.course_name ?? ""}
                placeholder="Enter Course Name"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.course_name === "" && formErrors.course_name}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="course_code">
              <Form.Label>Course Code</Form.Label>
              <Form.Control
                type="text"
                name="course_code"
                value={formValues.course_code ?? ""}
                placeholder="Enter Course Code"
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
                maxLength={3}
                autoComplete="off"
              />
              <small className="error">
                {formValues.course_code === "" && formErrors.course_code}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="course_type">
              <Form.Label>Course Type</Form.Label>
              <Form.Select
                value={formValues.course_type ?? ""}
                onChange={handleCourseTypeChange}
              >
                {getCourseOption.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.course_type === "" && formErrors.course_type}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="course_duration_hours">
              <Form.Label>Duration (Hours)</Form.Label>
              <Form.Control
                name="course_duration_hours"
                placeholder="Enter Course Duration hours"
                value={formValues.course_duration_hours ?? ""}
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
                maxLength={3}
              />
              <small className="error">
                {formValues.course_duration_hours === "" && formErrors.course_duration_hours}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="course_duration_months">
              <Form.Label>Duration (Months)</Form.Label>
              <Form.Control
                name="course_duration_months"
                placeholder="Enter Course Duration Months"
                value={formValues.course_duration_months ?? ""}
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
                maxLength={2}
              />
              <small className="error">
                {formValues.course_duration_months === "" && formErrors.course_duration_months}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={8}>
            <Form.Group controlId="course_eligibility_criteria">
              <Form.Label>Eligibility Criteria</Form.Label>
              <Form.Control
                type="text"
                name="course_eligibility_criteria"
                value={formValues.course_eligibility_criteria ?? ""}
                placeholder="Enter Eligibility Criteria"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.course_eligibility_criteria === "" && formErrors.course_eligibility_criteria}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="">
              <div className="mt-3 pt-4">
                <input
                  type="checkbox"
                  name="course_impact"
                  checked={formValues.course_impact === 1 ? true : false}
                  onChange={handleChange}
                />
                <span className="ms-2">Impact Course</span>
                <small className="error"></small>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={12}>
            <Form.Group controlId="course_content">
              <Form.Label>Course Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="course_content"
                value={formValues.course_content ?? ""}
                placeholder="Enter Course Content"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.course_content === "" && formErrors.course_content}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <div className="row mb-3">
          <div className="col-xl-12 col-sm-12 mt-4">
            <h5 className="fw-bold text-muted">Payment Terms</h5>
          </div>
        </div>

        <Row className="mb-3">
          <Col lg={3}>
            <Form.Group controlId="course_fees">
              <Form.Label>Course Fees</Form.Label>
              <Form.Control
                type="number"
                name="course_fees"
                value={formValues.course_fees ?? ""}
                placeholder={props.getCurrencyFormat(0)}
                onChange={handleChange}
                min={0}
              />
              <small className="error">
                {formValues.course_fees === "" && formErrors.course_fees}
              </small>
            </Form.Group>
          </Col>

          <Col lg={3}>
            <Form.Group controlId="course_royalty">
              <Form.Label>Royalty (%)</Form.Label>
              <Form.Control
                type="text"
                name="course_royalty"
                value={formValues.course_royalty ?? ""}
                placeholder="Enter royalty in %"
                onChange={handleChange}
                autoComplete="off"
                onKeyDown={props.handleKeyPress}
              />
              <small className="error">
                {formValues.course_royalty === "" && formErrors.course_royalty}
              </small>
            </Form.Group>
          </Col>

          <Col lg={3}>
            <Form.Group controlId="fees_effective_from">
              <Form.Label>Fees Effective From</Form.Label>
              <Form.Control
                type="date"
                name="fees_effective_from"
                value={formValues.fees_effective_from ?? ""}
                placeholder="dd/mm/yyyy"
                onChange={handleChange}
              />
              <small className="error">
                {formValues.fees_effective_from === "" && formErrors.fees_effective_from}
              </small>
            </Form.Group>
          </Col>

          <Col lg={3}>
            <Form.Group controlId="fees_effective_to">
              <Form.Label>Fees Effective To</Form.Label>
              <Form.Control
                type="date"
                name="fees_effective_to"
                value={formValues.fees_effective_to ?? ""}
                placeholder="dd/mm/yyyy"
                onChange={handleChange}
              />
              <small className="error">
                {formValues.fees_effective_to === "" && formErrors.fees_effective_to}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <div className="row border-bottom pb-3">
          <div className="col-xl-12 col-sm-12 mt-4">
            <h5 className="fw-bold text-muted">Fees Break-up</h5>
          </div>
          <div className="d-flex justify-content-start flex-wrap">
            {formValues.admission_amount.map((installment, i) => (
              <>
                <div key={installment.instalment_num} className="my-2 me-5 px-2">
                  <h6>Installment - {i + 1}</h6>
                  <Form.Control
                    type="number"
                    name="instalment_num"
                    value={installment.instalment_amt}
                    min={0}
                    onChange={(e) =>
                      handlePaymentChange(
                        installment.instalment_num,
                        "instalment_amt",
                        e.target.value
                      )}
                  />
                  <small className="error">
                    {
                      installment.instalment_amt === ""
                        ? formErrors.instalment_amt
                        : course.course_pay_amt > formValues.course_fees
                          ? formErrors.instalment_amt_error : ""
                    }
                  </small>
                </div>
              </>
            ))}

          </div>
        </div>
        <div className="mt-2 text-start">
          <input
            type="checkbox"
            name="course_isactive"
            checked={formValues.course_isactive === 1 ? true : false}
            onChange={handleChange}
          />
          <span className="ms-2">Is Active</span>
        </div>
        <div>
          <input
            type="hidden"
            name="ca_id"
            value={formValues.ca_id}
          />
        </div>
        <div className="mt-2 text-end">
          <Button type="submit" className="btn btn-primary mt-2"
            disabled={btnEnable ? true : false}
          >
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}
export default EditCourseForm;
