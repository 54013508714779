import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  API_ADMISSIONS,
  API_WEB_DOMAIN,
} from "../../config/Api";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../helpers/DataTableSettings";
import RoleBasedButton from "../shared/RoleBasedButton";

function ListAdmission(props) {

  const [admissionFormValues, setAdmissionFormValues] = useState({
    search_by: "",
    search_keyword: "",
  });
  const [showAdmission, setShowAdmission] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [studentData, setStudentData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const searchParam = [
    "stud_fullname",
    "stud_reg_no",
    "stud_reg_no2",
    "course_name",
    "ba_batch_name",
  ];
  const roles = ['SUPERADMIN', 'CENTER ADMIN', 'ADMIN'];
  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [close, setClose] = useState(false);
  const [page] = useState(1);
  const [newPerPage, setNewPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const handleAdmissionModalToggle = () => setShowAdmission(!showAdmission);

  let center_ref = "";
  let user_role = "";
  const admission_route_flag = localStorage.getItem("admission_route_flag");

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    user_role = user.role.role_name;

    if (user_role === "CENTER ADMIN") {
      center_ref = center.ref;
    }
  }

  const admissionSearchOptions = [
    { value: 'Select', label: 'Select Options' },
    { value: 'FINANCIAL YEAR', label: `${props.getFinancialYear()}` },
    ...(user_role === 'SUPERADMIN' || user_role === 'ADMIN'
      ? [{ value: 'FRANCHISEE', label: 'Webel Education or 001 ' }]
      : []),
    { value: 'NAME', label: 'Firstname or Lastname or Fullname' },
    { value: 'REGISTRATION', label: '2324000001' },
    { value: 'CERTIFICATE', label: '123456789' },
    { value: 'PHONE', label: '9876543210' },
  ];

  const getUrlQueryParam = (page, newPerPage) => {
    const url_query_param = API_ADMISSIONS + "?page_number=" + page + "&page_size=" + newPerPage + "&center_ref=" + center_ref
      + "&search_by=" + admissionFormValues.search_by + "&search_keyword=" + admissionFormValues.search_keyword;
    return url_query_param;
  };

  useEffect(() => {
    fetchStudents(1, 10);
  }, []);

  const fetchStudents = (page) => {
    setLoadingIndicator(true);
    const url_query_param = getUrlQueryParam(page, newPerPage);
    props
      .callRequest("GET", `${url_query_param}`, true, null)
      .then((res) => {
        setStudentData(res.data.students);
        setTotalRows(res.data.total_admission);
        setLoadingIndicator(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resetStudents = () => {
    setLoadingIndicator(true);
    const url_query_param = API_ADMISSIONS + "?page_number=" + page + "&page_size=" + newPerPage + "&center_ref=" + center_ref
      + "&search_by=&search_keyword=";
    props
      .callRequest("GET", `${url_query_param}`, true, null)
      .then((res) => {
        setStudentData(res.data.students);
        setTotalRows(res.data.total_admission);
        setLoadingIndicator(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (page) => {
    fetchStudents(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLoadingIndicator(true);
    const url_query_param = getUrlQueryParam(page, newPerPage);
    props.callRequest("GET", `${url_query_param}`, true, null).then((res) => {
      setStudentData(res.data.students);
      setNewPerPage(newPerPage);
      setLoadingIndicator(false);
    });
  };

  const handleAdmissionFilterChange = (e) => {
    const { name, value } = e.target;
    setAdmissionFormValues({ ...admissionFormValues, [name]: value });
  };

  const handleAdmissionFilterTypeChange = (event) => {
    const { value } = event.target;
    setAdmissionFormValues({ ...admissionFormValues, search_by: value, search_keyword: '' });
  };

  const getAdmissionFilterData = () => {
    const selectedOption = admissionSearchOptions.find(option => option.value === admissionFormValues.search_by);
    return selectedOption ? `e.g. ${selectedOption.label}` : 'Search Keyword';
  };

  const validateAdmission = () => {
    const errors = {};
    let isValid = true;

    if (
      admissionFormValues.search_by === "" ||
      admissionFormValues.search_by === undefined
    ) {
      isValid = false;
      errors.search_by = "Search By is required";
    }
    if (
      admissionFormValues.search_keyword === "" ||
      admissionFormValues.search_keyword === undefined
    ) {
      isValid = false;
      errors.search_keyword = "Search Keyword is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const searchAdmission = () => {
    if (!validateAdmission()) {
      return false;
    }
    setLoadingIndicator(true);
    const url_query_param = getUrlQueryParam(page, newPerPage);
    props
      .callRequest("GET", `${url_query_param}`, true, null)
      .then((res) => {
        setStudentData(res.data.students);
        setTotalRows(res.data.total_admission);
        setLoadingIndicator(false);
        handleAdmissionModalToggle();
        setClose(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columnsArr = [
    {
      name: <h6>Student Name</h6>,
      selector: (row) => row.stud_fullname,
      sortable: true,
      width: "30%",
      cell: (row) => (
        <>
          <img
            src={API_WEB_DOMAIN + row.stud_pic}
            alt=""
            className="admission-profile-thumb mt-1 mb-1"
          />
          <div className="d-flex flex-column">
            <div className="fw-bold ms-2">
              {row.stud_fullname}
            </div>
          </div>
        </>
      ),
    },
    {
      name: <h6>Registr. No.</h6>,
      selector: (row) => row.stud_reg_no,
      sortable: true,
      /* width: `${roles.includes(user_role) ? "17%" : "22%"}`, */
      width: "15%",
      cell: (row) => (
        <div className="d-flex flex-column">
          <div>
            <Link
              to={`/admissions/view/${row.stud_ref}`}
              className="ms-2">
              {row.stud_reg_no}
            </Link>
          </div>
          <div className="mt-1">
            <span className="mx-2">
              {props.getFormatedDate(row.stud_admission_date)}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: <h6>Franchisee</h6>,
      selector: (row) => row.center.length > 0 ? row.center[0].centerdetails.ct_name : "",
      sortable: false,
      width: "25%",
      cell: (row) => (
        <div className="d-flex">
          <Link to={`/study-centers/view/${row.center[0].centerdetails.ct_ref}`}>
            {row.center[0].centerdetails.ct_name} - {row.center[0].centerdetails.ct_location}
          </Link>
        </div>
      ),
    },
    {
      name: <h6>Course</h6>,
      selector: (row) =>
        row.course.length > 0 ? row.course[0].coursedetails.course_name : "",
      sortable: true,
      /* width: `${roles.includes(user_role) ? "25%" : "30%"}`, */
      width: "20%",
      cell: (row) => (
        <div className="d-flex">
          <Link to={`/courses/view/${row.course[0].coursedetails.course_ref}`}>
            {row.course[0].coursedetails.course_name}
          </Link>
        </div>
      ),
    },
    {
      name: <h6>Action</h6>,
      width: "10%",
      center: true,
      cell: (row) => (
        <RoleBasedButton
          type="edit_admission"
          url={`/admissions/edit/${row.stud_ref}`}
          user_role={user_role}
        />
      ),
    },
  ];

  const columns = roles.includes(user_role) ? columnsArr : columnsArr.slice(0, -1);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>Available Admissions</h5>
          <div className="d-flex justify-content-end">
            {!close ? (
              <Button
                className="link-action"
                onClick={() => {
                  handleAdmissionModalToggle();
                  setAdmissionFormValues({
                    ...admissionFormValues, search_by: "",
                    search_keyword: ""
                  });
                }}
              >
                <i className="las la-filter me-2"></i>Filter
              </Button>
            ) : (
              <Button
                className="link-action"
                onClick={() => {
                  setClose(false);
                  resetStudents();
                  setAdmissionFormValues({
                    ...admissionFormValues, search_by: "",
                    search_keyword: ""
                  });
                }}
              >
                <i className="las la-times me-2"></i>Filter
              </Button>
            )}

            <RoleBasedButton
              type="add_admission"
              url={admission_route_flag === "0" ? "/admissions/old" : "/admissions/new"}
              user_role={user_role}
            />
          </div>
        </div>

        <div className="card-body">
          <Modal
            show={showAdmission}
            onHide={handleAdmissionModalToggle}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col lg={5}>
                    <Form.Group className="mb-3" controlId="search_by">
                      <Form.Label>Search By</Form.Label>
                      <select
                        className="form-select"
                        aria-label=""
                        name="search_by"
                        value={admissionFormValues.search_by || ""}
                        onChange={handleAdmissionFilterTypeChange}
                      >
                        {admissionSearchOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.value}
                          </option>
                        ))}

                      </select>
                      <small className="error">{!admissionFormValues.search_by && formErrors.search_by}</small>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="search_keyword">
                      <Form.Label>Search Keyword</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={getAdmissionFilterData()}
                        name="search_keyword"
                        value={admissionFormValues.search_keyword || ""}
                        onChange={(event) => setAdmissionFormValues({ ...admissionFormValues, search_keyword: event.target.value })}
                      />
                      <small className="error">
                        {!admissionFormValues.search_keyword && formErrors.search_keyword}
                      </small>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleAdmissionModalToggle}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  searchAdmission();
                }}
              >
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
          {studentData ? (
            <DataTable
              columns={columns}
              data={DataTableSettings.filterItems(
                studentData,
                searchParam,
                filterText
              )}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              subHeader
              fixedHeaderScrollHeight="400px"
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              progressPending={loadingIndicator}
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
            />
          ) : (
            "No student found"
          )}
        </div>
      </div>
    </>
  );
}

export default ListAdmission;
