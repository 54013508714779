import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_BATCHES_VIEW, API_WEB_DOMAIN } from "../../config/Api";

function ViewBatch(props) {

  const navigate = useNavigate();
  const { batchRef } = props;
  const [apiData, setApiData] = useState(null);
  const [showBatchData, setShowBatchData] = useState([]);
  const [showStudentData, setShowStudentData] = useState([]);

  useEffect(() => {
    fetchBatch();
  }, []);

  const fetchBatch = () => {
    props
      .callRequest("GET", API_BATCHES_VIEW + "/" + batchRef, true, null)
      .then((res) => {
        const ba_schedule = JSON.parse(res.data.batch.ba_schedule);
        const temp_ba_schedule = ba_schedule !== null ? ba_schedule : [];
        setApiData(res.data.batch);
        setShowBatchData(temp_ba_schedule);
        setShowStudentData(res.data.batch.students);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View Batch</h5>
        </div>
        {apiData ? (
          <div className="card-body">
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-year text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Batch Name</h5>
                      <span>{apiData.ba_batch_name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="la la-calendar text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Financial Year</h5>
                      <span>{apiData.ba_fy}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-week text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Start Date</h5>
                      <span>{props.getFormatedDate(apiData.ba_start_date)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-week text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">End Date</h5>
                      <span>{props.getFormatedDate(apiData.ba_end_date)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div id="batchData">
                {showBatchData.map((items, index) => {
                  return (
                    <div className="row border-bottom pb-3" key={index}>
                      <div className="col-xl-3 col-sm-6 mt-4">
                        <div className="d-flex">
                          <span>
                            <i className="la la-calendar text-primary me-2"></i>
                          </span>
                          <div>
                            <h5 className="fw-bold text-muted">Weekday</h5>
                            <span>{items.day_name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6 mt-4">
                        <div className="d-flex">
                          <span>
                            <i className="la la-clock-o text-primary me-2"></i>
                          </span>
                          <div>
                            <h5 className="fw-bold text-muted">Start Time</h5>
                            <span>{items.day_start_time}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6 mt-4">
                        <div className="d-flex">
                          <span>
                            <i className="la la-clock-o me-2"></i>
                          </span>
                          <div>
                            <h5 className="fw-bold text-muted">End Time</h5>
                            <span>{items.day_end_time}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6 mt-4">
                        <div className="d-flex">
                          <span>
                            <i className="las la-hourglass-half me-2"></i>
                          </span>
                          <div>
                            <h5 className="fw-bold text-muted">
                              Duration (Hours)
                            </h5>
                            <span>{items.day_duration}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-6 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Course</h5>
                </div>
                <div className="col-xl-8 col-sm-12 mt-1">
                  <h5>
                    <Link to={`/courses/view/${apiData.course.course_ref}`}>
                      {apiData.course.course_name}  - [{apiData.course.course_code}]
                    </Link>
                  </h5>
                </div>
                <div className="col-xl-4 col-sm-12 mt-1">
                  <span>
                    {apiData.course.course_duration_hours} hours /{" "}
                    {apiData.course.course_duration_months} months
                  </span>
                </div>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Enrolled Students</h5>
                </div>
              </div>
              {showStudentData.map((item, index) => {
                return (
                  <div className="row border-bottom py-2" key={index}>
                    <div className="col-xl-8 col-lg-8">
                      <div className="d-flex align-items-center justify-content-start">
                        <img
                          src={API_WEB_DOMAIN + item.stud_pic}
                          alt=""
                          className="admission-profile-thumb me-3"
                        />
                        <div className="ms-3">
                          {item.stud_fullname}
                          <br />
                          <Link to={`/admissions/view/${item.stud_ref}`}>
                            {item.stud_reg_no}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                      <div className="d-flex align-items-center">
                        <i className="las la-phone me-2"></i>
                        <span>{item.stud_phone}</span>
                        {item.stud_phone2 ? (
                          <span>&nbsp;/&nbsp;{item.stud_phone2}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="las la-envelope me-2"></i>
                        <span>{item.stud_email}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
}

export default ViewBatch;
