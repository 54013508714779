import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_ADMISSION_COUNT } from "../../config/Api";

function AdmissionCount(props) {
    const [admissionCount, setAdmissionCount] = useState(0);

    let center_ref = '';
    let fin_year = props.getFinancialYear();

    if (localStorage.getItem("user") !== null && localStorage.getItem("center") !== null) {
        const user = JSON.parse(localStorage.getItem("user"));
        const center = JSON.parse(localStorage.getItem("center"));

        if (user.role.role_name == 'CENTER ADMIN') {
            center_ref = '/' + center.ref;
        }
    }

    useEffect(() => {
        fetchAdmissionsCount();
        const interval = setInterval(() => {
            fetchAdmissionsCount();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const fetchAdmissionsCount = () => {
        props.callRequest("GET", API_ADMISSION_COUNT + '/' + fin_year + center_ref, true, null).then((res) => {
            setAdmissionCount(res.data.admission_count);
        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="row d-flex align-items-center">
                        <div className="col-4">
                            <i className="las la-users card-icon-lg"></i>
                        </div>
                        <div className="col-8">
                            <Link to="/admissions">
                                <h4 className="text-muted fw-bold m-0">Admissions</h4>
                            </Link>
                            <h2 className="fw-bolder m-0">{admissionCount}</h2>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default AdmissionCount;