import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import NewPreviewPayment from './NewPreviewPayment';

const NewPreviewAdmission = (props) => {
   const {
      formValues,
      formInfo,
      setShowPreview,
      handleStudentSave,
      courseOptions,
      discountCount,
      installmentCount,
      installmentData,
      discountData,
      paymentCount,
      selectedOption,
      feesData,
      paymentAmount,
      showPaymentInfo,
      showCasteUpload,
      showOldRegCertNum,
      courseAll,
   } = props;

   const getCourseName = (course_id) => {
      let found = courseAll.find(item => item.course_id === Number(course_id))
      let course_data = null
      if (found) {
         course_data = found
      }
      return course_data
   }

   return (
      <>
         <Form>
            <Row className="mb-3">
               <Col lg={4}>
                  <Form.Group controlId="student_type">
                     <Form.Label>Does the student admitted earlier?</Form.Label>
                     <Form.Control
                        type="text"
                        name="student_type"
                        value={Number(formInfo.student_type) === 1 ? "No" : "Yes"}
                        disabled
                     />
                  </Form.Group>
               </Col>
               {showOldRegCertNum && (
                  <>
                     <Col lg={4}>
                        <Form.Group controlId="old_reg_num">
                           <Form.Label>Old Registration / Certificate Number</Form.Label>
                           <Form.Control
                              type="text"
                              name="old_reg_num"
                              value={formInfo.old_reg_num}
                              disabled
                           />
                        </Form.Group>
                     </Col>
                     <Col lg={4}>
                        <Form.Group controlId="course_id_from">
                           <Form.Label>Course</Form.Label>
                           <Form.Control
                              type="text"
                              name="course_id_from"
                              value={getCourseName(formInfo.course_id_from).course_name}
                              disabled
                           />
                        </Form.Group>
                     </Col>
                  </>
               )}
            </Row>
            <>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="">
                        <Form.Label>Admission Date</Form.Label>
                        <Form.Control
                           type="date"
                           name="admission_date"
                           value={formInfo.admission_date}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="fullname">
                        <Form.Label>Student Name</Form.Label>
                        <Form.Control
                           type="text"
                           name="fullname"
                           value={formInfo.fullname}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="guardian_name">
                        <Form.Label> Father's/Husband's Name</Form.Label>
                        <Form.Control
                           type="text"
                           name="guardian_name"
                           value={formInfo.guardian_name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                           type="date"
                           name="dob"
                           value={formInfo.dob}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="phone"
                           value={formInfo.phone}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="alternate_phone">
                        <Form.Label>Alternate Phone Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="alternate_phone"
                           value={formInfo.alternate_phone}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="email">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                           type="text"
                           name="email"
                           value={formInfo.email}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="gender">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                           name="gender"
                           value={formInfo.gender}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="education_qualifications">
                        <Form.Label> Last Educational Qualification</Form.Label>
                        <Form.Control
                           type="text"
                           name="education_qualifications"
                           value={formInfo.education_qualifications}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="stud_edu_cert">
                        <Form.Label>
                           {" "}
                           Upload Last Qualification Certificate
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="stud_edu_cert"
                           value={formInfo.stud_edu_cert.name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="id_type">
                        <Form.Label>Government ID</Form.Label>
                        <Form.Control
                           name="id_type"
                           value={formInfo.id_type}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="id_num">
                        <Form.Label>Government ID Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="id_num"
                           value={formInfo.id_num}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="stud_govt_id_path">
                        <Form.Label>Upload Government ID</Form.Label>
                        <Form.Control
                           type="text"
                           name="stud_govt_id_path"
                           value={formInfo.stud_govt_id_path.name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="stud_caste">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                           value={formInfo.stud_caste}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  {showCasteUpload && (
                     <Col lg={4}>
                        <Form.Group controlId="stud_caste_doc">
                           <Form.Label>Upload Caste Certificate</Form.Label>
                           <Form.Control
                              type="text"
                              name="stud_caste_doc"
                              value={formInfo.stud_caste_doc.name}
                              disabled
                           />
                        </Form.Group>
                     </Col>
                  )}
               </Row>
               <Row className="mb-3">
                  <Col lg={6}>
                     <Form.Group controlId="stud_present_address">
                        <Form.Label>Current Address</Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="stud_present_address"
                           value={formInfo.stud_present_address}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={6}>
                     <Form.Group controlId="stud_permanent_address">
                        <div className="d-flex justify-content-between">
                           <Form.Label>Permanent Address</Form.Label>
                        </div>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="stud_permanent_address"
                           value={formInfo.stud_permanent_address}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={6}>
                     <Form.Group controlId="stud_pic">
                        <Form.Label>Upload Photo</Form.Label>
                        <div className="preview-container">
                           <div className="img-preview">
                              <img src={formInfo.stud_pic} />
                           </div>
                        </div>
                     </Form.Group>
                  </Col>
                  <Col lg={6}>
                     <Form.Group controlId="stud_sign">
                        <Form.Label>Upload Signature</Form.Label>
                        <div className="preview-container">
                           <div className="sign-preview">
                              <img src={formInfo.stud_sign} />
                           </div>
                        </div>
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="course_id_to">
                        <Form.Label>Course</Form.Label>
                        <Form.Control
                           type="text"
                           name="course_id_to"
                           value={getCourseName(formInfo.course_id_to).course_name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group>
                        <Form.Control
                           id="center_id"
                           type="hidden"
                           name="center_id"
                           value={formValues.center_id}
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row>
                  <NewPreviewPayment
                     formValues={formValues}
                     discountCount={discountCount}
                     discountData={discountData}
                     installmentCount={installmentCount}
                     installmentData={installmentData}
                     setShowPreview={setShowPreview}
                     handleStudentSave={handleStudentSave}
                     paymentCount={paymentCount}
                     selectedOption={selectedOption}
                     feesData={feesData}
                     paymentAmount={paymentAmount}
                     showPaymentInfo={showPaymentInfo}
                     {...props}
                  />
               </Row>
            </>
         </Form>
      </>
   )
}

export default NewPreviewAdmission