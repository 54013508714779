import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/ubuntu/ubuntu-light.ttf';
import './fonts/ubuntu/ubuntu-regular.ttf';
import './fonts/ubuntu/ubuntu-bold.ttf';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { API_IS_MAINTENANCE } from './config/Api';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.get(API_IS_MAINTENANCE).then(({ data }) => {
  root.render(
    <BrowserRouter>
      <App under_maintenance={data.under_maintenance} />
    </BrowserRouter>
  );
});

reportWebVitals();
