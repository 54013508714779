import React, { useState, useEffect } from "react";
import { Button, Table, Form, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import {
  API_CENTERS_VIEW,
  API_CENTERS_COURSES_ALLOCATE,
  API_COURSES,
} from "../../config/Api";

function ViewCenter(props) {
  const { centerRef } = props;
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    course_id: [],
  });

  const [apiData, setApiData] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [courseCount, setCourseCount] = useState(0);

  const [courseOptions, setCourseOptions] = useState([]);
  const [showCourseModal, setShowCourseModal] = useState(false);

  const handleCourseModalToggle = () => setShowCourseModal(!showCourseModal);

  useEffect(() => {
    fetchCenters();
    fetchCourses();
  }, []);

  const fetchCenters = () => {
    props
      .callRequest("GET", API_CENTERS_VIEW + "/" + centerRef, true, null)
      .then((res) => {
        setApiData(res.data.centers[0]);

        setCourseCount(res.data.centers[0].centercourse.length);
        setCourseData(res.data.centers[0].centercourse);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCourses = () => {
    props
      .callRequest("GET", API_COURSES, true, null)
      .then((res) => {
        setCourseOptions(res.data.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const course_id = e.target.value;
    if (e.target.checked) {
      setFormValues({
        ...formValues,
        course_id: [...formValues.course_id, course_id],
      });
    } else {
      setFormValues({
        ...formValues,
        course_id: formValues.course_id.filter((id) => id !== course_id),
      });
    }
  };

  const validateCourses = () => {
    const errors = {};
    let isValid = true;
    if (formValues.course_id.length === 0) {
      isValid = false;
      errors.course_id = "Course is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleCourseSubmit = (e) => {
    e.preventDefault();
    if (!validateCourses()) {
      return false;
    }

    props
      .callRequest(
        "POST",
        API_CENTERS_COURSES_ALLOCATE + "/" + centerRef,
        true,
        { course_id: formValues.course_id }
      )
      .then((res) => {
        setFormValues({ ...formValues, course_id: "" });
        props.showDialog("success", res.data.message, 2000);

        handleCourseModalToggle();
        fetchCenters();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const displayCourses =
    courseCount > 0 ? (
      courseData.map((course, i) => {
        return (
          <tr key={course.cc_id}>
            <td>
              <Link to={`/courses/view/${course.course.course_ref}`}>
                {course.course.course_name}
              </Link>
            </td>
            <td className="text-center">{course.course.admissions.length}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="3">No course found</td>
      </tr>
    );
  console.log(apiData, 'apiData');
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View Franchisee</h5>
          <div className="d-flex justify-content-end">
            <Button
              className="link-action"
              onClick={() => {
                navigate(`/study-centers/edit/${centerRef}`);
              }}
            >
              <i className="las la-edit me-2"></i>Edit
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-lg-12">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="fw-bold m-0 me-2">
                        {apiData.ct_name}, {apiData.ct_location} [{apiData.ct_code}]
                        {apiData.is_active === 0 && <div className='inactive-msg'><small>{apiData.ct_remarks}</small></div>}
                      </h4>

                      <span>
                        <span className="text-muted">District:</span>{" "}
                        {apiData.district.dist_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-8 col-sm-6 mt-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-map-marker me-2"></i>
                    <span>{apiData.ct_address}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 mt-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-phone me-2"></i>
                    <span>{apiData.ct_phone}</span>
                    {
                      apiData.ct_phone2 &&
                      <span>&nbsp;/&nbsp;{apiData.ct_phone2}</span>
                    }
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="las la-envelope me-2"></i>
                    <span>{apiData.ct_email}</span>
                  </div>
                  <div className="ms-4" >
                    <span>{apiData.ct_email2}</span>
                  </div>
                </div>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-8 col-sm-6 mt-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-user-tie me-2"></i>
                    <span>{apiData.ct_holder_name}</span>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-6 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Available Courses</h5>
                </div>
                <div className="col-xl-6 col-sm-6 mt-4 text-end">
                  <Button
                    className="link-action"
                    onClick={handleCourseModalToggle}
                  >
                    Add Course
                  </Button>
                </div>
                <Table className="table table-striped table-hover mt-4">
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }}>Course Name</th>
                      <th style={{ width: "30%" }} className="text-center">
                        Students Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayCourses}</tbody>
                </Table>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </div>

      <Modal show={showCourseModal} onHide={handleCourseModalToggle} centered>
        <Form onSubmit={handleCourseSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Select Course</Form.Label>
            {courseOptions.map((value) => (
              <Form.Group controlId="course_name" key={value.course_id}>
                <Form.Check
                  type="checkbox"
                  label={value.course_name}
                  value={value.course_id}
                  onChange={handleChange}
                />
              </Form.Group>
            ))}
            <small className="error">{formErrors.course_id}</small>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCourseModalToggle}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default ViewCenter;
