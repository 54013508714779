import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { API_COURSE_VIEW } from "../../config/Api";

function ViewCourse(props) {
  const { courseRef, convertDateStringToYMD, getFormatedDate } = props;
  const navigate = useNavigate();

  let center_ref = "";
  let user_role = "";

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    user_role = user.role.role_name;

    if (user_role === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
    }
  }

  const [apiData, setApiData] = useState(null);
  const [installmentData, setInstallmentData] = useState([]);
  const [installmentCount, setInstallmentCount] = useState(0);
  const [batchData, setBatchData] = useState([]);
  const [batchCount, setBatchCount] = useState(0);
  const [formValues, setFormValues] = useState({
    course_id: "",
  });

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = () => {
    let courseParamsDate = convertDateStringToYMD(new Date());
    props
      .callRequest(
        "GET",
        API_COURSE_VIEW + "/" + courseParamsDate + "/" + courseRef,
        true,
        null
      )
      .then((res) => {
        setFormValues({ ...formValues, course_id: res.data.course.course_id });
        setApiData(res.data.course);
        setInstallmentCount(
          res.data.course.course_attributes.ca_payment_terms.length
        );
        setInstallmentData(
          JSON.parse(res.data.course.course_attributes.ca_payment_terms)
        );
        setBatchCount(res.data.course.course_batches.length);
        setBatchData(res.data.course.course_batches);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const displayFeesBreakUp =
    installmentData.length > 0 ? (
      installmentData.map((installment, i) => {
        return (
          <div
            key={installment.instalment_num}
            className="my-2 me-5 px-2 left-border"
          >
            <h6>Installment - {i + 1}</h6>
            <span>{props.getCurrencyFormat(installment.instalment_amt)}</span>
          </div>
        );
      })
    ) : (
      <div>No fees break-up found</div>
    );

  const displayBatches =
    batchCount > 0 ? (
      batchData.map((batch, i) => {
        return (
          <tr key={batch.ba_id}>
            <td>
              <Link to={`/batches/view/${batch.ba_ref}`}>
                {batch.ba_batch_name}
              </Link>
            </td>
            <td>
              <Link to={`/study-centers/view/${batch.center.ct_ref}`}>
                {batch.center.ct_name} [{batch.center.ct_code}]
              </Link>
            </td>
            <td className="text-center">{batch.ba_fy}</td>
            <td className="text-center">{batch.students.length}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="4">No batch found</td>
      </tr>
    );

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View Course</h5>
          <div className="d-flex justify-content-end">
            <Button
              className="link-action"
              onClick={() => {
                navigate(`/courses/edit/${courseRef}`);
              }}
            >
              <i className="las la-edit me-2"></i>Edit
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-lg-12">
                  <div>
                    <div className="d-flex align-items-center">
                      <h4 className="fw-bold m-0 me-2">
                        {apiData.course_name} - [{apiData.course_code}]
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-4 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-walking text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Eligibility</h5>
                      <span>
                        {apiData.course_attributes.ca_eligibility_criteria}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-rupee-sign text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Fees</h5>
                      <span>
                        {props.getCurrencyFormat(
                          apiData.course_attributes.ca_fees
                        )}
                      </span><br />
                      Effective from <span>{getFormatedDate(
                        apiData.course_attributes.ca_valid_from
                      )}</span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-hourglass-half me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Duration</h5>
                      <span>
                        {apiData.course_attributes.ca_duration_hours} hours /{" "}
                        {apiData.course_attributes.ca_duration_months} months
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Fees Break-up</h5>
                </div>

                <div className="d-flex justify-content-start flex-wrap">
                  {displayFeesBreakUp}
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Course Content</h5>
                </div>

                <p>{apiData.course_attributes.ca_content}</p>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-6 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Batches</h5>
                </div>

                <Table className="table table-striped table-hover mt-4">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Batch Number</th>

                      <th style={{ width: "40%" }}>Study Center</th>

                      <th style={{ width: "20%" }} className="text-center">
                        Financial Year
                      </th>
                      <th style={{ width: "20%" }} className="text-center">
                        Students Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayBatches}</tbody>
                </Table>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewCourse;