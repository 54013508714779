import { useState, useEffect, useRef } from "react";
import Topbar from "../components/common/Topbar";
import MobileLogo from "../components/common/MobileLogo";
import Sidebar from "../components/common/Sidebar";

import AdmissionCount from "../components/dashboard/AdmissionCount";
import ActiveCenterCount from "../components/dashboard/ActiveCenterCount";
import CourseCount from "../components/dashboard/CourseCount";
import RevenueCount from "../components/dashboard/RevenueCount";

import Royalty from "../components/dashboard/Royalty";
import ChartAdmission from "../components/dashboard/ChartAdmission";
import ChartRoyalty from "../components/dashboard/ChartRoyalty";
import ShowModal from "../components/dashboard/popup/ShowModal";

function DashboardScreen(props) {
  const [showTopbar, setShowTopbar] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);

  const handleShowTopbar = () => {
    setShowTopbar(!showTopbar);
  };
  const handleShowSidebar = () => {
    setShowSidebar(true);
  };
  const handleChangeIcon = () => {
    setChangeIcon(!changeIcon);
  };

  const sidebarRef = useRef();

  useEffect(() => {
    let clickToCloseSidebar = (e) => {
      if (sidebarRef.current.contains(e.target)) {
        setShowSidebar(false);
      }
    };

    document.addEventListener("mousedown", clickToCloseSidebar);
    return () => {
      document.removeEventListener("mousedown", clickToCloseSidebar);
    };
  }, []);

  let user_role = "";

  if (localStorage.getItem("user") !== null) {
    const user = JSON.parse(localStorage.getItem("user"));
    user_role = user.role.role_name;
  }

  return (
    <>
      <MobileLogo
        handleShowTopbar={handleShowTopbar}
        changeIcon={changeIcon}
        handleChangeIcon={handleChangeIcon}
        handleShowSidebar={handleShowSidebar}
        {...props}
      />

      <Sidebar
        showSidebar={showSidebar}
        handleShowSidebar={handleShowSidebar}
        {...props}
      />

      <Topbar showTopbar={showTopbar} {...props} />

      <div className="pc-container" ref={sidebarRef}>
        <div className="pcoded-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="page-header-title">
                    <h5 className="m-b-10">Dashboard</h5>
                  </div>
                </div>
                <div className="col-md-6 text-end">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-12 text-end text-muted">
              Data based on the current financial year only
            </div>
          </div>

          <ShowModal {...props} />

          <div className="row">
            <div className="col-md-6 col-xl-4">
              <AdmissionCount {...props} />
            </div>

            {user_role === "SUPERADMIN" ? (
              <div className="col-md-6 col-xl-4">
                <ActiveCenterCount {...props} />
              </div>
            ) : (
              ""
            )}

            <div className="col-md-6 col-xl-4">
              <CourseCount {...props} />
            </div>

            {user_role === "SUPERADMIN" ? (
              ""
            ) : (
              <div className="col-md-6 col-xl-4">
                <RevenueCount {...props} />
              </div>
            )}

          </div>

          {user_role === "SUPERADMIN" ? (
            <>
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <Royalty duration="month" {...props} />
                </div>
                <div className="col-md-6 col-xl-4">
                  <Royalty duration="quarter" {...props} />
                </div>
                <div className="col-md-6 col-xl-4">
                  <Royalty duration="year" {...props} />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-md-6 col-xl-6">
              <ChartAdmission {...props} />
            </div>

            <div className="col-md-6 col-xl-6">
              <ChartRoyalty {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardScreen;
