import './App.css';
import Common from './helpers/common';
import Router from './router/Router';
import MaintenanceScreen from './screens/MaintenanceScreen';

function App(props) {
  const { under_maintenance } = props;

  if (under_maintenance) return <MaintenanceScreen />;
  return (
    <div className="App">
      <Router {...props} />
    </div>
  );
}

export default Common(App);
