import React, { useState, useEffect } from "react";
import { Form, Button, Table, Modal, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import {
  API_ADMISSIONS_PAYMENT_CALCULATE,
  API_ADMISSIONS_PAYMENT_SAVE,
  API_ADMISSIONS_VIEW,
  API_WEB_DOMAIN,
} from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

function AdmissionPayment(props) {
  const { studentRef } = props;
  const navigate = useNavigate();

  const initialValues = {
    amount_paid: "",
    payment_option: "",
    pay_by: "",
    payment_date: props.convertDateStringToYMD(new Date()),
    chq_num: "",
    chq_date: props.convertDateStringToYMD(new Date()),
    chq_bank: "",
    recpt_num2: "",
    original_course_fee: "",
    total_discount: "",
    original_course_fee: "",
    full_pay_discount: "",
    caste_discount: "",
    old_student_discount: "",
    payable_amount: "",
    student_ref: studentRef
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [showPaymentMode, setShowPaymentMode] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [isValidAmount, setIsValidAmount] = useState(false);
  const [paymentCount, setPaymentCount] = useState(0);
  const [btnEnable, setBtnEnable] = useState(false);
  const [centerInfo, setCenterInfo] = useState({});
  const [courseInfo, setCourseInfo] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [studentInfo, setStudentInfo] = useState({});
  const [feesBreakup, setFeesBreakup] = useState([]);

  const handlePaymentModeToggle = () => setShowPaymentMode(!showPaymentMode);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    props
      .callRequest("GET", API_ADMISSIONS_VIEW + "/" + studentRef, true, null)
      .then((res) => {
        setCenterInfo(res.data.admission_info.center_info);
        setCourseInfo(res.data.admission_info.course_info);
        setPaymentInfo(res.data.admission_info.payment_info[0]);
        setStudentInfo(res.data.admission_info.student_info);

        setPaymentCount(res.data.admission_info.payment_info.length);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePaymentChange = (e) => {
    const payment_type = e.target.value;
    setSelectedOption(payment_type);

    if (payment_type > 0) {
      const formData = new FormData();
      formData.append("ref_code", studentRef);
      formData.append("payment_type", payment_type);

      setIsValidAmount(payment_type === "1");
      setFormValues({ ...formValues, custom_pay_amount: "" });

      const fetchPaymentOptionsData = () => {
        props
          .callRequest("POST", API_ADMISSIONS_PAYMENT_CALCULATE, true, formData)
          .then((res) => {
            setFeesBreakup(res.data.fees_breakup);
          })
          .catch((e) => {
            console.log(e);
          });
      };

      fetchPaymentOptionsData();
    } else {
      return "No data found";
    }
  };

  const handleUpdatePaymentChange = (e) => {
    setFormValues({ ...formValues, amount_paid: e.target.value });
    setIsValidAmount(true);
  };

  const handleUpdateCustomPaymentChange = (e) => {
    const maxValue = Number(studentInfo.stud_total_due);
    const value = Number(e.target.value);

    if (value > 0 && value <= maxValue) {
      setFormValues({ ...formValues, amount_paid: value });
      setIsValidAmount(true);
    } else {
      setFormValues({ ...formValues, amount_paid: "" });
      setIsValidAmount(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateSubmit = () => {
    const { pay_by, payment_date, chq_num, chq_date, chq_bank } = formValues;
    const errors = {};
    let isValid = true;

    if (pay_by === "") {
      isValid = false;
      errors.pay_by = "Payment mode is required";
    }
    if (pay_by === "CASH") {
      if (!props.isValidDate(payment_date)) {
        isValid = false;
        errors.validate_payment_date = "Payment date is not valid";
      }
      else if (!props.compareDate(payment_date)) {
        isValid = false;
        errors.valid_compare_pay_date = "Payment date should be less than today";
      }

    }
    if (["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(pay_by)) {
      if (chq_num === "") {
        isValid = false;
        errors.chq_num = "Cheque / DD No. is required";
      }
      if (chq_date === "") {
        isValid = false;
        errors.chq_date = "Cheque / DD Date is required";
      } else if (chq_date !== "" && !props.isValidDate(chq_date)) {
        isValid = false;
        errors.validate_chq_date = "Cheque date is not valid";
      } else if (!props.compareDate(chq_date)) {
        isValid = false;
        errors.valid_compare_chq_date = "Cheque date should be less than today";
      }
      if (chq_bank === "") {
        isValid = false;
        errors.chq_bank = "Bank name is required";
      }
      if (!props.isValidDate(payment_date)) {
        isValid = false;
        errors.validate_payment_date = "Payment date is not valid";
      }
      else if (!props.compareDate(payment_date)) {
        isValid = false;
        errors.valid_compare_pay_date = "Payment date can't be future date";
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const savePaymentAPI = () => {
    if (!validateSubmit()) {
      return false;
    }
    const postData = {
      ...formValues,
      original_course_fee: paymentInfo.org_crs_fee,
      total_discount: studentInfo.stud_total_discount_amount,
      payable_amount: studentInfo.stud_total_due,
      amount_paid: formValues.amount_paid,
      payment_option: selectedOption
    }
    const formData = new FormData();
    console.log(postData, 'jj');
    for (let [key, value] of Object.entries(postData)) {
      formData.append(key, value);
    }

    setBtnEnable(true);
    props
      .callRequest("POST", API_ADMISSIONS_PAYMENT_SAVE, true, formData)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          handlePaymentModeToggle();
          setBtnEnable(false);
          navigate("/admissions/view/" + studentRef);
        }, 5000);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  const handlePaymentModeChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, pay_by: value ? value : "" });
    if (value !== "CASH") {
      setShowPaymentInfo(value);
    } else {
      setShowPaymentInfo("CASH");
    }
  };

  const displayDiscountBreakUp = () => {
    const studDiscounts = studentInfo.stud_discounts ? JSON.parse(studentInfo.stud_discounts) : [];
    return studDiscounts.length > 0 && (
      studDiscounts.map((discount, i) => {
        return (
          <tr key={i}>
            <td className="ps-5">
              {discount.label}
            </td>
            <td className="discount text-end">
              {props.getCurrencyFormat(discount.amount)}
            </td>
          </tr>
        );
      })
    );
  };

  const displayFeesBreakUp = () => {
    return (
      feesBreakup.map((installment, i) => {
        return i === paymentCount ? (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <div className="d-flex align-items-center">
              <input
                type="radio"
                name="pay_amount"
                value={installment.ins_amount}
                onChange={handleUpdatePaymentChange}
              />
              <div className="ms-3">
                <h6>Installment - {i + 1}</h6>
                <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
                <small className="error">
                  {!isValidAmount && formErrors.is_installment}
                </small>
              </div>
            </div>
          </div>
        ) : (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <h6>Installment - {i + 1}</h6>
            <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
          </div>
        );
      }));
  };

  const displayCustomFeesBreakUp = () => {
    return (
      feesBreakup.map((installment, i) => {
        return i === paymentCount ? (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h6>Installment - {i + 1}</h6>
                <input
                  type="text"
                  className="form-control"
                  name="custom_pay_amount"
                  defaultValue={installment.ins_amount}
                  onBlur={handleUpdateCustomPaymentChange}
                />
                <small className="error">
                  {!isValidAmount && formErrors.is_installment}
                </small>
              </div>
            </div>
          </div>
        ) : (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <h6>Installment - {i + 1}</h6>
            <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
          </div>
        );
      })
    );
  };

  const feesDetails = () => {
    return (
      <Table className="table table-striped table-hover">
        <tbody>
          <tr className="fw-bold">
            <td><h5 className="fw-bold  m-0 p-0">Original Course Fees</h5></td>
            <td className="text-end">
              <h5 className="fw-bold  m-0 p-0">
                {props.getCurrencyFormat(paymentInfo.org_crs_fee)}
              </h5>
            </td>
          </tr>

          {displayDiscountBreakUp()}

          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Total discount</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold  m-0 p-0">
                {props.getCurrencyFormat(studentInfo.stud_total_discount_amount)}
              </h5>
            </td>
          </tr>

          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Payable Amount</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold  m-0 p-0">
                {props.getCurrencyFormat(paymentInfo.org_crs_fee - studentInfo.stud_total_discount_amount)}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Amount Paid</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold m-0 p-0">
                {props.getCurrencyFormat(studentInfo.stud_total_paid)}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Amount Due</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold m-0 p-0">
                {props.getCurrencyFormat(studentInfo.stud_total_due)}
              </h5>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <div className="card">
        <ToastContainer />
        <div className="card-header">
          <h5>Payment</h5>
          <div>
            <Button
              className="link-action"
              onClick={() => navigate("/admissions/view/" + studentRef)}
            >
              View Admission
            </Button>
          </div>
        </div>

        <div className="card-body">
          <Form>
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-8 col-lg-8">
                  <div className="d-flex align-items-center justify-content-start">
                    <img
                      src={API_WEB_DOMAIN + studentInfo.stud_pic}
                      className="admission-profile-thumb me-3"
                      alt=""
                    />
                    <div className="ms-3">
                      <h4 className="fw-bold m-0 me-2">
                        {studentInfo.stud_fullname}
                      </h4>
                      <h5>{studentInfo.stud_reg_no}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-phone me-2"></i>
                    <span>{studentInfo.stud_phone}</span>
                    <span>
                      &nbsp;/&nbsp;{studentInfo.stud_phone2}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="las la-envelope me-2"></i>
                    <span>{studentInfo.stud_email}</span>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-8 col-sm-8 mt-4">
                  <h5 className="fw-bold">
                    {centerInfo.ct_name} [
                    {centerInfo.ct_code}]
                  </h5>
                </div>

                <div className="col-xl-4 col-sm-4 mt-4">
                  <h5 className="fw-bold">
                    <Link
                      to={`/courses/view/${courseInfo.course_ref}`}
                    >
                      {courseInfo.course_name}
                    </Link>
                  </h5>
                </div>
              </div>

              <div className="row pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Payment Option</h5>
                </div>
                <div className="col-xl-4 col-sm-6 mt-2">
                  {studentInfo.stud_payment_option === 1 ||
                    studentInfo.stud_payment_option === null ? (
                    <input
                      type="radio"
                      name="payment_type"
                      value="1"
                      checked={selectedOption === "1"}
                      onChange={handlePaymentChange}
                    />
                  ) : (
                    ""
                  )}
                  <span className="ms-2">Full Payment</span>
                </div>
                <div className="col-xl-4 col-sm-6 mt-2">
                  {studentInfo.stud_payment_option === 2 ||
                    studentInfo.stud_payment_option === null ? (
                    <input
                      type="radio"
                      name="payment_type"
                      value="2"
                      checked={selectedOption === "2"}
                      onChange={handlePaymentChange}
                    />
                  ) : (
                    ""
                  )}
                  <span className="ms-2">Part Payment</span>
                </div>
                <div className="col-xl-4 col-sm-6 mt-2">
                  {studentInfo.stud_payment_option === 3 ||
                    studentInfo.stud_payment_option === null ? (
                    <input
                      type="radio"
                      name="payment_type"
                      value="3"
                      checked={selectedOption === "3"}
                      onChange={handlePaymentChange}
                    />
                  ) : (
                    ""
                  )}
                  <span className="ms-2">Custom Payment</span>
                </div>
              </div>

              <div className="row border-bottom pb-3 px-5">
                {selectedOption === "1" && (
                  feesDetails()
                )}

                {selectedOption === "2" && (
                  <>
                    {feesDetails()}
                    <tr>
                      <td colSpan="2">
                        <div className="d-flex justify-content-start flex-wrap">
                          {displayFeesBreakUp()}
                        </div>
                      </td>
                    </tr>
                  </>
                )}

                {selectedOption === "3" && (
                  <>
                    {feesDetails()}
                    < tr >
                      <td colSpan="2">
                        <div className="d-flex justify-content-start flex-wrap">
                          {displayCustomFeesBreakUp()}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </div>
            </div>
          </Form>

          {isValidAmount ? (
            <div className="mt-2 text-end">
              <Button type="submit" onClick={handlePaymentModeToggle}>
                Confirm Payment
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <Modal
        size="lg"
        show={showPaymentMode}
        onHide={handlePaymentModeToggle}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Payment Mode</Form.Label>
                <Form.Select name="pay_by" onChange={handlePaymentModeChange}>
                  {props.getPaymentMode().map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <small className="error">
                  {formValues.pay_by === "" && formErrors.pay_by}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="payment_date">
                <Form.Label>Payment Date</Form.Label>
                <Form.Control
                  type="date"
                  name="payment_date"
                  value={formValues.payment_date || ""}
                  placeholder="Enter Payment Date"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="error">
                  {!props.isValidDate(formValues.payment_date)
                    ? formErrors.validate_payment_date
                    : !props.compareDate(formValues.payment_date)
                      ? formErrors.valid_compare_pay_date : ""}
                </small>
              </Form.Group>
            </Col>
          </Row>

          {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(showPaymentInfo) && (
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="">
                  <Form.Label>Cheque/DD/Ref No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="chq_num"
                    value={formValues.chq_num || ""}
                    placeholder="Enter Cheque/DD Number"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.chq_num === "" && formErrors.chq_num}
                  </small>
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group controlId="date">
                  <Form.Label>Cheque / DD Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="chq_date"
                    value={formValues.chq_date || ""}
                    placeholder="Enter Cheque / DD Date"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.chq_date === ""
                      ? formErrors.chq_date
                      : formValues.chq_date !== "" &&
                        !props.isValidDate(formValues.chq_date)
                        ? formErrors.validate_chq_date
                        : !props.compareDate(formValues.chq_date)
                          ? formErrors.valid_compare_chq_date : ""
                    }
                  </small>
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group controlId="">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="chq_bank"
                    value={formValues.chq_bank || ""}
                    onChange={handleChange}
                    placeholder="Enter Bank Name"
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.chq_bank === "" && formErrors.chq_bank}
                  </small>
                </Form.Group>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handlePaymentModeToggle();
              setFormValues({ ...formValues, pay_by: "" });
              setShowPaymentInfo("");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={btnEnable}
            onClick={() => {
              savePaymentAPI();
            }}
          >
            Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdmissionPayment;