import React, { useEffect, useState } from "react";
import SuperAdminSidebar from "../sidebars/SuperAdminSidebar";
import StudyCenterSidebar from "../sidebars/StudyCenterSidebar";
import ManagementSidebar from "../sidebars/ManagementSidebar";
import OperationalAdminSidebar from "../sidebars/OperationalAdminSidebar";
import useAuth from "../../hooks/useAuth";
import { API_NOTIFICATIONS } from "../../config/Api";
import axios from "axios";

function Sidebar(props) {
  const { isAuthenticated } = useAuth();
  const { showSidebar } = props;
  const [notifications, setNotifications] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [close, setClose] = useState(false);

  const userData = JSON.parse(localStorage.getItem("user"));
  const [roleName, setRoleName] = useState(
    isAuthenticated && userData.role.role_name ? userData.role.role_name : null
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setClose(true);
    }, 5000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    axios.get(API_NOTIFICATIONS, {
      headers: {
        token: localStorage.getItem("token"),
      },
    }).then((response) => {
      setNotifications(response.data.results);
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, notifications]);

  return (
    <>
      {
        notifications.length > 0 && (
          <div className={`notification-wrapper ${close ? "open" : "close"}`}>

            <div className="notification-content">
              <button className="notification-close" onClick={() => setClose(false)}><i className="las la-times"></i></button>
              <div className="notification-text">
                <div className="text">
                  {notifications[currentIndex]?.n_title}
                </div>
              </div>
            </div>
          </div>
        )
      }
      <nav
        className={showSidebar ? "pc-sidebar mob-sidebar-active" : "pc-sidebar"}
      >
        <div className="navbar-wrapper">
          <div className="m-header">
            <a href="/" className="b-brand">
              <img
                src="/assets/images/logo.png"
                alt=""
                className="logo logo-lg"
              />
            </a>

          </div>

          <div className="navbar-content">
            <li className="pc-item pc-caption">
              <label>&nbsp;</label>
            </li>
            {roleName === "SUPERADMIN" && <SuperAdminSidebar {...props} />}
            {roleName === "CENTER ADMIN" && <StudyCenterSidebar {...props} />}
            {roleName === "MANAGEMENT" && <ManagementSidebar {...props} />}
            {roleName === "ADMIN" && <OperationalAdminSidebar {...props} />}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
