import { useState, useEffect, useMemo } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_ROLES } from "../../config/Api";
import { toast } from "react-toastify";
import DataTableSettings from "../../helpers/DataTableSettings";
import DataTable from "react-data-table-component";
import RoleBasedButton from "../shared/RoleBasedButton";


function ListRole(props) {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_role = user.role.role_name;

  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const searchParam = [
    "role_name",
  ];

  useEffect(() => {
    fetchRoleListData();
  }, []);

  const fetchRoleListData = () => {
    props
      .callRequest("GET", API_ROLES, true, null)
      .then((res) => {
        setLoadingIndicator(false);
        setRoleData(JSON.parse(res.data.roles));

      })
      .catch((e) => {
        console.log(e);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  const columnsArr = [
    {
      name: <h6>Role Name</h6>,
      selector: (row) => row.role_name,
      sortable: true,
      //width: `${user_role === "SUPERADMIN" ? "40%" : "50%"}`,
      cell: (row) => (
        <div className="d-flex">
          <span className="fs-6 fw-bold">
            <Link to={`/roles/view/${row.role_ref}`}>
              {row.role_name}
            </Link>
          </span>
        </div>
      ),
    },
    {
      name: <h6>Permissions</h6>,
      selector: (row) => row.role_id,
      sortable: false,
      //width: "15%",

    },
    {
      name: <h6>Action</h6>,
      //width: "10%",
      center: true,
      cell: (row) => (
        <RoleBasedButton
          type="edit_role"
          url={`/roles/edit/${row.role_ref}`}
          user_role={user_role}
        />
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>All Roles</h5>
          <div className="d-flex justify-content-end">
            <RoleBasedButton
              type="add_role"
              url={"/roles/new"}
              user_role={user_role}
            />
          </div>
        </div>
        <div className="card-body">
          {roleData ? (
            <DataTable
              columns={columnsArr}
              data={DataTableSettings.filterItems(
                roleData,
                searchParam,
                filterText
              )}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              progressPending={loadingIndicator}
              subHeader
              fixedHeaderScrollHeight="400px"
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
            />
          ) : (
            "No roles found"
          )}
        </div>
      </div>
    </>
  );
}

export default ListRole;
