import { Link } from "react-router-dom";

function SuperAdminSidebar(props) {

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_expired_on");
    localStorage.removeItem("user");
    localStorage.removeItem("center");
    window.location.replace("/");
  };

  return (


    <div className="accordion" id="accordionExample">

      <li className="pc-item pc-hasmenu active">
        <Link to="/dashboard" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-desktop"></i>
            <span className="ms-3">Dashboard</span>
          </div>
        </Link>
      </li>

      <li className="pc-item pc-hasmenu">
        <a className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-university"></i>
            <span
              className="ms-3"
              data-bs-toggle="collapse"
              href="#center"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Franchisee
            </span>
          </div>
        </a>
        <ul
          className="pc-submenu accordion-collapse  collapse"
          id="center"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <li className="pc-item">
            <Link
              to="/study-centers"
              className="pc-link"
            >
              All Franchisee
            </Link>
          </li>
          <li className="pc-item">
            <Link to="/study-centers/new" className="pc-link">
              New Franchisee
            </Link>
          </li>
        </ul>
      </li>

      <li className="pc-item pc-hasmenu">
        <a className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-laptop-code"></i>
            <span
              className="ms-3"
              data-bs-toggle="collapse"
              href="#courses"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Courses
            </span>
          </div>
        </a>
        <ul
          className="pc-submenu accordion-collapse  collapse"
          id="courses"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <li className="pc-item">
            <Link
              to="/courses"
              className="pc-link"
            >
              All Courses
            </Link>
          </li>
          <li className="pc-item">
            <Link to="/courses/new" className="pc-link">
              New Course
            </Link>
          </li>
        </ul>
      </li>

      <li className="pc-item pc-hasmenu">
        <a className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-users"></i>
            <span
              className="ms-3"
              data-bs-toggle="collapse"
              href="#students"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Admissions
            </span>
          </div>
        </a>
        <ul
          className="pc-submenu accordion-collapse  collapse"
          id="students"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <li className="pc-item">
            <Link
              to="/admissions"
              className="pc-link"
            >
              All Admissions
            </Link>
          </li>

        </ul>
      </li>

      <li className="pc-item pc-hasmenu">
        <a className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-users-cog"></i>
            <span
              className="ms-3"
              data-bs-toggle="collapse"
              href="#users"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Users
            </span>
          </div>
        </a>
        <ul
          className="pc-submenu accordion-collapse  collapse"
          id="users"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <li className="pc-item">
            <Link
              to="/users"
              className="pc-link"
            >
              All Users
            </Link>
          </li>
          <li className="pc-item">
            <Link to="/users/new" className="pc-link">
              New User
            </Link>
          </li>
          <li className="pc-item">
            <Link to="/roles" className="pc-link">
              Role & Permission
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/reconciliation" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-list-alt"></i>
            <span className="ms-3">Reconciliation</span>
          </div>
        </Link>
      </li>

      <li>
        <Link to="/reports" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-chart-bar"></i>
            <span className="ms-3">Reports</span>
          </div>
        </Link>
      </li>

      <li>
        <Link to="/" onClick={logout} className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-sign-out-alt"></i>
            <span className="ms-3">Logout</span>
          </div>
        </Link>
      </li>

    </div>

  );
}

export default SuperAdminSidebar;
