import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const ShowModal = (props) => {
  const center = JSON.parse(localStorage.getItem("center"));
  const modalToggle = localStorage.getItem("is_ongoing_completed");

  const navigate = useNavigate();
  const [showDashboardModal, setShowDashboardModal] = useState(false);

  useEffect(() => {
    if (modalToggle === "1") {
      setShowDashboardModal(true);
    } else {
      setShowDashboardModal(false);
    }
  }, [modalToggle]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_expired_on");
    localStorage.removeItem("user");
    localStorage.removeItem("center");
    localStorage.removeItem("is_ongoing_completed");

    navigate("/");
  };

  return (
    <>
      <Modal
        show={showDashboardModal}
        backdrop="static"
        size="lg"
        //onHide={() => setShowDashboardModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="alert text-blink"
          >
            Alert !!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="lh-base">
            All franchisees are requested to complete entry of candidate information admitted w.e.f. 1st April 2023 in the new system positively within 20th August 2023. However, if the entry of the old student information is completed before that, the franchisee can start the admission of the new student immediately. This will enable franchisees to begin new admission process.
          </h5>

          {center.balance_admission_count > 0 && (
            <p className="fs-4">
              There are only{" "}
              <span className="fw-bold fs-3">
                {center.balance_admission_count}
              </span>{" "}
              remaining entries for candidate information
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={logout}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              localStorage.removeItem("is_ongoing_completed");
              navigate("/admissions/old");
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowModal;
