import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { API_LISTS_COURSE, API_LISTS_FINANCIAL_YEAR, API_REPORTS_COURSE_WISE_ADMISSIONS } from "../../config/Api";

function ViewCourseAdmissionReport(props) {
    const [loader, setLoader] = useState(false);

    const [formValues, setFormValues] = useState({
        financial_year: "",
        month: "",
        course_id: ""
    });
    const [formErrors, setFormErrors] = useState({});
    const [courseOptions, setCourseOptions] = useState([]);
    const [finYearOptions, setFinYearOptions] = useState([]);
    const [searchData, setSearchData] = useState([]);

    const monthOptions = Object.values(props.getMonth());

    useEffect(() => {
        fetchCourseOptions();
        fetchFinYearOptions();
    }, []);

    const fetchCourseOptions = () => {
        props.callRequest("GET", API_LISTS_COURSE, true, null).then((res) => {
            setCourseOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const fetchFinYearOptions = () => {
        props.callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null).then((res) => {
            setFinYearOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "financial_year") {
            setFormValues({ ...formValues, financial_year: value });
        } else if (name === "month") {
            setFormValues({ ...formValues, month: value });
        } else if (name === "course_id") {
            setFormValues({ ...formValues, course_id: value });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const validateSubmit = () => {
        const { financial_year, month, course_id } = formValues;
        const errors = {};
        let isValid = true;


        if (financial_year === "") {
            isValid = false;
            errors.financial_year = "Please select the financial year";
        }
        if (month === "") {
            isValid = false;
            errors.month = "Please select the month";
        }
        if (course_id === "") {
            isValid = false;
            errors.course_id = "Please select the course";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateSubmit()) {
            return false;
        }
        setLoader(true);
        setSearchData([]);
        props.callRequest("POST", API_REPORTS_COURSE_WISE_ADMISSIONS, true, formValues).then((res) => {
            setSearchData(res.data);
            setLoader(false);
        }).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (formValues.course_id === '' || formValues.month === '' || formValues.financial_year === '') {
            setSearchData([]);
        }
    }, [formValues]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row className="border-bottom pb-3">
                    <Col lg={3}>
                        <Form.Group controlId="financial_year">
                            <Form.Label>Financial Year</Form.Label>
                            <Form.Select name="financial_year" onChange={handleChange}>
                                <option value="">
                                    Select Financial Year
                                </option>
                                {
                                    Object.keys(finYearOptions).map((key) => (
                                        <option key={key} value={finYearOptions[key]}>
                                            {finYearOptions[key]}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            <small className="error">{formValues.financial_year === "" && formErrors.financial_year}</small>
                        </Form.Group>
                    </Col>

                    <Col lg={2}>
                        <Form.Group controlId="month">
                            <Form.Label>Month</Form.Label>
                            <Form.Select name="month" onChange={handleChange}>
                                <option value="">Select</option>
                                {monthOptions.map((month, index) => (
                                    <option key={index} value={index + 1}>
                                        {month}
                                    </option>
                                ))}
                            </Form.Select>
                            <small className="error">{formValues.month === "" && formErrors.month}</small>
                        </Form.Group>
                    </Col>

                    <Col lg={5}>
                        <Form.Group controlId="course_id">
                            <Form.Label>Course</Form.Label>
                            <Form.Select name="course_id" onChange={handleChange}>
                                <option value="">
                                    Select Course
                                </option>
                                {
                                    Object.keys(courseOptions).map((key) => (
                                        <option key={key} value={key}>
                                            {courseOptions[key]}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            <small className="error">{formValues.course_id === "" && formErrors.course_id}</small>
                        </Form.Group>
                    </Col>


                    <Col lg={2} className="text-end">
                        <div className="mt-3 pt-1"></div>
                        <Button type="submit" className="btn btn-primary mt-2">Search</Button>
                    </Col>
                </Row>

                <Row className="border-bottom py-3">
                    <Col lg={12}>
                        {loader && (
                            <img src="/assets/images/loader.gif" alt="" />
                        )}
                        {
                            searchData.count > 0 && searchData
                                ?
                                <div className="">
                                    <h6>Total <strong>{searchData.count}</strong> record(s) found</h6>
                                    {/* <Link to={searchData.file_path} target="_blank">
                                        <div className="d-flex align-items-center">
                                            <i className="las la-file-download me-2"></i> Download in excel
                                        </div>
                                    </Link> */}

                                    <embed src={searchData.file_path} type="application/pdf" width="100%" height="600px" />
                                </div>

                                : null
                        }
                    </Col>
                </Row>
            </Form>
        </>
    );

}

export default ViewCourseAdmissionReport;