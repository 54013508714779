import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_WEB_DOMAIN } from "../../config/Api";
import useAuth from "../../hooks/useAuth";

function StudyCenterSidebar(props) {
  const { isAuthenticated, guard } = useAuth();

  const navigate = useNavigate();
  const { showSidebar } = props;
  const [userInfo, setUserInfo] = useState(null);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_expired_on");
    localStorage.removeItem("user");
    localStorage.removeItem("center");
    setUserInfo(null);
    window.location.replace("/");
  };

  return (
    <div className="accordion" id="accordionExample">
      <li className="pc-item pc-hasmenu active">
        <Link to="/dashboard" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-chart-bar"></i>
            <span className="ms-3">Dashboard</span>
          </div>
        </Link>
      </li>

      <li className="pc-item pc-hasmenu active">
        <Link to="/courses" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-laptop-code"></i>
            <span className="ms-3">Courses</span>
          </div>
        </Link>
      </li>
      <li className="pc-item pc-hasmenu active">
        <Link to="/batches" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-user-graduate"></i>
            <span className="ms-3">Batches</span>
          </div>
        </Link>
      </li>

      <li className="pc-item pc-hasmenu active">
        <Link to="/admissions" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-users"></i>
            <span className="ms-3">Admissions</span>
          </div>
        </Link>
      </li>

      <li className="pc-item pc-hasmenu active">
        <Link to="/center-reconciliation" className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-list-alt"></i>
            <span className="ms-3">Reconciliation</span>
          </div>
        </Link>
      </li>

      <li className="pc-item pc-hasmenu active">
        <Link
          to={API_WEB_DOMAIN + "upload/Help-Guide.pdf"}
          target="_blank"
          className="pc-link"
        >
          <div className="d-flex align-items-center">
            <i className="las la-question"></i>
            <span className="ms-3">Help</span>
          </div>
        </Link>
      </li>

      <li>
        <Link to="/" onClick={logout} className="pc-link ">
          <div className="d-flex align-items-center">
            <i className="las la-sign-out-alt"></i>
            <span className="ms-3">Logout</span>
          </div>
        </Link>
      </li>
    </div>
  );
}

export default StudyCenterSidebar;
