import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { API_COURSES, API_COURSES_SEARCH } from "../../config/Api";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../helpers/DataTableSettings";
import RoleBasedButton from "../shared/RoleBasedButton";

const ListCourse = (props) => {
  const { convertDateStringToYMD } = props;

  const courseSearchOptions = [
    { value: 'Select', label: 'Select Option ' },
    { value: 'NAME', label: 'Diploma in Computer' },
    { value: 'DURATION-M', label: '1 months' },
    { value: 'DURATION-H', label: '24 hours' },
    { value: 'FEES', label: `${props.getCurrencyFormat(0)}` },
    { value: 'ELIGIBILITY', label: 'HS' },
  ];

  const [courseFormValues, setCourseFormValues] = useState({
    search_by: "",
    search_keyword: "",
  });
  const [showCourse, setShowCourse] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [courseData, setCourseData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const searchParam = [
    "course_name",
    "course_fees",
    "course_eligibility_criteria",
    "course_duration_months",
    "course_duration_hours",
  ];
  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [close, setClose] = useState(false);

  const handleCourseToggle = () => setShowCourse(!showCourse);

  let center_ref = "";
  let user_role = "";
  let courseParamsDate = convertDateStringToYMD(new Date());
  let queryParams = ""

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    user_role = user.role.role_name;

    if (user_role === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
      queryParams = API_COURSES + "/" + courseParamsDate + center_ref
    } else {
      queryParams = API_COURSES + "/" + courseParamsDate
    }
  }

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {

    props
      .callRequest("GET", queryParams, true, null)
      .then((res) => {
        setCourseData(res.data.courses);
        setLoadingIndicator(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columnsArr = [
    {
      name: <h6>Code</h6>,
      selector: (row) => row.course_code,
      sortable: false,
      width: "8%",
    },
    {
      name: <h6>Course Name</h6>,
      selector: (row) => row.course_name,
      sortable: true,
      width: `${user_role === "SUPERADMIN" || user_role === "ADMIN" ? "40%" : "50%"}`,
      cell: (row) => (
        <div className="d-flex">
          <span className="fs-6 fw-bold">
            <Link to={`/courses/view/${row.course_ref}`}>
              {row.course_name}
            </Link>
          </span>
        </div>
      ),
    },
    {
      name: <h6>Duration</h6>,
      selector: (row) => row.course_attributes.ca_duration_hours,
      sortable: false,
      width: "14%",
      cell: (row) => (
        <div className="d-flex flex-column">
          <span>{row.course_attributes.ca_duration_hours} hours</span>
          <span>{row.course_attributes.ca_duration_months} months</span>
        </div>
      ),
    },
    {
      name: <h6>Eligibility Criteria</h6>,
      selector: (row) => row.course_attributes.ca_eligibility_criteria,
      sortable: true,
      width: "14%",
    },
    {
      name: <h6>Fees</h6>,
      selector: (row) => row.course_attributes.ca_fees,
      sortable: true,
      width: "14%",
      cell: (row) => <span>{props.getCurrencyFormat(row.course_attributes.ca_fees)}</span>,
    },
    {
      name: <h6>Action</h6>,
      width: "10%",
      center: true,
      cell: (row) => (
        <>
          <RoleBasedButton
            type="course_new_fees"
            url={`/courses/new_fees/${row.course_ref}`}
            user_role={user_role}
          />
          <RoleBasedButton
            type="edit_course"
            url={`/courses/edit/${row.course_ref}`}
            user_role={user_role}
          />
        </>
      ),
    },
  ];

  const columns = user_role === "SUPERADMIN" || user_role === "ADMIN" ? columnsArr : columnsArr.slice(0, -1);

  const handleCourseFilterChange = (e) => {
    const { name, value } = e.target;
    setCourseFormValues({ ...courseFormValues, [name]: value, search_keyword: '' });
  };

  const getCourseFilterData = () => {
    const selectedOption = courseSearchOptions.find(option => option.value === courseFormValues.search_by);
    return selectedOption ?
      `${selectedOption.value === 'FEES' ? selectedOption.label : "e.g." + selectedOption.label}` : 'Search Keyword';
  };

  const validateSearchFilter = () => {
    let isValid = true;
    const errors = {};

    if (
      courseFormValues.search_by === undefined ||
      courseFormValues.search_by === ""
    ) {
      isValid = false;
      errors.search_by = "Search By is required";
    }
    if (
      courseFormValues.search_keyword === undefined ||
      courseFormValues.search_keyword === ""
    ) {
      isValid = false;
      errors.search_keyword = "Search Keyword is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const searchCourses = () => {
    if (!validateSearchFilter()) {
      return false;
    }

    props
      .callRequest("POST", API_COURSES_SEARCH, true, courseFormValues)
      .then((res) => {
        setCourseData(res.data.courses);
        handleCourseToggle();
        setClose(true);
      })
      .catch((e) => {
        props.showDialog("error", e.response.data.message, 2000);
      });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>All Course</h5>

          <div className="d-flex justify-content-end">
            {!close ?
              (
                <Button
                  className="link-action"
                  onClick={() => {
                    handleCourseToggle();
                    setCourseFormValues("");
                  }}
                >
                  <i className="las la-filter me-2"></i>Filter
                </Button>
              ) : (
                <Button
                  className="link-action"
                  onClick={() => {
                    setClose(false);
                    fetchCourses();
                  }}
                >
                  <i className="las la-times me-2"></i>Filter
                </Button>
              )}

            <RoleBasedButton
              type="add_course"
              url={"/courses/new"}
              user_role={user_role}
            />
          </div>
        </div>
        <Modal
          show={showCourse}
          onHide={handleCourseToggle}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col lg={5}>
                  <Form.Group className="mb-3" controlId="search_by">
                    <Form.Label>Search By</Form.Label>
                    <select
                      className="form-select"
                      name="search_by"
                      value={courseFormValues.search_by || ""}
                      onChange={handleCourseFilterChange}
                    >
                      {courseSearchOptions.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.value}
                        </option>
                      ))}

                    </select>
                    <small className="error">{!courseFormValues.search_by && formErrors.search_by}</small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="search_keyword">
                    <Form.Label>Search Keyword</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={getCourseFilterData()}
                      name="search_keyword"
                      value={courseFormValues.search_keyword || ""}
                      onChange={(event) => setCourseFormValues({ ...courseFormValues, search_keyword: event.target.value })}
                      autoComplete="off"
                    />
                    <small className="error">{!courseFormValues.search_keyword && formErrors.search_keyword}</small>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCourseToggle}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                searchCourses();
              }}
            >
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="card-body">
          {courseData ? (
            <DataTable
              columns={columns}
              data={DataTableSettings.filterItems(
                courseData,
                searchParam,
                filterText
              )}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              progressPending={loadingIndicator}
              subHeader
              fixedHeaderScrollHeight="400px"
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
            />
          ) : (
            "No course found"
          )}
        </div>
      </div>
    </div>
  );
};

export default ListCourse;
