import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { API_CENTER_REVENUE } from "../../config/Api";

function RevenueCount(props) {
  const [revenueAmount, setRevenueAmount] = useState(0);

  let center_ref = "";
  let fin_year = props.getFinancialYear();
  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    if (user.role.role_name == "CENTER ADMIN") {
      center_ref = "/" + center.ref;
    }
  }

  useEffect(() => {
    fetchRevenue();
    const interval = setInterval(() => {
      fetchRevenue();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchRevenue = () => {
    props
      .callRequest("GET", API_CENTER_REVENUE + '/' + fin_year + center_ref, true, null)
      .then((res) => {
        let revenue_amount = 0;
        res.data.revenue.map((item) => {
          revenue_amount += Number(item.amount_paid);
        });
        setRevenueAmount(props.displayDashboardCurrency(revenue_amount));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row d-flex align-items-center">
            <div className="col-4">
              <i className="las la-coins card-icon-lg"></i>
            </div>
            <div className="col-8">
              <h4 className="text-muted fw-bold m-0">Revenue</h4>
              <h2 className="fw-bolder m-0">
                {revenueAmount ? revenueAmount : 0}
              </h2>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default RevenueCount;
