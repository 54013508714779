import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const PreviewPaymentDetails = ({
   formValues,
   showPaymentInfo,

}) => {
   return (
      <div className="mt-2">
         <div className="col-xl-12 col-sm-12 mt-4">
            <h5 className="fw-bold text-muted">Payment Details</h5>
         </div>
         <Row className="mb-3 mt-3">
            <Col lg={4}>
               <Form.Group>
                  <Form.Label>Payment Mode</Form.Label>
                  <Form.Control
                     name="pay_by"
                     value={formValues.pay_by}
                     disabled
                  />
               </Form.Group>
            </Col>

            <Col lg={4}>
               <Form.Group controlId="payment_date">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                     type="date"
                     name="payment_date"
                     value={formValues.payment_date}
                     disabled
                  />

               </Form.Group>
            </Col>
         </Row>

         {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(showPaymentInfo) && (
            <Row className="mb-3">
               <Col lg={4}>
                  <Form.Group controlId="">
                     <Form.Label>Cheque/DD/Ref No.</Form.Label>
                     <Form.Control
                        type="text"
                        name="chq_num"
                        value={formValues.chq_num}
                        disabled
                     />

                  </Form.Group>
               </Col>

               <Col lg={4}>
                  <Form.Group controlId="date">
                     <Form.Label>Cheque/DD/Ref Date</Form.Label>
                     <Form.Control
                        type="date"
                        name="chq_date"
                        value={formValues.chq_date}
                        disabled
                     />

                  </Form.Group>
               </Col>

               <Col lg={4}>
                  <Form.Group controlId="">
                     <Form.Label>Bank Name</Form.Label>
                     <Form.Control
                        type="text"
                        name="chq_bank"
                        value={formValues.chq_bank}
                        disabled
                     />

                  </Form.Group>
               </Col>
            </Row>
         )}
      </div>
   );
};

export default PreviewPaymentDetails;
