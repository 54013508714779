import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Cropper from "react-easy-crop";
import getCroppedImg from "../../helpers/cropImage";
import {
  API_ADMISSIONS_SEARCH_OLD_CANDIDATE,
  API_COURSES,
  API_COURSES_ALL,
  API_WEB_DOMAIN,
} from "../../config/Api";

import NewAdmissionPayment from "./NewAdmissionPayment";
import { ToastContainer, toast } from "react-toastify";

function NewAdmissionForm(props) {
  const studentTypeOption = [
    { label: "Select", value: "" },
    { label: "No", value: "1" },
    { label: "Yes", value: "0" },
  ];

  const navigate = useNavigate();
  const admission_route_flag = localStorage.getItem("admission_route_flag");

  let center_id = "";
  let center_ref = "";
  let user = "";
  let center = "";

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    user = JSON.parse(localStorage.getItem("user"));
    center = JSON.parse(localStorage.getItem("center"));

    if (user.role.role_name === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
      center_id = center.ct_id;
    }
  }

  useEffect(() => {
    if (admission_route_flag === "0") {
      navigate("/admissions/old");
    }
  }, [admission_route_flag]);

  const initialValues = {
    student_type: "",
    old_reg_num: "",
    fullname: "",
    guardian_name: "",
    phone: "",
    alternate_phone: "",
    email: "",
    dob: "",
    education_qualifications: "",
    stud_edu_cert: "",
    stud_caste: "GENERAL",
    stud_caste_doc: "",
    gender: "",
    stud_present_address: "",
    stud_permanent_address: "",
    center_id: `${center_id}`,
    stud_pic: "",
    stud_sign: "",
    id_type: "",
    id_num: "",
    admission_date: props.convertDateStringToYMD(new Date()),
    stud_govt_id_path: "",
    course_id_to: "",
    course_id_from: "",
    course_code_to: "",
    course_code_from: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const [showOldRegCertNum, setShowOldRegCertNum] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [courseAll, setCourseAll] = useState([]);

  const [selectedIDCard, setSelectedIDCard] = useState(null);
  const [selectedEducationCertificate, setSelectedEducationCertificate] = useState(null);
  const [selectedCasteCertificate, setSelectedCasteCertificate] = useState(null);

  const [showCasteUpload, setShowCasteUpload] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [isPdf, setIspdf] = useState({
    caste: true,
    govt_id: true,
    last_edu_qualification: true,
  });

  //For Image
  const [srcImg, setSrcImg] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [resultImg, setResultImg] = useState(null);

  const [showImageModal, setShowImageModal] = useState(false);

  //For Signature
  const [srcSign, setSrcSign] = useState(null);
  const [cropSign, setCropSign] = useState({ x: 0, y: 0 });
  const [croppedAreaPixelsSignature, setCroppedAreaPixelsSignature] = useState(null);
  const [zoomSign, setZoomSign] = useState(1);
  const [resultSign, setResultSign] = useState(null);

  const [showSignatureModal, setShowSignatureModal] = useState(false);

  useEffect(() => {
    fetchAllCourses();
    fetchCourseOptions();
  }, []);

  const fetchAllCourses = () => {
    props
      .callRequest("GET", API_COURSES_ALL, true, null)
      .then((res) => {
        setCourseAll(res.data.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCourseOptions = () => {
    const url = API_COURSES + '/' + formValues.admission_date + center_ref;
    props
      .callRequest("GET", url, true, null)
      .then((res) => {
        setCourseOptions(res.data.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (formValues.course_id_from) {
      const filterCourses = courseOptions.filter((item) => item.course_id !== formValues.course_id_from);
      setCourseOptions(filterCourses);
    } else {
      fetchCourseOptions();
    }
  }, [formValues.course_id_from]);

  const handleBlur = (event) => {
    if (formValues.old_reg_num !== "") {
      fetchOldCandidateData(event.target.value);
    }
  };

  const fetchOldCandidateData = (param) => {
    props
      .callRequest(
        "GET",
        API_ADMISSIONS_SEARCH_OLD_CANDIDATE + "/" + param,
        true,
        null
      )
      .then((res) => {
        setFormValues({
          ...formValues,
          fullname: res.data.admission_info.student_info.stud_fullname,
          guardian_name:
            res.data.admission_info.student_info.stud_guardian_name,
          phone: res.data.admission_info.student_info.stud_phone,
          alternate_phone: res.data.admission_info.student_info.stud_phone2,
          email: res.data.admission_info.student_info.stud_email,
          dob: res.data.admission_info.student_info.stud_dob,
          education_qualifications:
            res.data.admission_info.student_info.stud_edu_qual,
          stud_edu_cert:
            res.data.admission_info.student_info.stud_edu_qual_path,
          stud_caste: res.data.admission_info.student_info.stud_caste,
          gender: res.data.admission_info.student_info.stud_gender,
          stud_present_address:
            res.data.admission_info.student_info.stud_present_address,
          stud_permanent_address:
            res.data.admission_info.student_info.stud_permanent_address,
          id_type: res.data.admission_info.student_info.stud_govt_id_type,
          id_num: res.data.admission_info.student_info.stud_govt_id_num,
          stud_govt_id_path:
            res.data.admission_info.student_info.stud_govt_id_path,
          stud_caste_doc:
            res.data.admission_info.student_info.stud_caste_document,
        });
        setSelectedEducationCertificate(
          res.data.admission_info.student_info.stud_edu_qual_path
        );
        setSelectedIDCard(
          res.data.admission_info.student_info.stud_govt_id_path
        );
        setSelectedCasteCertificate(
          res.data.admission_info.student_info.stud_caste_document
        );
        setShowCasteUpload(
          res.data.admission_info.student_info.stud_caste !== "GENERAL"
        );
        setResultImg(
          API_WEB_DOMAIN + res.data.admission_info.student_info.stud_pic
        );
        setResultSign(
          API_WEB_DOMAIN + res.data.admission_info.student_info.stud_sign
        );
        setShowForm(true);
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });

        setFormValues({ ...formValues, student_type: "0" });
        setSelectedIDCard(null);
        setSelectedCasteCertificate(null);
        setSelectedEducationCertificate(null);
        setResultImg(null);
        setResultSign(null);
        setShowCasteUpload(false);
        setShowForm(true);
      });
  };

  const handleStudentTypeChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, student_type: value });

    if (value === "0") {
      setShowForm(false);
      setShowOldRegCertNum(true);
    } else if (value === "1") {
      setFormValues({ ...initialValues, student_type: "1" });
      setSelectedIDCard(null);
      setSelectedCasteCertificate(null);
      setSelectedEducationCertificate(null);
      setResultImg(null);
      setResultSign(null);
      setShowForm(true);
      setShowOldRegCertNum(false);
    } else {
      setShowForm(false);
      setShowOldRegCertNum(false);
    }
  };

  const handleEducationCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedEducationCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_edu_cert: e.target.files[0],
        });
        setIspdf({ ...isPdf, last_edu_qualification: true });
      } else {
        setSelectedEducationCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_edu_cert: e.target.files[0],
        });
        setIspdf({ ...isPdf, last_edu_qualification: false });
      }
    }
  };

  const handleIDCardChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedIDCard(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_govt_id_path: e.target.files[0],
        });
        setIspdf({ ...isPdf, govt_id: true });
      } else {
        setSelectedIDCard(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_govt_id_path: e.target.files[0],
        });
        setIspdf({ ...isPdf, govt_id: false });
      }
    }
  };

  const handleCasteCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedCasteCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_caste_doc: e.target.files[0],
        });
        setIspdf({ ...isPdf, caste: true });
      } else {
        setSelectedCasteCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_caste_doc: e.target.files[0],
        });
        setIspdf({ ...isPdf, caste: false });
      }
    }
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, stud_caste: value });
    setShowCasteUpload(value !== "GENERAL");
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setFormValues((formValues) => ({
        ...formValues,
        stud_permanent_address: formValues.stud_present_address,
      }));
    } else {
      setFormValues((formValues) => ({
        ...formValues,
        stud_permanent_address: "",
      }));
    }
  };

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
  };

  const handleShowSignatureModal = () => setShowSignatureModal(true);
  const handleCloseSignatureModal = () => setShowSignatureModal(false);

  const handleSignature = async (event) => {
    setSrcSign(URL.createObjectURL(event.target.files[0]));
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(srcImg, croppedAreaPixels, "profile");

      setResultImg(croppedImage);
      setFormValues({
        ...formValues,
        stud_pic: croppedImage,
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onCropCompleteSignature = useCallback(
    (croppedArea, croppedAreaPixelsSignature) => {
      setCroppedAreaPixelsSignature(croppedAreaPixelsSignature);
    },
    []
  );

  const showCroppedImageSignature = useCallback(async () => {
    try {
      const croppedImageSignature = await getCroppedImg(
        srcSign,
        croppedAreaPixelsSignature,
        "signature"
      );

      setResultSign(croppedImageSignature);
      setFormValues({
        ...formValues,
        stud_sign: croppedImageSignature,
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixelsSignature]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "course_id_to") {
      let found = courseOptions.find((item) => item.course_id === Number(value));
      if (found) {
        setFormValues({ ...formValues, course_id_to: found.course_id, course_code_to: found.course_code });
      } else {
        setFormValues({ ...formValues, course_id_to: "" });
      }
    } else if (name === "course_id_from") {
      let found = courseAll.find((item) => item.course_id === Number(value));
      if (found) {
        setFormValues({ ...formValues, course_id_from: found.course_id, course_code_from: found.course_code });
      } else {
        setFormValues({ ...formValues, course_id_from: "" });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateStudentForm = () => {
    const {
      student_type,
      old_reg_num,
      fullname,
      guardian_name,
      phone,
      dob,
      gender,
      education_qualifications,
      id_type,
      id_num,
      stud_caste,
      stud_present_address,
      stud_permanent_address,
      course_id_to,
      admission_date,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (student_type === 0 && old_reg_num === "") {
      isValid = false;
      errors.old_reg_num = "Old registration number is required";
    }
    if (fullname === "") {
      isValid = false;
      errors.fullname = "Student fullname is required";
    } else if (fullname !== "" && !props.checkAlphaOnly(fullname)) {
      isValid = false;
      errors.fullname_not_valid = "Valid entry only alphabets, space and dot";
    }
    if (guardian_name === "") {
      isValid = false;
      errors.guardian_name = "Father's/Husband's name is required";
    } else if (guardian_name !== "" && !props.checkAlphaOnly(guardian_name)) {
      isValid = false;
      errors.guardian_name_not_valid =
        "Valid entry only alphabets, space and dot";
    }

    if (dob === "") {
      isValid = false;
      errors.dob = "Date of Birth is required";
    } else if (dob !== "" && !props.isValidDate(dob)) {
      isValid = false;
      errors.valid_dob = "Date of Birth is not valid";
    }
    if (gender === "") {
      isValid = false;
      errors.gender = "Gender is required";
    }
    if (education_qualifications === "") {
      isValid = false;
      errors.education_qualifications =
        "Educational qualifications is required";
    }
    if (!selectedEducationCertificate) {
      isValid = false;
      errors.stud_edu_cert = "Educational Certificate upload is required";
    } else if (!isPdf.last_edu_qualification && selectedEducationCertificate) {
      isValid = false;
      errors.stud_edu_format_pdf = "Invalid file format";
    }
    if (id_type === "") {
      isValid = false;
      errors.id_type = "Government ID type is required";
    }
    if (id_num === "") {
      isValid = false;
      errors.id_num = "Government ID number is required";
    }
    if (!selectedIDCard) {
      isValid = false;
      errors.stud_govt_id_path = "Government ID card upload is required";
    } else if (selectedIDCard && !isPdf.govt_id) {
      isValid = false;
      errors.stud_govt_id_path_format_pdf = "Invalid file format";
    }
    if (stud_caste !== "GENERAL" && !selectedCasteCertificate) {
      isValid = false;
      errors.stud_caste_doc = "Caste certificate is required";
    } else if (
      stud_caste !== "GENERAL" &&
      selectedCasteCertificate &&
      !isPdf.caste
    ) {
      isValid = false;
      errors.stud_caste_format_pdf = "Invalid file format";
    }
    if (stud_present_address === "") {
      isValid = false;
      errors.stud_present_address = "Present address is required";
    }
    if (stud_permanent_address === "") {
      isValid = false;
      errors.stud_permanent_address = "Permanent address is required";
    }
    if (course_id_to === "") {
      isValid = false;
      errors.course_id_to = "Course is required";
    }
    if (!resultImg) {
      isValid = false;
      errors.stud_pic = "Student photo is required";
    }
    if (!resultSign) {
      isValid = false;
      errors.stud_sign = "Student signature is required";
    }
    if (phone === "") {
      isValid = false;
      errors.phone = "Phone number is required and must be 10 digit";
    }
    if (admission_date === "") {
      isValid = false;
      errors.admission_date = "Admission date is required";
    } else if (admission_date !== "" && !props.isValidDate(admission_date)) {
      isValid = false;
      errors.validate_admission_date = "Admission date is not valid";
    } else if (admission_date !== "" && !props.compareDate(admission_date)) {
      isValid = false;
      errors.validate_compare_admission_date = "Admission date should be less than today";
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <>
      <Form>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="student_type">
              <Form.Label>Does the student admitted earlier?</Form.Label>
              <Form.Select
                value={formValues.student_type || ""}
                onChange={handleStudentTypeChange}
              >
                {studentTypeOption.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          {showOldRegCertNum && (
            <>
              <Col lg={4}>
                <Form.Group controlId="old_reg_num">
                  <Form.Label>Old Registration / Certificate Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="old_reg_num"
                    value={formValues.old_reg_num || ""}
                    placeholder="Enter Registration / Certificate Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <small className="error">
                    {formValues.student_type === 0 &&
                      formValues.old_reg_num === "" &&
                      formErrors.old_reg_num}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="course_id_from">
                  <Form.Label>Course From </Form.Label>
                  <Form.Select name="course_id_from" onChange={handleChange}>
                    <option value="">Select Course</option>
                    {courseAll.map((value) => (
                      <option key={value.course_ref} value={value.course_id}>
                        {value.course_name}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formValues.course_id_from === "" && formErrors.course_id_from}
                  </small>
                </Form.Group>
              </Col>
            </>
          )}

        </Row>

        {showForm && (
          <>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="">
                  <Form.Label>Admission Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="admission_date"
                    value={formValues.admission_date || ""}
                    onChange={handleChange}
                    max={formValues.admission_date}
                  />
                  <small className="error">
                    {formValues.admission_date === ""
                      ? formErrors.admission_date
                      : formValues.admission_date !== "" &&
                        !props.isValidDate(formValues.admission_date)
                        ? formErrors.validate_admission_date
                        : formValues.admission_date !== "" && !props.compareDate(formValues.admission_date) ? formErrors.validate_compare_admission_date : ""}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="fullname">
                  <Form.Label>Student Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullname"
                    value={formValues.fullname || ""}
                    placeholder="Student Fullname"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.fullname === ""
                      ? formErrors.fullname
                      : formValues.fullname !== "" &&
                        !props.checkAlphaOnly(formValues.fullname)
                        ? formErrors.fullname_not_valid
                        : ""}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="guardian_name">
                  <Form.Label> Father's/Husband's Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="guardian_name"
                    value={formValues.guardian_name || ""}
                    placeholder="Father's/Husband's Name"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.guardian_name === ""
                      ? formErrors.guardian_name
                      : formValues.guardian_name !== "" &&
                        !props.checkAlphaOnly(formValues.guardian_name)
                        ? formErrors.guardian_name_not_valid
                        : ""}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="dob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={formValues.dob || ""}
                    onChange={handleChange}
                  />
                  <small className="error">
                    {formValues.dob === ""
                      ? formErrors.dob
                      : formValues.dob !== "" &&
                        !props.isValidDate(formValues.dob)
                        ? formErrors.valid_dob
                        : ""}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="phone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formValues.phone || ""}
                    placeholder="e.g. 9876543210"
                    onChange={handleChange}
                    onKeyDown={props.handleKeyPress}
                    maxLength={10}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.phone === "" && formErrors.phone}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="alternate_phone">
                  <Form.Label>Alternate Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="alternate_phone"
                    value={formValues.alternate_phone || ""}
                    placeholder="e.g. 9876543210"
                    onChange={handleChange}
                    onKeyDown={props.handleKeyPress}
                    maxLength={10}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.alternate_phone === "" &&
                      formErrors.alternate_phone}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="email">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formValues.email || ""}
                    placeholder="Enter Email Address"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="gender">
                  <Form.Label>Gender</Form.Label>
                  <select
                    className="form-select"
                    aria-label="Select Gender"
                    name="gender"
                    value={formValues.gender || ""}
                    onChange={handleChange}
                  >
                    {props.getGender().map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <small className="error">
                    {formValues.gender === "" && formErrors.gender}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="education_qualifications">
                  <Form.Label> Last Educational Qualification</Form.Label>
                  <Form.Control
                    type="text"
                    name="education_qualifications"
                    value={formValues.education_qualifications || ""}
                    placeholder="Enter Educational Qualification"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <small className="error">
                    {formValues.education_qualifications === "" &&
                      formErrors.education_qualifications}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="stud_edu_cert">
                  <Form.Label>
                    {" "}
                    Upload Last Qualification Certificate
                  </Form.Label>
                  {(formValues.stud_edu_cert && typeof formValues.stud_edu_cert === "string") ? (
                    <Link
                      to={API_WEB_DOMAIN + formValues.stud_edu_cert}
                      target="_blank"
                    >
                      <center>
                        <i className="las la-file-pdf me-4"></i>
                      </center>
                    </Link>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    type="file"
                    name="stud_edu_cert"
                    onChange={handleEducationCertificateChange}
                  />
                  <small className="error">
                    {!selectedEducationCertificate
                      ? formErrors.stud_edu_cert
                      : selectedEducationCertificate &&
                        !isPdf.last_edu_qualification
                        ? formErrors.stud_edu_format_pdf
                        : ""}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="id_type">
                  <Form.Label>Government ID</Form.Label>
                  <select
                    className="form-select"
                    aria-label="Select ID Type"
                    name="id_type"
                    value={formValues.id_type || ""}
                    onChange={handleChange}
                  >
                    {props.getGovId().map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <small className="error">
                    {formValues.id_type === "" && formErrors.id_type}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="id_num">
                  <Form.Label>Government ID Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="id_num"
                    placeholder="Govt. ID Number"
                    value={formValues.id_num || ""}
                    onChange={handleChange}
                  />
                  <small className="error">
                    {formValues.id_num === "" && formErrors.id_num}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="stud_govt_id_path">
                  <Form.Label>Upload Government ID</Form.Label>
                  {(formValues.stud_govt_id_path && typeof formValues.stud_govt_id_path === "string") ? (
                    <Link
                      to={API_WEB_DOMAIN + formValues.stud_govt_id_path}
                      target="_blank"
                    >
                      <center>
                        <i className="las la-file-pdf me-4"></i>
                      </center>
                    </Link>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    type="file"
                    name="stud_govt_id_path"
                    onChange={handleIDCardChange}
                  />
                  <small className="error">
                    {!selectedIDCard
                      ? formErrors.stud_govt_id_path
                      : selectedIDCard && !isPdf.govt_id
                        ? formErrors.stud_govt_id_path_format_pdf
                        : ""}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="stud_caste">
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    value={formValues.stud_caste || ""}
                    onChange={handleCategoryChange}
                  >
                    {props.getCategory().map((value, i) => (
                      <option key={i} value={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {showCasteUpload && (
                <Col lg={4}>
                  <Form.Group controlId="stud_caste_doc">
                    <Form.Label>Upload Caste Certificate</Form.Label>
                    {(formValues.stud_caste_doc && typeof formValues.stud_caste_doc === "string") ? (
                      <Link
                        to={API_WEB_DOMAIN + formValues.stud_caste_doc}
                        target="_blank"
                      >
                        <center>
                          <i className="las la-file-pdf me-4"></i>
                        </center>
                      </Link>
                    ) : (
                      ""
                    )}
                    <Form.Control
                      type="file"
                      name="stud_caste_doc"
                      onChange={handleCasteCertificateChange}
                      accept=".pdf"
                    />
                    <small className="error">
                      {formValues.stud_caste !== "GENERAL" &&
                        !selectedCasteCertificate
                        ? formErrors.stud_caste_doc
                        : formValues.stud_caste !== "GENERAL" &&
                          selectedCasteCertificate &&
                          !isPdf.caste
                          ? formErrors.stud_caste_format_pdf
                          : ""}
                    </small>
                  </Form.Group>
                </Col>
              )}
            </Row>

            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="stud_present_address">
                  <Form.Label>Current Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="stud_present_address"
                    placeholder="Enter present address"
                    value={formValues.stud_present_address || ""}
                    onChange={handleChange}
                  />
                  <small className="error">
                    {formValues.stud_present_address === "" &&
                      formErrors.stud_present_address}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="stud_permanent_address">
                  <div className="d-flex justify-content-between">
                    <Form.Label>Permanent Address</Form.Label>
                    <div className="d-flex justify-content-end">
                      <Form.Check
                        id="same-as-current-address"
                        type="checkbox"
                        name="same-as-current-address"
                        className="me-2"
                        onChange={handleCheckboxChange}
                      />
                      <Form.Label className="fw-normal">
                        Same as Current Address
                      </Form.Label>
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="stud_permanent_address"
                    placeholder="Enter permanent address"
                    value={formValues.stud_permanent_address || ""}
                    onChange={handleChange}
                  />
                  <small className="error">
                    {formValues.stud_permanent_address === "" &&
                      formErrors.stud_permanent_address}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="stud_pic">
                  <Form.Label>Upload Photo</Form.Label>
                  <div className="preview-container">
                    <div className="img-preview">
                      {resultImg && (
                        <div>
                          <img src={resultImg} alt="cropped" />
                        </div>
                      )}
                    </div>
                    <div className="ms-3">
                      <Button
                        className="btn btn-primary"
                        onClick={handleShowImageModal}
                      >
                        Choose Photo
                      </Button>
                    </div>
                  </div>
                  <small className="error">
                    {!resultImg && formErrors.stud_pic}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="stud_sign">
                  <Form.Label>Upload Signature</Form.Label>
                  <div className="preview-container">
                    <div className="sign-preview">
                      {resultSign && (
                        <div>
                          <img src={resultSign} alt="cropped" />
                        </div>
                      )}
                    </div>
                    <div className="ms-3">
                      <Button
                        className="btn btn-primary"
                        onClick={handleShowSignatureModal}
                      >
                        Choose Signature
                      </Button>
                    </div>
                  </div>
                  <small className="error">
                    {!resultSign && formErrors.stud_sign}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="course_id_to">
                  <Form.Label>Course To</Form.Label>
                  <Form.Select name="course_id_to" onChange={handleChange}>
                    <option value="">Select Course</option>
                    {courseOptions.map((value) => (
                      <option key={value.course_ref} value={value.course_id}>
                        {value.course_name}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formValues.course_id_to === "" && formErrors.course_id_to}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Control
                    id="center_id"
                    type="hidden"
                    name="center_id"
                    value={formValues.center_id}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <NewAdmissionPayment
                validateStudentForm={validateStudentForm}
                formInfo={formValues}
                courseOptions={courseOptions}
                courseAll={courseAll}
                showCasteUpload={showCasteUpload}
                showOldRegCertNum={showOldRegCertNum}
                {...props}
              />
            </Row>
          </>
        )}
      </Form>
      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Form>
          <Modal.Header>
            <Modal.Title>Select Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="file"
              name="stud_pic"
              onChange={handleImage}
              accept=".jpg,.png"
            />
            <br />
            <div>
              {srcImg && (
                <div
                  style={{
                    height: "200px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Cropper
                    image={srcImg}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cropButton"
              onClick={() => {
                showCroppedImage();
                handleCloseImageModal();
              }}
            >
              Crop
            </Button>
            <Button variant="secondary" onClick={handleCloseImageModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSignatureModal}
        onHide={handleCloseSignatureModal}
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Select Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="file"
              name="stud_sign"
              onChange={handleSignature}
              accept=".jpg,.png"
            />
            <br />
            <div>
              {srcSign && (
                <div
                  style={{
                    height: "200px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Cropper
                    image={srcSign}
                    crop={cropSign}
                    zoom={zoomSign}
                    aspect={30 / 7}
                    onCropChange={setCropSign}
                    onCropComplete={onCropCompleteSignature}
                    onZoomChange={setZoomSign}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cropButton"
              onClick={() => {
                showCroppedImageSignature();
                handleCloseSignatureModal();
              }}
            >
              Crop
            </Button>
            <Button variant="secondary" onClick={handleCloseSignatureModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default NewAdmissionForm;
