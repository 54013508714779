import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  API_BATCHES_CREATE,
  API_COURSES,
  API_LISTS_FINANCIAL_YEAR,
} from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewBatchForm = (props) => {
  const navigate = useNavigate();
  const batchInitialValues = {
    financial_year: "",
    course_id: "",
    start_date: "",
    end_date: "",
    day_name: [],
    start_time: [],
    duration: [],
  };

  const [formValues, setFormValues] = useState(batchInitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [durationMonth, setDurationMonth] = useState(null);
  const [durationHour, setDurationHour] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [btnEnable, setBtnEnable] = useState(false);

  let center_ref = "";
  let user_role = "";

  const timeOptions = props.generateHours();
  const weekOptions = props.getWeekdayNames();
  const durationOptions = props.getDuration();

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    user_role = user.role.role_name;

    if (user_role == "CENTER ADMIN") {
      center_ref = "/" + center.ref;
    }
  }

  useEffect(() => {
    fetchCourseOptions();
    fetchFinYearOptions();
  }, []);

  const fetchCourseOptions = () => {
    props
      .callRequest("GET", API_COURSES + center_ref, true, null)
      .then((res) => {
        setCourseOptions(res.data.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchFinYearOptions = () => {
    props
      .callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null)
      .then((res) => {
        setFinYearOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "financial_year") {
      setFormValues({ ...formValues, financial_year: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleScheduleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSchedule = [...formValues[name]];
    updatedSchedule[index] = value;
    setFormValues({ ...formValues, [name]: updatedSchedule });
  };

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;

    const selectedCourse = courseOptions.find(
      (course) => course.course_id === parseInt(selectedCourseId)
    );
    setSelectedCourse(selectedCourse);
    setDurationMonth(
      selectedCourse ? selectedCourse.course_attributes.ca_duration_months
        : ""
    );
    setDurationHour(selectedCourse ? selectedCourse.course_attributes.ca_duration_hours : "");
    setFormValues({ ...formValues, course_id: selectedCourseId });
  };

  const handleStartDateChange = (event) => {
    const startDate = event.target.value;
    setStartDate(startDate);

    if (durationMonth) {
      const [year, month, day] = startDate.split("-");
      const startDateObj = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      const endDateObj = new Date(
        startDateObj.setMonth(startDateObj.getMonth() + parseInt(durationMonth))
      );
      const endDate = endDateObj.toISOString().slice(0, 10);
      setEndDate(endDate);
    } else {
      setEndDate("");
    }

    setFormValues({ ...formValues, start_date: startDate });
  };

  const validateBatch = () => {
    const { financial_year, course_id, start_date } = formValues;
    const errors = {};
    let isValid = true;

    if (financial_year === "") {
      isValid = false;
      errors.financial_year = "Financial year is required";
    }
    if (course_id === "") {
      isValid = false;
      errors.course_id = "Course is required";
    }
    if (start_date === "") {
      isValid = false;
      errors.start_date = "Start date is required";
    } else if (start_date !== "" && !props.isValidDate(start_date)) {
      isValid = false;
      errors.valid_start_date = "Start date is not valid";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleBatchSubmit = (e) => {
    e.preventDefault();

    if (!validateBatch()) {
      return false;
    }
    setBtnEnable(true);
    props
      .callRequest("POST", API_BATCHES_CREATE + center_ref, true, formValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/batches");
        }, 2000);
      })
      .catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  return (
    <>
      <Form onSubmit={handleBatchSubmit}>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="financial_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Select name="financial_year" onChange={handleChange}>
                <option value="">Select Financial Year</option>
                {Object.keys(finYearOptions).map((key) => (
                  <option key={key} value={finYearOptions[key]}>
                    {finYearOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.financial_year === "" && formErrors.financial_year}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="start_date">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="start_date"
                value={startDate || ""}
                onChange={handleStartDateChange}
              />
              <small className="error">
                {formValues.start_date === ""
                  ? formErrors.start_date
                  : formValues.start_date !== "" &&
                    !props.isValidDate(formValues.start_date)
                    ? formErrors.valid_start_date
                    : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="end_date">
              <Form.Label> End Date</Form.Label>
              <Form.Control
                type="date"
                name="end_date"
                value={endDate}
                readOnly
                disabled={!durationMonth || !startDate}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="course_id">
              <Form.Label>Course</Form.Label>
              <Form.Select name="course_id" onChange={handleCourseChange}>
                <option value="">Select Course</option>
                {courseOptions.map((course) => (
                  <option key={course.course_id} value={course.course_id}>
                    {course.course_name}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.course_id === "" && formErrors.course_id}
              </small>
            </Form.Group>
          </Col>
          <Col lg={2} style={{ display: selectedCourse ? "block" : "none" }}>
            <Form.Group controlId="course_duration_mnth">
              <Form.Label>Duration (Months)</Form.Label>
              <Form.Control
                type="text"
                name="course_duration_mnth"
                value={durationMonth || ""}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col lg={2} style={{ display: selectedCourse ? "block" : "none" }}>
            <Form.Group controlId="course_duration_hr">
              <Form.Label>Duration (Hours)</Form.Label>
              <Form.Control
                type="text"
                name="course_duration_hr"
                value={durationHour || ""}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Table>
            <thead>
              <tr>
                <th>Week</th>
                <th>Start Time</th>
                <th>Duration (hours)</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(weekOptions).map((option, index) => (
                <tr key={index}>
                  <td>
                    {option}{" "}
                    <input type="hidden" name="day_name" value={option} />
                  </td>
                  <td>
                    <Form.Select
                      name="start_time"
                      onChange={(e) => handleScheduleChange(e, index)}
                    >
                      <option value="">Select</option>
                      {Object.keys(timeOptions).map((key) => (
                        <option key={key} value={timeOptions[key]}>
                          {timeOptions[key]}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      name="duration"
                      onChange={(e) => handleScheduleChange(e, index)}
                    >
                      <option value="">Select</option>
                      {Object.keys(durationOptions).map((key) => (
                        <option key={key} value={durationOptions[key]}>
                          {durationOptions[key]}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
        <div className="mt-2 text-end">
          <Button
            disabled={btnEnable ? true : false}
            type="submit"
            className="btn btn-primary mt-2"
          >
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewBatchForm;
