import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { API_LISTS_COURSE, API_LISTS_FINANCIAL_YEAR, API_REPORTS_COURSE_WISE_REVENUE } from "../../config/Api";

function ViewCourseRevenueReport(props) {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    course_id: "",
    finacial_year: ""
  });
  const [formErrors, setFormErrors] = useState({});
  const [courseOptions, setCourseOptions] = useState([]);
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    fetchCourseOptions();
    fetchFinYearOptions();
  }, []);

  const fetchCourseOptions = () => {
    props.callRequest("GET", API_LISTS_COURSE, true, null).then((res) => {
      setCourseOptions(res.data.items);
    }).catch((e) => {
      console.log(e);
    });
  };

  const fetchFinYearOptions = () => {
    props.callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null).then((res) => {
      setFinYearOptions(res.data.items);
    }).catch((e) => {
      console.log(e);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "course_id") {
      setFormValues({ ...formValues, course_id: value });
    } else if (name === "finacial_year") {
      setFormValues({ ...formValues, finacial_year: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateSubmit = () => {
    const { course_id, finacial_year } = formValues;
    const errors = {};
    let isValid = true;

    if (course_id === "") {
      isValid = false;
      errors.course_id = "Please select the course";
    }
    if (finacial_year === "") {
      isValid = false;
      errors.finacial_year = "Please select the financial year";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return false;
    }

    props.callRequest("POST", API_REPORTS_COURSE_WISE_REVENUE, true, formValues).then((res) => {
      setSearchData(res.data);
    }).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    if (formValues.finacial_year === '' || formValues.course_id === '') {
      setSearchData([]);
    }
  }, [formValues]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="border-bottom pb-3">

          <Col lg={4}>
            <Form.Group controlId="finacial_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Select name="finacial_year" onChange={handleChange}>
                <option value="">
                  Select Financial Year
                </option>
                {Object.keys(finYearOptions).map((key) => (
                  <option key={key} value={finYearOptions[key]}>
                    {finYearOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.finacial_year === "" && formErrors.finacial_year}</small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="course_id">
              <Form.Label>Course</Form.Label>
              <Form.Select name="course_id" onChange={handleChange}>
                <option value="">Select Course</option>
                {Object.keys(courseOptions).map((key) => (
                  <option key={key} value={key}>
                    {courseOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.course_id === "" && formErrors.course_id}</small>
            </Form.Group>
          </Col>

          <Col lg={4} className="text-end">
            <div className="mt-3 pt-1"></div>
            <Button type="submit" className="btn btn-primary mt-2">
              Search
            </Button>
          </Col>
        </Row>

        <Row className="border-bottom py-3">
          <Col lg={4}>
            {searchData.count > 0 && searchData ? (
              <div className="">
                <h6>
                  Total <strong>{searchData.count}</strong> record(s) found
                </h6>
                <Link to={searchData.file_path} target="_blank">
                  <div className="d-flex align-items-center">
                    <i className="las la-file-download me-2"></i> Download in
                    excel
                  </div>
                </Link>
              </div>
            ) : (
              "No data found"
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ViewCourseRevenueReport;
