import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_CENTERS_UPDATE, API_CENTERS_VIEW, API_LISTS_DISTRICT } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

function EditStudyCenterForm(props) {
  const { centerRef } = props;

  const initialValues = {
    center_name: "",
    center_code: "",
    center_dist: "",
    center_phone: "",
    center_alternate_phone: "",
    center_email: "",
    center_alternate_email: "",
    center_holder: "",
    center_address: "",
    center_location: "",
  };

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [districtOptions, setDistrictOptions] = useState({});


  useEffect(() => {
    fetchStudyCenter();
    fetchDistrictOptions();
  }, []);

  const fetchStudyCenter = () => {
    props.callRequest("GET", API_CENTERS_VIEW + '/' + centerRef, true, null)
      .then((res) => {
        console.log(res.data.centers[0].ct_name, 'dd');
        setFormValues({
          ...formValues,
          center_name: res.data.centers[0].ct_name,
          center_code: res.data.centers[0].ct_code,
          center_dist: res.data.centers[0].district.dist_id,
          center_phone: res.data.centers[0].ct_phone,
          center_alternate_phone: res.data.centers[0].ct_phone2,
          center_email: res.data.centers[0].ct_email,
          center_alternate_email: res.data.centers[0].ct_email2,
          center_holder: res.data.centers[0].ct_holder_name,
          center_location: res.data.centers[0].ct_location,
          center_address: res.data.centers[0].ct_address,

        });
      }).catch((e) => {
        console.log(e);
      });
  };

  const fetchDistrictOptions = () => {
    props.callRequest("GET", API_LISTS_DISTRICT, true, null).then((res) => {
      setDistrictOptions(res.data.items);
    }).catch((e) => {
      console.log(e);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const validateStudyCenterForm = () => {
    const {
      center_name,
      center_code,
      center_dist,
      center_phone,
      center_email,
      center_holder,
      center_address,
      center_location,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (center_name === "") {
      isValid = false;
      errors.center_name =
        "Franchisee  name is required";
    } else if (center_name !== "" && !props.checkAlphaOnly(center_name)) {
      isValid = false;
      errors.center_name_not_valid = "Valid entry only alphabets, space and dot";
    }
    if (center_code === "") {
      isValid = false;
      errors.center_code =
        "Franchisee code is required";
    }
    if (center_dist === "") {
      isValid = false;
      errors.center_dist =
        "Franchisee district is required";
    }
    if (center_phone === "") {
      isValid = false;
      errors.center_phone =
        "Franchisee phone is required";
    }
    if (center_email === "") {
      isValid = false;
      errors.center_email =
        "Franchisee email is required";
    }
    if (center_holder === "") {
      isValid = false;
      errors.center_holder =
        "Franchisee holder is required";
    }
    // else if (center_holder !== "" && !props.checkAlphaOnly(center_holder)) {
    //   isValid = false;
    //   errors.center_holder_not_valid = "Valid entry only alphabets, space and dot";
    // }
    if (center_location === "") {
      isValid = false;
      errors.center_location =
        "Franchisee location is required";
    }
    if (center_address === "") {
      isValid = false;
      errors.center_address =
        "Franchisee address is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateStudyCenterForm()) {
      return false;
    }

    props
      .callRequest("POST", API_CENTERS_UPDATE + "/" + centerRef, true, formValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/study-centers");
        }, 5000);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  return (
    <>
      <Form onSubmit={handleSubmit}>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_name">
              <Form.Label>Franchisee Name</Form.Label>
              <Form.Control
                type="text"
                name="center_name"
                value={formValues.center_name ?? ""}
                placeholder="Enter Center Name"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.center_name === ""
                  ? formErrors.center_name
                  : formValues.center_name !== "" &&
                    !props.checkAlphaOnly(formValues.center_name)
                    ? formErrors.center_name_not_valid
                    : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_code">
              <Form.Label> Code</Form.Label>
              <Form.Control
                type="text"
                name="center_code"
                value={formValues.center_code ?? ""}
                placeholder="Enter Center Code"
                onChange={handleChange}
                autoComplete="off"
                onKeyDown={props.handleKeyPress}
                maxLength={3}
              />
              <small className="error">
                {formValues.center_code === "" && formErrors.center_code}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_dist">
              <Form.Label>District Name</Form.Label>
              <Form.Select
                name="center_dist"
                onChange={handleChange}
                value={formValues?.center_dist ?? ""}
              >
                <option value="">Select District Name</option>
                {
                  Object.entries(districtOptions).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
              </Form.Select>
              <small className="error">
                {formValues.center_dist === "" && formErrors.center_dist}
              </small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_phone">
              <Form.Label>Phone No.</Form.Label>
              <Form.Control
                type="text"
                name="center_phone"
                value={formValues.center_phone ?? ""}
                placeholder="e.g. 9876543210"
                onChange={handleChange}
                autoComplete="off"
                onKeyDown={props.handleKeyPress}
              />
              <small className="error">
                {formValues.center_phone === "" && formErrors.center_phone}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_alternate_phone">
              <Form.Label> Alternate Phone No.</Form.Label>
              <Form.Control
                type="text"
                name="center_alternate_phone"
                value={formValues.center_alternate_phone ?? ""}
                placeholder="e.g. 9876543210"
                onChange={handleChange}
                autoComplete="off"
                onKeyDown={props.handleKeyPress}
              />
              <small className="error">
                {formValues.center_phone === "" && formErrors.center_phone}
              </small>
            </Form.Group>
          </Col>


        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="center_email"
                value={formValues.center_email ?? ""}
                placeholder="e.g demo@example.com"
                onChange={handleChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                autoComplete="off"
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_alternate_email">
              <Form.Label>Alternate Email</Form.Label>
              <Form.Control
                type="email"
                name="center_alternate_email"
                value={formValues.center_alternate_email ?? ""}
                placeholder="e.g demo@example.com"
                onChange={handleChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_holder">
              <Form.Label>Holder's Name</Form.Label>
              <Form.Control
                type="text"
                name="center_holder"
                value={formValues.center_holder ?? ""}
                placeholder="Enter Holder's Name"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.center_holder === "" && formErrors.center_holder}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="center_location"
                placeholder="Enter location"
                value={formValues.center_location ?? ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.center_location === "" && formErrors.center_location}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="center_address"
                placeholder="Enter address"
                value={formValues.center_address ?? ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.center_address === "" && formErrors.center_address}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-2 text-end">
          <Button type="submit" className="btn btn-primary mt-2">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}

export default EditStudyCenterForm;
