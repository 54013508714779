import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_CENTERS, API_CENTERS_SEARCH } from "../../config/Api";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../helpers/DataTableSettings";
import RoleBasedButton from "../shared/RoleBasedButton";

const ListCenter = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_role = user.role.role_name;
  const [centerFormValues, setCenterFormValues] = useState({
    search_by: "",
    search_keyword: "",
  });
  const [showCenter, setShowCenter] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [centerData, setCenterData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const searchParam = [
    "ct_code",
    "ct_name",
    "ct_location",
    "dist_name",
    "ct_holder_name",
  ];
  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [close, setClose] = useState(false);

  const centerSearchOptions = [
    { value: 'Select', label: 'Select Option ' },
    { value: 'FRANCHISEE CODE', label: '001' },
    { value: 'FRANCHISEE NAME ', label: 'Webel Education' },
    { value: 'FRANCHISEE LOCATION', label: 'Sector V' },
    { value: 'DISTRICT', label: 'KOLKATA' },
    { value: 'HOLDER', label: 'Mr. Jon Doe' },
  ];

  const getCenterFilterData = () => {
    const selectedOption = centerSearchOptions.find(option => option.value === centerFormValues.search_by);
    return selectedOption ? `e.g. ${selectedOption.label}` : 'Search Keyword';
  };

  const handleCenterToggle = () => setShowCenter(!showCenter);

  useEffect(() => {
    fetchCenters();
  }, []);

  const fetchCenters = () => {
    props
      .callRequest("GET", API_CENTERS, true, null)
      .then((res) => {
        setCenterData(res.data.centers);
        setLoadingIndicator(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columnsArr = [
    {
      name: <h6>Code</h6>,
      selector: (row) => row.ct_code,
      sortable: true,
      width: "10%",
      cell: (row) => (
        <div>
          {row.ct_code}
          {row.is_active === 0 &&
            <div className="inactive"></div>
          }
        </div>
      ),
    },
    {
      name: <h6>Franchisee Name</h6>,
      selector: (row) => row.ct_name,
      sortable: true,
      width: "30%",
      cell: (row) => (
        <Link to={`/study-centers/view/${row.ct_ref}`}>
          {props.getExcerpt(row.ct_name, 30)}
        </Link>
      ),
    },
    {
      name: <h6>Location</h6>,
      selector: (row) => row.ct_location,
      sortable: true,
      width: `${user_role === "SUPERADMIN", "ADMIN" ? "15%" : "20%"}`,
    },
    {
      name: <h6>District</h6>,
      selector: (row) => row.district.dist_name,
      sortable: true,
      width: `${user_role === "SUPERADMIN", "ADMIN" ? "15%" : "20%"}`,
    },
    {
      name: <h6>Holder's Name</h6>,
      selector: (row) => row.ct_holder_name,
      sortable: true,
      width: "20%",
    },
    {
      name: <h6>Action</h6>,
      width: "10%",
      center: true,
      cell: (row) => (
        <RoleBasedButton
          type="edit_center"
          url={`/study-centers/edit/${row.ct_ref}`}
          user_role={user_role}
        />
      ),
    },
  ];

  const columns = user_role === "SUPERADMIN" || user_role === "ADMIN" ? columnsArr : columnsArr.slice(0, -1);

  const handleCenterFilterChange = (e) => {
    const { name, value } = e.target;
    setCenterFormValues({ ...centerFormValues, [name]: value, search_keyword: '' });
  };

  const validateSearchFilter = () => {
    let isValid = true;
    const errors = {};

    if (
      centerFormValues.search_by === undefined ||
      centerFormValues.search_by === ""
    ) {
      isValid = false;
      errors.search_by = "Search By is required";
    }
    if (
      centerFormValues.search_keyword === undefined ||
      centerFormValues.search_keyword === ""
    ) {
      isValid = false;
      errors.search_keyword = "Search Keyword is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const searchCenters = () => {
    if (!validateSearchFilter()) {
      return false;
    }
    props
      .callRequest("POST", API_CENTERS_SEARCH, true, centerFormValues)
      .then((res) => {
        setCenterData(res.data.centers);
        handleCenterToggle();
        setClose(true);
      })
      .catch((e) => {
        props.showDialog("error", e.response.data.message, 2000);
      });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>All Franchisee</h5>
          <div className="d-flex justify-content-end">
            {!close ? (
              <Button
                className="link-action"
                onClick={() => {
                  handleCenterToggle();
                  setCenterFormValues("");
                }}
              >
                <i className="las la-filter me-2"></i>Filter
              </Button>
            ) : (
              <Button
                className="link-action"
                onClick={() => {
                  setClose(false);
                  fetchCenters();
                }}
              >
                <i className="las la-times me-2"></i>Filter
              </Button>
            )}

            <RoleBasedButton
              type="add_center"
              url={"/study-centers/new"}
              user_role={user_role}
            />
          </div>
        </div>
        <div className="card-body">
          <Modal
            show={showCenter}
            onHide={handleCenterToggle}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col lg={5}>
                    <Form.Group className="mb-3" controlId="search_by">
                      <Form.Label>Search By</Form.Label>
                      <select
                        className="form-select"
                        value={centerFormValues.search_by || ""}
                        name="search_by"
                        onChange={handleCenterFilterChange}
                      >
                        {centerSearchOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.value}
                          </option>
                        ))}
                      </select>
                      <small className="error">{!centerFormValues.search_by && formErrors.search_by}</small>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="search_keyword">
                      <Form.Label>Search Keyword</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={getCenterFilterData()}
                        name="search_keyword"
                        value={centerFormValues.search_keyword || ""}
                        onChange={(event) => setCenterFormValues({ ...centerFormValues, search_keyword: event.target.value })}
                        autoComplete="off"
                      />
                      <small className="error">
                        {!centerFormValues.search_keyword && formErrors.search_keyword}
                      </small>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCenterToggle}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  searchCenters();
                }}
              >
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
          {centerData ? (
            <DataTable
              columns={columns}
              data={DataTableSettings.filterItems(
                centerData,
                searchParam,
                filterText
              )}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              progressPending={loadingIndicator}
              subHeader
              fixedHeaderScrollHeight="400px"
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
            />
          ) : (
            "No center found"
          )}
        </div>
      </div>
    </div>
  );
};

export default ListCenter;
