import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import Topbar from "../../components/common/Topbar";
import MobileLogo from "../../components/common/MobileLogo";
import Sidebar from "../../components/common/Sidebar";
import OldAdmissionForm from "../../components/admission/OldAdmissionForm";

const OldAdmissionScreen = (props) => {
  const navigate = useNavigate();

  const [showTopbar, setShowTopbar] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);

  const handleShowTopbar = () => {
    setShowTopbar(!showTopbar);
  };
  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleChangeIcon = () => {
    setChangeIcon(!changeIcon);
  };

  const sidebarRef = useRef();
  useEffect(() => {
    let clickToCloseSidebar = (e) => {
      if (sidebarRef.current.contains(e.target)) {
        setShowSidebar(false);
        // console.log(sidebarRef.current)
      }
    };

    document.addEventListener("mousedown", clickToCloseSidebar);
    return () => {
      document.removeEventListener("mousedown", clickToCloseSidebar);
    };
  }, []);
  return (
    <>
      <MobileLogo
        handleShowTopbar={handleShowTopbar}
        changeIcon={changeIcon}
        handleChangeIcon={handleChangeIcon}
        handleShowSidebar={handleShowSidebar}
      />
      <Sidebar
        showSidebar={showSidebar}
        handleShowSidebar={handleShowSidebar}
      />
      <Topbar showTopbar={showTopbar} />

      <div className="pc-container" ref={sidebarRef}>
        <div className="pcoded-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="page-header-title">
                    <h5 className="m-b-10">Admissions</h5>
                  </div>
                </div>
                <div className="col-md-6 text-end">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={"/admissions"}>Admissions</Link>
                    </li>
                    <li className="breadcrumb-item">New Admission</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Old Admission</h5>

                  <div className="d-flex justify-content-end">
                    {/* <Button className="link-action"
                      onClick={()=> navigate("/admissions/old/upload")}
                    >Upload</Button> */}

                    <Button
                      className="link-action ms-3"
                      onClick={() => navigate("/admissions")}
                    >
                      Back to Admission List
                    </Button>
                  </div>
                </div>

                <div className="card-body">
                  <p className="highlight">
                    Before adding any new admissions, you must ensure that all existing students are entered into the system. After completing the details of the existing students, you will be redirected to the new student admission.
                  </p>
                  <OldAdmissionForm {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldAdmissionScreen;
