import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  API_ADMISSIONS_RECONCILIATION,
  API_ADMISSIONS_SAVE_RECONCILIATION,
  API_LISTS_CENTER,
  API_LISTS_FINANCIAL_YEAR,
  API_WEB_DOMAIN,
} from "../../config/Api";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../helpers/DataTableSettings";

function Reconciliation(props) {
  const [formValues, setFormValues] = useState({
    financial_year: "",
    month: "",
    center_id: "",
    mr_ref: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [centerOptions, setCenterOptions] = useState([]);
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const monthOptions = Object.values(props.getMonth());


  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [close, setClose] = useState(false);
  const [page] = useState(1);
  const [newPerPage, setNewPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);


  useEffect(() => {
    fetchCenterOptions();
    fetchFinancialYearOptions();
  }, []);

  const fetchCenterOptions = () => {
    props
      .callRequest("GET", API_LISTS_CENTER, true, null)
      .then((res) => {
        setCenterOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchFinancialYearOptions = () => {
    props
      .callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null)
      .then((res) => {
        setFinYearOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "financial_year") {
      setFormValues({ ...formValues, financial_year: value });
    } else if (name === "month") {
      setFormValues({ ...formValues, month: value });
    } else if (name === "center_id") {
      setFormValues({ ...formValues, center_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateSubmit = () => {
    const { financial_year, month, center_id } = formValues;
    const errors = {};
    let isValid = true;

    if (financial_year === "") {
      isValid = false;
      errors.financial_year = "Please select the financial year";
    }
    if (month === "") {
      isValid = false;
      errors.month = "Please select the month";
    }


    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ADMISSIONS_RECONCILIATION, true, formValues)
      .then((res) => {
        setSearchData(res.data.reconciliation);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reconcileMR = (ref) => {
    if (ref.length === 32) {
      setFormValues({ ...formValues, mr_ref: ref });
    }
  };

  useEffect(() => {
    if (formValues.mr_ref) {
      saveReconciliation(formValues);
    }
  }, [formValues.mr_ref]);

  const saveReconciliation = (params) => {
    props
      .callRequest("POST", API_ADMISSIONS_SAVE_RECONCILIATION, true, params)
      .then((res) => {
        setSearchData(res.data.reconciliation);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = [
    {
      name: <h6>Franchisee</h6>,
      selector: (row) => row.center_name,
      width: "40%",
      cell: (row) => (
        <Link
          to={`/study-centers/view/${row.center_ref}`}
          target="_blank"
        >
          {row.center_name}
        </Link>
      ),
    },
    {
      name: <h6>Royalty Generated</h6>,
      selector: (row) => props.getCurrencyFormat(row.royalty_generated),
      width: "20%",
      right: true
    },


    {
      name: <h5>Royalty Recevied</h5>,
      selector: (row) => props.getCurrencyFormat(row.royalty_received),
      width: "20%",
      right: true
    },
    {
      name: <h5>Royalty Pending</h5>,
      selector: (row) => props.getCurrencyFormat(row.royalty_generated - row.royalty_received),
      width: "20%",
      right: true
    },

  ];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="pb-3">
          <Col lg={3}>
            <Form.Group controlId="financial_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Select name="financial_year" onChange={handleChange}>
                <option value="">Select</option>
                {Object.keys(finYearOptions).map((key) => (
                  <option key={key} value={finYearOptions[key]}>
                    {finYearOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.financial_year === "" && formErrors.financial_year}</small>
            </Form.Group>
          </Col>

          <Col lg={2}>
            <Form.Group controlId="month">
              <Form.Label>Month</Form.Label>
              <Form.Select name="month" onChange={handleChange}>
                <option value="">Select</option>
                {monthOptions.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.month === "" && formErrors.month}</small>
            </Form.Group>
          </Col>

          <Col lg={5}>
            <Form.Group controlId="center_id">
              <Form.Label>Franchisee</Form.Label>
              <Form.Select name="center_id" onChange={handleChange}>
                <option value="">All</option>
                {Object.keys(centerOptions).map((key) => (
                  <option key={key} value={key}>
                    {centerOptions[key]}
                  </option>
                ))}
              </Form.Select>

            </Form.Group>
          </Col>

          <Col lg={2}>
            <div className="mt-3 pt-1"></div>
            <Button type="submit" className="btn btn-primary mt-2">
              Search
            </Button>
            <input type="hidden" name="mr_ref" value={formValues.mr_ref} />
          </Col>
        </Row>
      </Form>

      <div className="card">
        <div className="card-header">
          <h5>Reconciliation</h5>
        </div>
        <div className="card-body">
          {searchData ? (
            <DataTable
              columns={columns}
              data={searchData}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              fixedHeader
              subHeader
              fixedHeaderScrollHeight="400px"
              highlightOnHover

            />
          ) : (
            "No search data found"
          )}
        </div>
      </div>
    </>
  );
}

export default Reconciliation;
