import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { API_CENTERS_SAVE_RECONCILIATION } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

const ReconciliationPayment = (props) => {
  const [formValues, setFormValues] = useState({
    pay_by: "",
    payment_date: props.convertDateStringToYMD(new Date()),
    chq_num: "",
    chq_date: "",
    chq_bank: "",
    center_id: props.center_id,
    royalty_amount: props.royalty_amount,
    royalty_recpt_nums: props.royalty_recpt_nums,
  });
  const [formErrors, setFormErrors] = useState({});
  const [showPaymentInfo, setShowPaymentInfo] = useState("");
  const [btnEnable, setBtnEnable] = useState(false);

  useEffect(() => {
    setFormValues({
      ...formValues,
      royalty_amount: props.royalty_amount,
      royalty_recpt_nums: props.royalty_recpt_nums,
    });
    setBtnEnable(props.royalty_amount === 0 ? true : false);
  }, [props.royalty_amount, props.royalty_recpt_nums]);

  const handlePaymentModeChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, pay_by: value ? value : "" });
    if (value !== "CASH") {
      setShowPaymentInfo(value);
    } else {
      setShowPaymentInfo("CASH");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validatePayment = () => {
    const { pay_by, payment_date, chq_num, chq_date, chq_bank } = formValues;
    const errors = {};
    let isValid = true;

    if (pay_by === "") {
      isValid = false;
      errors.pay_by = "Payment mode is required";
    }
    if (pay_by === "CASH") {
      if (!props.isValidDate(payment_date)) {
        isValid = false;
        errors.validate_payment_date = "Admission date is not valid";
      } else if (!props.compareDate(payment_date)) {
        isValid = false;
        errors.valid_compare_pay_date = "Payment date should be less than today";
      }
    }
    if (["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(pay_by)) {
      if (chq_num === "") {
        isValid = false;
        errors.chq_num = "Cheque / DD/Ref No. is required";
      }
      if (chq_date === "") {
        isValid = false;
        errors.chq_date = "Cheque / DD Date is required";
      } else if (chq_date !== "" && !props.isValidDate(chq_date)) {
        isValid = false;
        errors.validate_chq_date = "Cheque date is not valid";
      } else if (!props.compareDate(chq_date)) {
        isValid = false;
        errors.valid_compare_chq_date = "Cheque date should be less than today";
      }
      if (chq_bank === "") {
        isValid = false;
        errors.chq_bank = "Bank name is required";
      }
      if (!props.isValidDate(payment_date)) {
        isValid = false;
        errors.validate_payment_date = "Payment date is not valid";
      }
      else if (!props.compareDate(chq_date)) {
        isValid = false;
        errors.valid_compare_pay_date = "Payment date should be less than today";
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSaveRoyalty = () => {
    if (!validatePayment()) {
      return false;
    }
    setBtnEnable(true);
    props
      .callRequest("POST", API_CENTERS_SAVE_RECONCILIATION, true, formValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        });
        setTimeout(() => {
          window.location.replace("/center-reconciliation");
        }, 4000);
      })
      .catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="mt-2">
        <div className="col-xl-12 col-sm-12 mt-4">
          <h5 className="fw-bold text-muted">Payment Details</h5>
        </div>
        <Row className="mb-3 mt-3">
          <Col lg={4}>
            <Form.Group>
              <Form.Label>Payment Mode</Form.Label>
              <Form.Select
                name="pay_by"
                value={formValues.pay_by}
                onChange={handlePaymentModeChange}
              >
                {props.getPaymentMode().map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.pay_by === "" && formErrors.pay_by}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="payment_date">
              <Form.Label>Payment Date</Form.Label>
              <Form.Control
                type="date"
                name="payment_date"
                value={formValues.payment_date}
                placeholder="Enter Payment Date"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {!props.isValidDate(formValues.payment_date)
                  ? formErrors.validate_payment_date
                  : !props.compareDate(formValues.payment_date)
                    ? formErrors.valid_compare_pay_date : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="royalty_amount">
              <Form.Label>Royalty Amount</Form.Label>
              <Form.Control
                type="text"
                name="royalty_amount"
                value={props.getCurrencyFormat(formValues.royalty_amount)}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(showPaymentInfo) && (
          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="">
                <Form.Label>Cheque / DD No.</Form.Label>
                <Form.Control
                  type="text"
                  name="chq_num"
                  value={formValues.chq_num}
                  placeholder="Enter Cheque/DD Number"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="error">
                  {formValues.chq_num === "" && formErrors.chq_num}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="date">
                <Form.Label>Cheque / DD Date</Form.Label>
                <Form.Control
                  type="date"
                  name="chq_date"
                  value={formValues.chq_date}
                  placeholder="Enter Cheque / DD Date"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="error">
                  {formValues.chq_date === ""
                    ? formErrors.chq_date
                    : formValues.chq_date !== "" &&
                      !props.isValidDate(formValues.chq_date)
                      ? formErrors.validate_chq_date
                      : !props.compareDate(formValues.chq_date)
                        ? formErrors.valid_compare_chq_date : ""}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  name="chq_bank"
                  value={formValues.chq_bank}
                  onChange={handleChange}
                  placeholder="Enter Bank Name"
                  autoComplete="off"
                />
                <small className="error">
                  {formValues.chq_bank === "" && formErrors.chq_bank}
                </small>
              </Form.Group>
            </Col>
          </Row>
        )}
      </div>
      <div className="mt-2 text-end">
        <Button
          disabled={btnEnable ? true : false}
          onClick={() => handleSaveRoyalty()}
          className="btn btn-primary mt-2"
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default ReconciliationPayment;
