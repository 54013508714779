import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { API_ADMISSIONS_VIEW, API_WEB_DOMAIN } from "../../config/Api";

function ViewAdmission(props) {
  const { studentRef } = props;
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [discountType, setDiscountType] = useState([]);
  const [hasDue, setHasDue] = useState(true);

  useEffect(() => {
    fetchAdmission();
  }, []);

  const fetchAdmission = () => {
    props
      .callRequest("GET", API_ADMISSIONS_VIEW + "/" + studentRef, true, null)
      .then((res) => {
        setApiData(res.data.admission_info);
        setPaymentCount(res.data.admission_info.payment_info.length);
        setDiscountType(
          res.data.admission_info.student_info.stud_discounts ?
            JSON.parse(res.data.admission_info.student_info.stud_discounts)
            : []);
        setPaymentData(res.data.admission_info.payment_info);

        if (res.data.admission_info.payment_info.length > 0) {
          for (
            let i = 0;
            i < res.data.admission_info.payment_info.length;
            i++
          ) {
            if (res.data.admission_info.payment_info[i].pmt_bal === "0.00") {
              setHasDue(false);
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDownloadCasteClick = (e) => {
    e.preventDefault();
    const pdfURL = API_WEB_DOMAIN + apiData.student_info.stud_caste_document;
    window.open(pdfURL, "_blank");
  };

  const handleDownloadQualificationClick = (e) => {
    e.preventDefault();
    const pdfURL = API_WEB_DOMAIN + apiData.student_info.stud_edu_qual_path;
    window.open(pdfURL, "_blank");
  };

  const handleDownloadIDCardClick = (e) => {
    e.preventDefault();
    const pdfURL = API_WEB_DOMAIN + apiData.student_info.stud_govt_id_path;
    window.open(pdfURL, "_blank");
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View Admission</h5>
          <div className="d-flex justify-content-end">
            <Button
              className="link-action"
              onClick={() => {
                navigate(`/admissions/edit/${studentRef}`);
              }}
            >
              <i className="las la-edit me-2"></i>Edit
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-8 col-lg-8">
                  <div className="d-flex align-items-center justify-content-start">
                    <img
                      src={API_WEB_DOMAIN + apiData.student_info.stud_pic}
                      alt=""
                      className="admission-profile-thumb me-3"
                    />
                    <div className="ms-3">
                      <h4 className="fw-bold m-0 me-2">
                        {apiData.student_info.stud_fullname}
                      </h4>
                      {apiData.student_info.stud_reg_no === null ? (
                        ""
                      ) : (
                        <>
                          <h5>
                            {apiData.student_info.stud_reg_no}
                            {apiData.student_info.stud_reg_no2 ? (
                              <span className="ms-3 text-muted">
                                [Old Registration Num:{" "}
                                {apiData.student_info.stud_reg_no2}]
                              </span>
                            ) : (
                              ""
                            )}
                          </h5>
                          <Link
                            to={
                              API_WEB_DOMAIN +
                              "download/admission-form/" +
                              apiData.student_info.stud_ref
                            }
                            target="_blank"
                          >
                            Download Admission Form
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-phone me-2"></i>
                    <span>{apiData.student_info.stud_phone}</span>
                    {
                      apiData.student_info.stud_phone2 &&
                      <span>&nbsp;/&nbsp;{apiData.student_info.stud_phone2}</span>
                    }

                  </div>
                  <div className="d-flex align-items-center">
                    <i className="las la-envelope me-2"></i>
                    <span>{apiData.student_info.stud_email}</span>
                  </div>
                  <div className="d-flex ">
                    <div className="text-muted lbl-w50">Admission Date:</div>{" "}
                    <span className="ml-4 ">
                      {props.getFormatedDate(
                        apiData.student_info.stud_admission_date
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-8 col-sm-8 mt-4">
                  <h5 className="fw-bold">
                    {apiData.center_info.ct_name} [{apiData.center_info.ct_code}
                    ]
                  </h5>

                  <div className="d-flex align-items-center">
                    <i className="las la-map-marker me-2"></i>
                    <span>{apiData.center_info.ct_address}</span>
                  </div>

                  <div className="d-flex align-items-center">
                    <i className="las la-phone me-2"></i>
                    <span>{apiData.center_info.ct_phone}</span>
                  </div>

                  <div className="d-flex align-items-center">
                    <i className="las la-envelope me-2"></i>
                    <span>{apiData.center_info.ct_email}</span>
                  </div>
                </div>

                <div className="col-xl-4 col-sm-4 mt-4">
                  <h5 className="fw-bold">
                    <Link
                      to={`/courses/view/${apiData.course_info.course_ref}`}
                    >
                      {apiData.course_info.course_name}
                    </Link>
                  </h5>
                  <div className="text-muted lbl-w72">Batch:</div>{" "}
                  {apiData.batch_info !== null ? (
                    <span>
                      <Link to={`/batches/view/${apiData.batch_info.ba_ref}`}>
                        {apiData.batch_info.ba_batch_name}
                      </Link>
                    </span>
                  ) : (
                    <Link
                      className="text-blink text-danger"
                      to={`/admissions/batch_assign/${studentRef}`}
                    >
                      Not Assign
                    </Link>
                  )}
                  <br />
                  <div className="text-muted lbl-w72">Fees:</div>{" "}
                  {props.getCurrencyFormat(apiData.course_info.course_attributes.
                    ca_fees)}
                  <br />
                  <div className="text-muted lbl-w72">Duration:</div>{" "}
                  {apiData.course_info.course_attributes
                    .ca_duration_hours
                  } hours /{" "}
                  {apiData.course_info.course_attributes.ca_duration_months} months
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-4 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">
                    Father's / Husband's Name
                  </h5>
                  <span>{apiData.student_info.stud_guardian_name}</span>
                </div>

                <div className="col-xl-4 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Date of Birth</h5>
                  <span>
                    {props.getFormatedDate(apiData.student_info.stud_dob)}
                  </span>
                </div>

                <div className="col-xl-4 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Gender</h5>
                  <span>{apiData.student_info.stud_gender}</span>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-3 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">
                    Education Qualification
                  </h5>
                  <span>{apiData.student_info.stud_edu_qual}</span>
                </div>
                <div className="col-xl-3 col-sm-6 mt-4">
                  {apiData.student_info.stud_edu_qual_path !== "" ? (
                    <>
                      <h5 className="fw-bold text-muted">
                        Qualification Document
                      </h5>
                      <Link
                        to="/"
                        onClick={handleDownloadQualificationClick}
                        className="d-flex align-items-center"
                      >
                        <i className="las la-file-pdf me-2"></i> Download
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-xl-3 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Category</h5>
                  <span>{apiData.student_info.stud_caste}</span>
                </div>
                <div className="col-xl-3 col-sm-6 mt-4">
                  {apiData.student_info.stud_caste_document !== "" ? (
                    <>
                      <h5 className="fw-bold text-muted">Caste Document</h5>
                      <Link
                        to="/"
                        onClick={handleDownloadCasteClick}
                        className="d-flex align-items-center"
                      >
                        <i className="las la-file-pdf me-2"></i> Download
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-4 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Government ID Type</h5>
                  <span>{apiData.student_info.stud_govt_id_type}</span>
                </div>
                <div className="col-xl-4 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Government ID Number</h5>
                  <span>{apiData.student_info.stud_govt_id_num}</span>
                </div>
                <div className="col-xl-4 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Government ID</h5>
                  <Link
                    to="/"
                    onClick={handleDownloadIDCardClick}
                    className="d-flex align-items-center"
                  >
                    <i className="las la-file-pdf me-2"></i> Download
                  </Link>
                </div>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-6 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Current Address</h5>
                  <span>{apiData.student_info.stud_present_address}</span>
                </div>
                <div className="col-xl-6 col-sm-6 mt-4">
                  <h5 className="fw-bold text-muted">Permanent Address</h5>
                  <span>{apiData.student_info.stud_permanent_address}</span>
                </div>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Discount Information</h5>
                  <Table className="table table-bordered table-striped table-hover">
                    <tr>
                      <th>Discount Type</th>
                      <th>Amount</th>
                    </tr>
                    {
                      discountType.map((item, i) => (
                        <tr key={i}>
                          <td>{item.label}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))
                    }
                  </Table>
                </div>
              </div>
              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Payment Information</h5>
                  {paymentCount > 0 ? (
                    <Table className="table table-bordered table-striped table-hover">
                      <tr>
                        <th>Date</th>
                        <th>MR #</th>
                        <th>Payment</th>
                        <th>Payment Mode</th>
                        <th>Amount Paid</th>
                        <th>Amount Due</th>
                        <th>&nbsp;</th>
                      </tr>
                      {paymentData.map((payment, i) => {
                        return (
                          <tr key={payment.payments_id}>
                            <td>
                              {props.getFormatedDate(payment.payment_date)}
                            </td>
                            <td>{payment.recpt_num}</td>
                            <td>{payment.remarks}</td>
                            <td>{payment.pay_by}</td>
                            <td>{props.getCurrencyFormat(payment.pmt_amt)}</td>
                            <td>{props.getCurrencyFormat(payment.pmt_bal)}</td>
                            <td className="text-center">
                              <Link
                                to={
                                  API_WEB_DOMAIN +
                                  `download/money-receipt/${payment.payments_ref}`
                                }
                                target="_blank"
                              >
                                <i className="las la-file-download"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  ) : (
                    <div className="">No payment made</div>
                  )}
                  {hasDue ? (
                    <div className="mt-2 text-end">
                      <Link
                        to={`/admissions/payment/${studentRef}`}
                        className="btn btn-primary link-action"
                      >
                        Go to Payment
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div >
  );
}

export default ViewAdmission;
