import React from 'react';

const MaintenanceMessage = () => {
    return (
        <div className="maintenance-container">
            <img src="/assets/images/maintenance.png"/>
            <h6>We are currently performing maintenance on our website. Please check back later.</h6>
        </div>
    );
};

export default MaintenanceMessage;