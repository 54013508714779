import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  API_LISTS_FINANCIAL_YEAR,
  API_CENTERS_RECONCILIATION,
} from "../../config/Api";
import Table from "react-bootstrap/Table";
import ReconciliationPayment from "./ReconciliationPayment";

const ReconciliationCenter = (props) => {
  const monthOptions = Object.values(props.getMonth());
  const center = JSON.parse(localStorage.getItem("center"));
  const [formValues, setFormValues] = useState({
    financial_year: "",
    month: "",
    center_id: center.ct_id,
    royalty_recpt_nums: [],
    all_checked: false,
    pmt_amount: 0,
    royalty_amount: 0,
    total_royalty: 0,
  });
  const [errors, setErrors] = useState({});
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    fetchFinancialYearOptions();
  }, []);

  const fetchFinancialYearOptions = () => {
    props
      .callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null)
      .then((res) => {
        setFinYearOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "financial_year") {
      setFormValues({ ...formValues, financial_year: value });
    } else if (name === "month") {
      setFormValues({ ...formValues, month: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateSearch = () => {
    const { financial_year, month } = formValues;
    const errors = {};
    let isValid = true;

    if (financial_year === "") {
      isValid = false;
      errors.financial_year = "Please select the financial year";
    }
    if (month === "") {
      isValid = false;
      errors.month = "Please select the month";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSearch = () => {
    if (!validateSearch()) {
      return false;
    }
    const search_filter_obj = {
      financial_year: formValues.financial_year,
      month: formValues.month,
      center_id: formValues.center_id,
    };
    props
      .callRequest("POST", API_CENTERS_RECONCILIATION, true, search_filter_obj)
      .then((res) => {
        let pmt_amt = 0;
        let royalty = 0;

        let temp_state = res.data.reconciliation.map((item) => {
          pmt_amt += Number(item.pmt_amt);
          royalty += Number(item.royalty);
          if (item.is_reconciled === 1) {
            item.isChecked = true;
          }
          return item;
        });
        setSearchData(temp_state);
        setFormValues({
          ...formValues,
          pmt_amount: pmt_amt,
          total_royalty: royalty,
          all_checked: false,
          royalty_recpt_nums: [],
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCheckboxChange = (e) => {
    let item_name = e.target.name;
    let checked = e.target.checked;
    let list = [];
    let temp_recpt_nums = [];
    let all_checked = false;
    let royalty = 0;

    if (item_name === "checkAll") {
      list = searchData.map((item) => ({ ...item, isChecked: checked }));
      setSearchData(list);
      list.map((item) => {
        if (item.isChecked) {
          if (item.is_reconciled === 0) {
            temp_recpt_nums.push(item.recpt_num);
            royalty += Number(item.royalty);
          }
        }
      });
      setFormValues({
        ...formValues,
        all_checked: checked,
        royalty_recpt_nums: temp_recpt_nums,
        royalty_amount: royalty,
      });
    } else {
      list = searchData.map((item) =>
        item.recpt_num === item_name ? { ...item, isChecked: checked } : item
      );
      setSearchData(list);
      list.map((item) => {
        if (item.isChecked) {
          if (item.is_reconciled === 0) {
            temp_recpt_nums.push(item.recpt_num);
            royalty += Number(item.royalty);
          }
        }
      });
      all_checked = list.every((item) => item.isChecked);
      setFormValues({
        ...formValues,
        all_checked: all_checked,
        royalty_recpt_nums: temp_recpt_nums,
        royalty_amount: royalty,
      });
    }
  };

  return (
    <>
      <div>
        <Row className="pb-3">
          <Col lg={3}>
            <Form.Group controlId="financial_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Select name="financial_year" onChange={handleChange}>
                <option value="">Select</option>
                {Object.keys(finYearOptions).map((key) => (
                  <option key={key} value={finYearOptions[key]}>
                    {finYearOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.financial_year === "" && errors.financial_year}
              </small>
            </Form.Group>
          </Col>

          <Col lg={2}>
            <Form.Group controlId="month">
              <Form.Label>Month</Form.Label>
              <Form.Select name="month" onChange={handleChange}>
                <option value="">Select</option>
                {monthOptions.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.month === "" && errors.month}
              </small>
            </Form.Group>
          </Col>

          <Col lg={2}>
            <div className="mt-3 pt-1"></div>
            <Button onClick={handleSearch} className="btn btn-primary mt-2">
              Search
            </Button>
          </Col>
        </Row>
      </div>

      <div className="card">
        <div className="card-header">
          <h5>Reconciliation</h5>
        </div>
        {searchData.length > 0 ? (
          <div className="card-body">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      name="checkAll"
                      checked={
                        formValues.all_checked ? formValues.all_checked : false
                      }
                      onChange={handleCheckboxChange}
                    />
                  </th>
                  <th>Payment Date</th>
                  <th>MR No.</th>
                  <th>Student Name</th>
                  <th>Registration No.</th>
                  <th className="text-center">Amount</th>
                  <th className="text-center">Royalty</th>
                </tr>
              </thead>
              <tbody>
                {searchData.map((item) => {
                  return (
                    <tr key={item.recpt_num}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          name={item.recpt_num}
                          value={item.recpt_num ?? ""}
                          checked={
                            item.isChecked
                              ? item.isChecked
                              : item.is_reconciled === 1
                                ? true
                                : false
                          }
                          disabled={item.is_reconciled === 1 ? true : false}
                          onChange={handleCheckboxChange}
                        />
                      </td>
                      <td>{item.payment_date}</td>
                      <td>{item.recpt_num}</td>
                      <td>{item.stud_fullname}</td>
                      <td>{item.stud_reg_no}</td>
                      <td className="text-end">{props.getCurrencyFormat(item.pmt_amt)}</td>
                      <td className="text-end">{props.getCurrencyFormat(item.royalty)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={5}></td>
                  <td className="fw-bold text-end">{props.getCurrencyFormat(formValues.pmt_amount)}</td>
                  <td className="fw-bold text-end">{props.getCurrencyFormat(formValues.total_royalty)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center p-2">
            No records to display
          </div>
        )}
      </div>
      <div className="card">
        <div className="card-body">
          <ReconciliationPayment
            royalty_recpt_nums={formValues.royalty_recpt_nums}
            center_id={formValues.center_id}
            royalty_amount={
              formValues.royalty_recpt_nums.length > 0
                ? formValues.royalty_amount
                : 0
            }
            {...props}
          />
        </div>
      </div>
    </>
  );
};

export default ReconciliationCenter;
