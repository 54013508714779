import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const RoleBasedButton = (props) => {
    const { type, url, user_role } = props;
    const navigate = useNavigate();

    switch (type) {
        case 'add_admission':
            if (user_role === "CENTER ADMIN") {
                return (
                    <div>
                        <Button
                            className="link-action ms-3"
                            onClick={() => navigate(url)}
                        >
                            New Admission
                        </Button>
                    </div>
                );
            } else {
                return null;
            }

        case 'edit_admission':
            if (["CENTER ADMIN", "SUPERADMIN", "ADMIN"].includes(user_role)) {
                return (
                    <Link to={url}>
                        <i className="las la-edit"></i>
                    </Link>
                );
            } else {
                return null;
            }

        case 'add_center':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <div>
                        <Button
                            className="link-action ms-3"
                            onClick={() => navigate(url)}
                        >
                            New Franchisee
                        </Button>
                    </div>
                );
            } else {
                return null;
            }

        case 'edit_center':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <Link to={url} className="text-center">
                        <i className="las la-edit"></i>
                    </Link>
                );
            } else {
                return null;
            }

        case 'add_course':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <div>
                        <Button
                            className="link-action ms-3"
                            onClick={() => navigate(url)}
                        >
                            New Course
                        </Button>
                    </div>
                );
            } else {
                return null;
            }

        case 'edit_course':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <Link to={url}>
                        <i className="las la-edit"></i>
                    </Link>
                );
            } else {
                return null;
            }

        case 'course_new_fees':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <Link to={url}>
                        <i className="las la-rupee-sign"></i>
                    </Link>
                );
            } else {
                return null;
            }

        case 'add_user':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <div>
                        <Button
                            className="link-action"
                            onClick={() => navigate(url)}
                        >
                            New User
                        </Button>
                    </div>
                );
            } else {
                return null;
            }

        case 'edit_user':
            if (user_role === "SUPERADMIN" || user_role === "ADMIN") {
                return (
                    <Link to={url}>
                        <i className="las la-edit"></i>
                    </Link>
                );
            } else {
                return null;
            }
        case 'add_role':
            if (user_role === "SUPERADMIN") {
                return (
                    <div>
                        <Button
                            className="link-action"
                            onClick={() => navigate(url)}
                        >
                            New Role
                        </Button>
                    </div>
                );
            } else {
                return null;
            }
        case 'edit_role':
            if (user_role === "SUPERADMIN") {
                return (
                    <Link to={url}>
                        <i className="las la-edit"></i>
                    </Link>
                );
            } else {
                return null;
            }

        default:
            break;
    }

    return (
        <>
            {RoleBasedButton()}
        </>
    );
};

export default RoleBasedButton;
