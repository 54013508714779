import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_ROLES_VIEW, API_WEB_DOMAIN } from "../../config/Api";
import { toast } from "react-toastify";


function ViewRole(props) {
  const { roleRef } = props;

  const [apiData, setApiData] = useState(null);
  const [showUserData, setShowUserData] = useState([]);

  useEffect(() => {
    fetchRoleData();
  }, []);

  const fetchRoleData = () => {
    props
      .callRequest("GET", API_ROLES_VIEW + "/" + roleRef, true, null)
      .then((res) => {
        setApiData(res.data.role);
        setShowUserData(res.data.role.profile);
      })
      .catch((e) => {
        console.log(e);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View Role</h5>
        </div>
        <div className="card-body">
          <div className="profile-basic">
            <div className="row">
              <div className="col-md-6">
                <div className="profile-info-left pb-3">
                  <div className="d-flex">
                    <i className="las la-user me-2"></i>
                    <h4 className="user-name fw-bold">{apiData?.r_name}</h4>
                  </div>
                  <div className="text-muted fw-bold">
                    <i
                      className="las la-building me-2"
                      style={{ fontSize: "1.1rem" }}
                    ></i>
                    <span>{apiData?.department.d_name}</span>
                  </div>
                  <div className="col-xl-12 col-sm-12 mt-4">
                    <h5 className="fw-bold text-muted">Users</h5>
                  </div>
                  {showUserData ? (
                    <>
                      {showUserData.map((user, i) => {
                        return (
                          <div className="row pb-3 mt-3" key={i}>
                            <div className="col-xl-8 col-lg-8">
                              <div className="d-flex align-items-center justify-content-start">
                                <img
                                  src={API_WEB_DOMAIN + user.u_profile_photo}
                                  alt=""
                                  className="admission-profile-thumb me-3"
                                  style={{
                                    width: "75px",
                                    height: "75px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <div className="ms-3">
                                  <Link to={`/users/view/${user.u_ref}`}>
                                    {user.u_fullname}
                                  </Link>
                                  <br />
                                  <span className="text-muted">
                                    Employee ID:{" "}
                                    {user.u_emp_code ? user.u_emp_code : "N/A"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 mt-3">
                              <div className="d-flex align-items-center">
                                <i className="las la-phone me-2"></i>
                                <span>{user.u_phone}</span>
                                {user.u_phone2 ? (
                                  <span>&nbsp;/&nbsp;{user.u_phone2}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <i className="las la-envelope me-2"></i>
                                <span>{user.u_email}</span>
                                {user.u_email_personal ? (
                                  <span>
                                    &nbsp;/&nbsp;{user.u_email_personal}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    "No user found"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRole;
