import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';

const OldPreviewAdmission = (props) => {
   const { formValues, resultImg, resultSign,
      selectedEducationCertificate, selectedIDCard,
      selectedCasteCertificate, setShowPreview, handleStudentSave, courseOptions, showCasteUpload } = props;

   const getCourseName = (course_id) => {
      let found = courseOptions.find(item => item.course_id === Number(course_id))
      let course_data = null
      if (found) {
         course_data = found
      }
      return course_data
   }

   const getPayOption = (pay_id) => {
      let found = props.getPayment().find((item) => item.value === pay_id)
      let option_name = ""
      if (found) {
         option_name = found.label
      }
      return option_name
   }

   const saveStudentAdmission = () => {
      setShowPreview(false)
      handleStudentSave();
   }

   const cancelPreviewAdmission = () => {
      setShowPreview(false)
   }

   return (
      <>
         <Form>
            <Row className="mb-3">
               <Col lg={4}>
                  <Form.Group controlId="finacial_year">
                     <Form.Label>Financial Year</Form.Label>
                     <Form.Control
                        type="text"
                        name="finacial_year"
                        value={formValues.finacial_year}
                        disabled
                     />
                  </Form.Group>
               </Col>
               <Col lg={4}>
                  <Form.Group controlId="">
                     <Form.Label>Admission Date</Form.Label>
                     <Form.Control
                        type="date"
                        name="admission_date"
                        value={formValues.admission_date}
                        disabled
                     />
                  </Form.Group>
               </Col>
            </Row>
            <>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="fullname">
                        <Form.Label>Student Name</Form.Label>
                        <Form.Control
                           type="text"
                           name="fullname"
                           value={formValues.fullname}
                           disabled
                        />
                     </Form.Group>
                  </Col>

                  <Col lg={4}>
                     <Form.Group controlId="guardian_name">
                        <Form.Label> Father's/Husband's Name</Form.Label>
                        <Form.Control
                           type="text"
                           name="guardian_name"
                           value={formValues.guardian_name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                           type="date"
                           name="dob"
                           value={formValues.dob}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="phone"
                           value={formValues.phone}
                           disabled
                        />
                     </Form.Group>
                  </Col>

                  <Col lg={4}>
                     <Form.Group controlId="alternate_phone">
                        <Form.Label>Alternate Phone Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="alternate_phone"
                           value={formValues.alternate_phone}
                           disabled
                        />
                     </Form.Group>
                  </Col>

                  <Col lg={4}>
                     <Form.Group controlId="email">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                           type="text"
                           name="email"
                           value={formValues.email}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="gender">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                           type="text"
                           name="gender"
                           value={formValues.gender}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="education_qualifications">
                        <Form.Label> Last Educational Qualification</Form.Label>
                        <Form.Control
                           type="text"
                           name="education_qualifications"
                           value={formValues.education_qualifications}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="stud_edu_cert">
                        <Form.Label> Upload Last Qualification Certificate</Form.Label>
                        <Form.Control
                           type="text"
                           name="stud_edu_cert"
                           value={selectedEducationCertificate.name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="id_type">
                        <Form.Label>Government ID</Form.Label>
                        <Form.Control
                           name="id_type"
                           value={formValues.id_type}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="id_num">
                        <Form.Label>Government ID Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="id_num"
                           placeholder="Govt. ID Number"
                           value={formValues.id_num}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="stud_govt_id_path">
                        <Form.Label>Upload Government ID</Form.Label>
                        <br />
                        <Form.Control
                           type="text"
                           name="stud_govt_id_path"
                           value={selectedIDCard.name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="stud_caste">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                           name='stud_caste'
                           value={formValues.stud_caste}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  {showCasteUpload && (
                     <Col lg={4}>
                        <Form.Group controlId="stud_caste_doc">
                           <Form.Label>Upload Caste Certificate</Form.Label>
                           <Form.Control
                              type="text"
                              name="stud_caste_doc"
                              value={selectedCasteCertificate.name}
                              disabled
                           />
                        </Form.Group>
                     </Col>
                  )}
               </Row>
               <Row className="mb-3">
                  <Col lg={6}>
                     <Form.Group controlId="stud_present_address">
                        <Form.Label>Current Address</Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="stud_present_address"
                           value={formValues.stud_present_address}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={6}>
                     <Form.Group controlId="stud_permanent_address">
                        <div className="d-flex justify-content-between">
                           <Form.Label>Permanent Address</Form.Label>
                        </div>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="stud_permanent_address"
                           value={formValues.stud_permanent_address}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row className="mb-3">
                  <Col lg={6}>
                     <Form.Group controlId="stud_pic">
                        <Form.Label>Upload Photo</Form.Label>
                        <div className="preview-container">
                           <div className="img-preview">
                              <img src={resultImg} />
                           </div>
                        </div>
                     </Form.Group>
                  </Col>

                  <Col lg={6}>
                     <Form.Group controlId="stud_sign">
                        <Form.Label>Upload Signature</Form.Label>
                        <div className="preview-container">
                           <div className="sign-preview">
                              <img src={resultSign} />
                           </div>
                        </div>
                     </Form.Group>
                  </Col>
               </Row>

               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="course_id">
                        <Form.Label>Course</Form.Label>
                        <Form.Control
                           type="text"
                           name="course_id"
                           value={getCourseName(formValues.course_id).course_name}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="course_fee">
                        <Form.Label>Course Fees</Form.Label>
                        <Form.Control
                           type="text"
                           name="course_fee"
                           value={getCourseName(formValues.course_id).course_fees}
                           disabled
                        />
                     </Form.Group>
                  </Col>

                  <Col lg={4}>
                     <Form.Group controlId="stud_reg_no2">
                        <Form.Label>Existing Registration Number</Form.Label>
                        <Form.Control
                           type="text"
                           name="stud_reg_no2"
                           placeholder="Existing Registration Number"
                           value={formValues.stud_reg_no2}
                           disabled
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row className="mb-3">
                  <Col lg={4}>
                     <Form.Group controlId="payment_option">
                        <Form.Label>Payment Option</Form.Label>
                        <Form.Control
                           name="payment_option"
                           value={getPayOption(formValues.payment_option)}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group controlId="">
                        <Form.Label>Total Discount Amount (Rs.)</Form.Label>
                        <Form.Control
                           type="text"
                           name="total_discount"
                           value={props.getCurrencyFormat(formValues.total_discount)}
                           disabled
                        />
                     </Form.Group>
                  </Col>
                  <Col lg={4}>
                     <Form.Group>
                        <Form.Control
                           id="center_id"
                           type="hidden"
                           name="center_id"
                           value={formValues.center_id}
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <div className="row pb-3">
                  <div className="col-xl-12 col-sm-12 mt-4">
                     <h5 className="fw-bold text-muted">Money Receipt Details</h5>
                  </div>
               </div>

               <div className="mb-3">
                  {formValues.money_received.map((data, index) => (
                     <div key={data.id}>
                        <Row className="mb-3">
                           <Col lg={3}>
                              <Form.Group>
                                 <Form.Label>Payment Mode</Form.Label>
                                 <Form.Control
                                    name="pay_by"
                                    value={data.pay_by}
                                    disabled
                                 />
                              </Form.Group>
                           </Col>
                           <Col lg={3}>
                              <Form.Group>
                                 <Form.Label>Payment Date</Form.Label>
                                 <Form.Control
                                    type="date"
                                    name="payment_date"
                                    value={data.payment_date}
                                    disabled
                                 />
                              </Form.Group>
                           </Col>
                           <Col lg={3}>
                              <Form.Group>
                                 <Form.Label>Existing MR Number</Form.Label>
                                 <Form.Control
                                    type="text"
                                    name="recpt_num2"
                                    value={data.recpt_num2}
                                    disabled
                                 />
                              </Form.Group>
                           </Col>
                           <Col lg={2}>
                              <Form.Group>
                                 <Form.Label>Amount Paid</Form.Label>
                                 <Form.Control
                                    type="text"
                                    name="payment_amount"
                                    value={props.getCurrencyFormat(data.payment_amount)}
                                    disabled
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(data.pay_by) && (
                           <Row className="mb-3">
                              <Col lg={3}>
                                 <Form.Group>
                                    <Form.Label>Cheque/DD/Ref. No</Form.Label>
                                    <Form.Control
                                       type="text"
                                       name="chq_num"
                                       value={data.chq_num}
                                       disabled
                                    />
                                 </Form.Group>
                              </Col>
                              <Col lg={3}>
                                 <Form.Group>
                                    <Form.Label>Cheque/DD Date</Form.Label>
                                    <Form.Control
                                       type="date"
                                       name="chq_date"
                                       value={data.chq_date}
                                       disabled
                                    />
                                 </Form.Group>
                              </Col>
                              <Col lg={3}>
                                 <Form.Group>
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control
                                       type="text"
                                       name="chq_bank"
                                       value={data.chq_bank}
                                       disabled
                                    />
                                 </Form.Group>
                              </Col>
                           </Row>
                        )}
                     </div>
                  ))}
               </div>

               <div className='d-flex flex-row-reverse'>
                  <Button className='btn btn-primary ms-2' type='button'
                     onClick={() => saveStudentAdmission()}
                  >
                     Submit
                  </Button>
                  <Button className='btn btn-danger'
                     onClick={() => cancelPreviewAdmission()}
                  >
                     Cancel
                  </Button>
               </div>
            </>
         </Form>
      </>
   )
}

export default OldPreviewAdmission
