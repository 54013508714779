import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_CENTERS_CREATE, API_LISTS_DISTRICT } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

function NewStudyCenterForm(props) {
  const initialValues = {
    center_name: "",
    center_code: "",
    center_dist: "",
    center_phone: "",
    center_alternate_phone: "",
    center_email: "",
    center_alternate_email: "",
    center_holder: "",
    center_address: "",
    center_location: "",
  };

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(initialValues);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [btnEnable, setBtnEnable] = useState(false);

  useEffect(() => {
    fetchDistricts();
  }, []);

  const fetchDistricts = () => {
    props
      .callRequest("GET", API_LISTS_DISTRICT, true, null)
      .then((res) => {
        setDistrictOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateForm = () => {
    const {
      center_name,
      center_code,
      center_dist,
      center_phone,
      center_email,
      center_holder,
      center_address,
      center_location,
    } = formValues;
    let isValid = true;
    const errors = {};

    if (center_name === "") {
      isValid = false;
      errors.center_name = "Franchisee name is required";
    } else if (center_name !== "" && !props.checkAlphaOnly(center_name)) {
      isValid = false;
      errors.center_name_not_valid = "Valid entry only alphabets, space and dot";
    }
    if (center_code === "") {
      isValid = false;
      errors.center_code = "Franchisee code is required";
    }
    if (center_dist === "") {
      isValid = false;
      errors.center_dist = "District name is required";
    }
    if (center_phone === "") {
      isValid = false;
      errors.center_phone = "Phone number is required";
    }
    if (center_email === "") {
      isValid = false;
      errors.center_email = "Email address is required";
    }
    if (center_holder === "") {
      isValid = false;
      errors.center_holder = "Holder's name is required";
    } else if (center_holder !== "" && !props.checkAlphaOnly(center_holder)) {
      isValid = false;
      errors.center_holder_not_valid = "Valid entry only alphabets, space and dot";
    }
    if (center_address === "") {
      isValid = false;
      errors.center_address = "Address is required";
    }
    if (center_location === "") {
      isValid = false;
      errors.center_location = "Location is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    setBtnEnable(true);
    props
      .callRequest("POST", API_CENTERS_CREATE, true, formValues)
      .then((res) => {
        toast.success("Center create successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setTimeout(() => {
          if (res.status === 200) {
            navigate("/study-centers");
          }
        }, 2000);
      })
      .catch((e) => {
        setBtnEnable(false);
        console.log(e);
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_name">
              <Form.Label>Franchisee Name</Form.Label>
              <Form.Control
                type="text"
                name="center_name"
                value={formValues.center_name || ""}
                placeholder="Enter Center Name"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.center_name === ""
                  ? formErrors.center_name
                  : formValues.center_name !== "" &&
                    !props.checkAlphaOnly(formValues.center_name)
                    ? formErrors.center_name_not_valid
                    : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_code">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                name="center_code"
                value={formValues.center_code || ""}
                placeholder="Enter Center Code"
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
                maxLength={3}
                autoComplete="off"
              />
              <small className="error">
                {formValues.center_code === "" && formErrors.center_code}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_dist">
              <Form.Label>District Name</Form.Label>
              <Form.Select
                name="center_dist"
                onChange={handleChange}
                value={formValues.center_dist || ""}
              >
                <option value="">Select District Name</option>
                {Object.keys(districtOptions).map((key) => (
                  <option key={key} value={key}>
                    {districtOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.center_dist === "" && formErrors.center_dist}
              </small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_phone">
              <Form.Label>Phone No.</Form.Label>
              <Form.Control
                type="text"
                name="center_phone"
                value={formValues.center_phone || ""}
                placeholder="e.g. 9876543210"
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
              />
              <small className="error">
                {formValues.center_phone === "" && formErrors.center_phone}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_alternate_phone">
              <Form.Label>Alternate Phone No.</Form.Label>
              <Form.Control
                type="text"
                name="center_alternate_phone"
                value={formValues.center_alternate_phone || ""}
                placeholder="e.g. 9876543210"
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
              />
            </Form.Group>
          </Col>

        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="center_email"
                value={formValues.center_email || ""}
                placeholder="e.g demo@example.com"
                onChange={handleChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                autoComplete="off"
              />
              <small className="error">
                {formValues.center_email === "" && formErrors.center_email}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_alternate_email">
              <Form.Label> Alternate Email</Form.Label>
              <Form.Control
                type="email"
                name="center_alternate_email"
                value={formValues.center_alternate_email || ""}
                placeholder="e.g demo@example.com"
                onChange={handleChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="center_holder">
              <Form.Label>Holder's Name</Form.Label>
              <Form.Control
                type="text"
                name="center_holder"
                value={formValues.center_holder || ""}
                placeholder="Enter Holder's Name"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.center_holder === ""
                  ? formErrors.center_holder
                  : formValues.center_holder !== "" &&
                    !props.checkAlphaOnly(formValues.center_holder)
                    ? formErrors.center_holder_not_valid
                    : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="center_location"
                placeholder="Enter location"
                value={formValues.center_location || ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.center_location === "" && formErrors.center_location}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="center_address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="center_address"
                placeholder="Enter address"
                value={formValues.center_address || ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.center_address === "" && formErrors.center_address}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-2 text-end">
          <Button disabled={btnEnable ? true : false} type="submit" className="btn btn-primary mt-2">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}

export default NewStudyCenterForm;
