import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { API_ADMISSIONS_VIEW, API_COURSES, API_COURSES_SHIFT, API_WEB_DOMAIN }
   from '../../config/Api';

const ShiftCourse = (props) => {
   const { studentRef } = props;
   const navigate = useNavigate();
   let center_ref = "";
   let center = "";

   const [formValues, setFormValues] = useState({
      stud_ref: studentRef,
      course_id_from: "",
      course_id_to: "",
   });

   const [centerInfo, setCenterInfo] = useState({});
   const [courseInfo, setCourseInfo] = useState({});
   const [studentInfo, setStudentInfo] = useState({});
   const [courseOptions, setCourseOptions] = useState([]);
   const [btnEnable, setBtnEnable] = useState(false);
   const [formErrors, setFormErrors] = useState({});

   if (
      localStorage.getItem("center") !== null
   ) {
      center = JSON.parse(localStorage.getItem("center"));
      center_ref = center.ref;
   }

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = () => {
      props
         .callRequest("GET", API_ADMISSIONS_VIEW + "/" + studentRef, true, null)
         .then((res) => {
            setFormValues({ ...formValues, course_id_from: res.data.admission_info.course_info.course_id });
            setCenterInfo(res.data.admission_info.center_info);
            setCourseInfo(res.data.admission_info.course_info);
            setStudentInfo(res.data.admission_info.student_info);
            fetchCourseOptions(res.data.admission_info.course_info);
         })
         .catch((e) => {
            console.log(e);
         });
   };

   const fetchCourseOptions = (courseInfo) => {
      const url = API_COURSES + '/' + formValues.admission_date + center_ref;
      props
         .callRequest("GET", url, true, null)
         .then((res) => {
            const temp_state = res.data.courses.filter((item) => Number(item.course_id) !== Number(courseInfo.course_id))
            setCourseOptions(temp_state);
         })
         .catch((e) => {
            console.log(e);
         });
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
   };

   const validateShiftCourse = () => {
      const { course_id_to, } = formValues;
      const errors = {};
      let isValid = true;

      if (course_id_to === "") {
         isValid = false;
         errors.course_id_to = "Course shift is required";
      }

      setFormErrors(errors);
      return isValid;
   };

   const handleShiftCourseSubmit = (e) => {
      e.preventDefault();

      if (!validateShiftCourse()) {
         return false;
      }
      setBtnEnable(true);
      props
         .callRequest("POST", API_COURSES_SHIFT, true, formValues)
         .then((res) => {
            toast.success(`${res.data.message}`, {
               position: toast.POSITION.TOP_CENTER,
               autoClose: 5000,
            });
            setTimeout(() => {
            }, 5000);
         })
         .catch((e) => {
            console.log(e);
            toast.error(`${e.response.data.message}`, {
               position: toast.POSITION.TOP_CENTER,
               autoClose: 5000,
            });
         });
   };

   return (
      <>
         <div className="card">
            <ToastContainer />
            <div className="card-header">
               <h5>Course Shift</h5>
               <div>
                  <Button
                     className="link-action"
                     onClick={() => navigate("/admissions/view/" + studentRef)}
                  >
                     View Admission
                  </Button>
               </div>
            </div>
            <div className="card-body">
               <Form>
                  <div className="project">
                     <div className="row border-bottom pb-3">
                        <div className="col-xl-8 col-lg-8">
                           <div className="d-flex align-items-center justify-content-start">
                              <img
                                 src={API_WEB_DOMAIN + studentInfo.stud_pic}
                                 className="admission-profile-thumb me-3"
                                 alt=""
                              />
                              <div className="ms-3">
                                 <h4 className="fw-bold m-0 me-2">
                                    {studentInfo.stud_fullname}
                                 </h4>
                                 <h5>{studentInfo.stud_reg_no}</h5>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                           <div className="d-flex align-items-center">
                              <i className="las la-phone me-2"></i>
                              <span>{studentInfo.stud_phone}</span>
                              <span>
                                 &nbsp;/&nbsp;{studentInfo.stud_phone2}
                              </span>
                           </div>
                           <div className="d-flex align-items-center">
                              <i className="las la-envelope me-2"></i>
                              <span>{studentInfo.stud_email}</span>
                           </div>
                        </div>
                     </div>
                     <div className="row border-bottom pb-3">
                        <div className="col-xl-8 col-sm-8 mt-4">
                           <h5 className="fw-bold">
                              {centerInfo.ct_name} [
                              {centerInfo.ct_code}]
                           </h5>
                        </div>
                     </div>
                     <div className="row border-bottom pb-3">
                        <div className="col-xl-4 col-sm-4 mt-4">
                           <Form.Label>Course From</Form.Label>
                           <h5 className="fw-bold">
                              <Link
                                 to={`/courses/view/${courseInfo.course_ref}`}
                              >
                                 {courseInfo.course_name}
                              </Link>
                           </h5>
                        </div>
                        <div className="col-xl-4 col-sm-4 mt-4">
                           <Form.Group controlId="course_id_to">
                              <Form.Label>Course To</Form.Label>
                              <Form.Select name="course_id_to" onChange={handleChange}>
                                 <option value="">Select Course</option>
                                 {courseOptions.map((value) => (
                                    <option key={value.course_ref} value={value.course_id}>
                                       {value.course_name}
                                    </option>
                                 ))}
                              </Form.Select>
                              <small className="error">
                                 {formValues.course_id_to === "" && formErrors.course_id_to}
                              </small>
                           </Form.Group>
                        </div>
                     </div>
                  </div>
               </Form>
            </div>
            <div className='d-flex flex-row-reverse m-3'>
               <Button
                  className='btn btn-primary ms-2' type='button'
                  disabled={btnEnable ? true : false}
                  onClick={(e) => {
                     handleShiftCourseSubmit(e);
                  }}
               >
                  Save
               </Button>
            </div>
         </div>
      </>
   )
}

export default ShiftCourse