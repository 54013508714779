import React from 'react'
import { Button, Table } from 'react-bootstrap'
import PreviewPaymentDetails from './PreviewPaymentDetails'

const NewPreviewPayment = (props) => {
   const { formValues, setShowPreview, handleStudentSave,
      paymentCount, selectedOption, feesBreakup, paymentAmount, showPaymentInfo } = props

   const saveStudentAdmission = () => {
      setShowPreview(false)
      handleStudentSave();
   }
   const cancelPreviewAdmission = () => {
      setShowPreview(false)
   }
   const displayDiscountBreakUp =
      formValues.discount_type.length > 0 ? (
         formValues.discount_type.map((discount, i) => {
            return (
               <tr key={i}>
                  <td className="ps-5">
                     {discount.label}
                  </td>
                  <td className="discount text-end">
                     {props.getCurrencyFormat(discount.amount)}
                  </td>
               </tr>
            );
         })
      ) : (
         <tr>
            <td colSpan="2">No discount found</td>
         </tr>
      );

   const displayFeesBreakUp = () => {
      return (
         feesBreakup.map((installment, i) => {
            return i === paymentCount ? (
               <div key={i} className="my-2 me-5 px-2 left-border">
                  <div className="d-flex align-items-center">
                     <input
                        type="radio"
                        name="pay_amount"
                        value={installment.ins_amount}
                        checked={installment.ins_amount}
                        disabled
                     />
                     <div className="ms-3">
                        <h6>Installment - {i + 1}</h6>
                        <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
                     </div>
                  </div>
               </div>
            ) : (
               <div key={i} className="my-2 me-5 px-2 left-border">
                  <h6>Installment - {i + 1}</h6>
                  <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
               </div>
            );
         }));
   };


   const displayCustomFeesBreakUp = () => {
      return (
         feesBreakup.map((installment, i) => {
            return i === paymentCount ? (
               <div key={i} className="my-2 me-5 px-2 left-border">
                  <div className="d-flex align-items-center">
                     <div className="ms-3">
                        <h6>Installment - {i + 1}</h6>
                        <input
                           type="text"
                           className="form-control"
                           name="custom_pay_amount"
                           defaultValue={formValues.amount_paid !== "" ? formValues.amount_paid : installment.ins_amount}
                           disabled
                        />
                     </div>
                  </div>
               </div>
            ) : (
               <div key={i} className="my-2 me-5 px-2 left-border">
                  <h6>Installment - {i + 1}</h6>
                  <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
               </div>
            );
         })
      );
   };

   const feesDetails = (formValues) => {
      const {
         original_course_fee,
         max_special_discount,
         special_reason,
         special_discount,
         total_discount,
      } = formValues;

      return (
         <Table className="table table-striped table-hover">
            <tbody>
               <tr className="fw-bold">
                  <td><h5 className="fw-bold  m-0 p-0">Original Course Fees</h5></td>
                  <td className="text-end">
                     <h5 className="fw-bold  m-0 p-0">
                        {props.getCurrencyFormat(original_course_fee)}
                     </h5>
                  </td>
               </tr>

               {displayDiscountBreakUp}

               {
                  max_special_discount > 0 && (
                     <>
                        <tr className="fw-bold">
                           <td><h5 className="fw-bold  m-0 p-0">Special Discount</h5></td>
                           <td className="text-end">&nbsp;</td>
                        </tr>
                        <tr>
                           <td className="discount">
                              <input
                                 type="text"
                                 className="form-control"
                                 name="special_reason"
                                 value={special_reason}
                                 placeholder="Reason of special discount"
                                 disabled
                              />
                           </td>
                           <td className="discount text-end">
                              <input
                                 type="number"
                                 className="form-control"
                                 name="special_discount"
                                 value={special_discount}
                                 placeholder={"=< " + props.getCurrencyFormat(max_special_discount)}
                                 disabled
                              />
                           </td>
                        </tr>
                     </>
                  )
               }
               <tr>
                  <td>
                     <h5 className="fw-bold m-0 p-0">Total discount</h5>
                  </td>
                  <td className="text-end">
                     <h5 className="fw-bold  m-0 p-0">
                        {props.getCurrencyFormat(Number(special_discount) + Number(total_discount))}
                     </h5>
                  </td>
               </tr>

               <tr>
                  <td>
                     <h5 className="fw-bold m-0 p-0">Payable Amount</h5>
                  </td>
                  <td className="text-end">
                     <h5 className="fw-bold  m-0 p-0">
                        {props.getCurrencyFormat(
                           original_course_fee - total_discount - special_discount
                        )}
                     </h5>
                  </td>
               </tr>
               <tr>
                  <td>
                     <h5 className="fw-bold m-0 p-0">Amount Paid</h5>
                  </td>
                  <td className="text-end">
                     <h5 className="fw-bold m-0 p-0">
                        {props.getCurrencyFormat(paymentAmount)}
                     </h5>
                  </td>
               </tr>
               <tr>
                  <td>
                     <h5 className="fw-bold m-0 p-0">Amount Due</h5>
                  </td>
                  <td className="text-end">
                     <h5 className="fw-bold m-0 p-0">
                        {props.getCurrencyFormat(
                           original_course_fee -
                           total_discount -
                           special_discount -
                           paymentAmount
                        )}
                     </h5>
                  </td>
               </tr>
            </tbody>
         </Table>
      );
   };


   return (
      <div className="payment-section">
         <div className="card-body">
            <div className="project">
               <div className="row pb-3">
                  <div className="col-xl-12 col-sm-12 mt-4">
                     <h5 className="fw-bold text-muted">Payment Option</h5>
                  </div>
                  <div className="col-xl-4 col-sm-6 mt-2">
                     <input
                        type="radio"
                        name="payment_type"
                        value="1"
                        checked={selectedOption === "1"}
                        disabled
                     />
                     <span className="ms-2">Full Payment</span>
                  </div>
                  <div className="col-xl-4 col-sm-6 mt-2">
                     <input
                        type="radio"
                        name="payment_type"
                        value="2"
                        checked={selectedOption === "2"}
                        disabled
                     />
                     <span className="ms-2">Part Payment</span>
                  </div>
                  <div className="col-xl-4 col-sm-6 mt-2">
                     <input
                        type="radio"
                        name="payment_type"
                        value="3"
                        checked={selectedOption === "3"}
                        disabled
                     />
                     <span className="ms-2">Custom Payment</span>
                  </div>
               </div>
               <div className="row border-bottom pb-3 px-5">
                  {selectedOption === "1" && (
                     feesDetails(formValues)
                  )}

                  {selectedOption === "2" && (
                     <>
                        {feesDetails(formValues)}
                        <tr>
                           <td colSpan="2">
                              <div className="d-flex justify-content-start flex-wrap">
                                 {displayFeesBreakUp()}
                              </div>
                           </td>
                        </tr>
                     </>
                  )}

                  {selectedOption === "3" && (
                     <>
                        {feesDetails(formValues)}
                        < tr >
                           <td colSpan="2">
                              <div className="d-flex justify-content-start flex-wrap">
                                 {displayCustomFeesBreakUp()}
                              </div>
                           </td>
                        </tr>
                     </>
                  )}
               </div>
            </div>


            <PreviewPaymentDetails
               formValues={formValues}
               showPaymentInfo={showPaymentInfo}
               {...props}
            />

         </div>

         <div className='d-flex flex-row-reverse'>
            <Button className='btn btn-primary ms-2' type='button'
               onClick={() => saveStudentAdmission()}
            >
               Submit
            </Button>
            <Button className='btn btn-danger'
               onClick={() => cancelPreviewAdmission()}
            >
               Cancel
            </Button>
         </div>
      </div>
   )
}

export default NewPreviewPayment
