import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { API_CHARTS_ADMISSION } from "../../config/Api";

function ChartAdmission(props) {
    const [chartData, setChartData] = useState([]);

    let center_ref = "";

    if (
        localStorage.getItem("user") !== null &&
        localStorage.getItem("center") !== null
    ) {
        const user = JSON.parse(localStorage.getItem("user"));
        const center = JSON.parse(localStorage.getItem("center"));

        if (user.role.role_name == "CENTER ADMIN") {
            center_ref = "/" + center.ref;
        }
    }

    useEffect(() => {
        fetchAdmissions();
        const interval = setInterval(() => {
            fetchAdmissions();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const fetchAdmissions = () => {
        props.callRequest("GET", API_CHARTS_ADMISSION + center_ref, true, null).then((res) => {
            setChartData(res.data.chart_data);
        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="row d-flex align-items-center">
                        <div className="col-12">
                            <h4 className="text-muted fw-bold m-0">Admissions - <small>Last 6 months</small></h4>
                            {
                                chartData ?
                                    (<div style={{ width: '100%', height: '240px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart data={chartData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="admissions" fill="#239b56" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>)

                                    : ''
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default ChartAdmission;
