import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup, Button } from "react-bootstrap";
import { API_AUTHENTICATE, API_FORGOT_PASSWORD, RECAPTCHA_SITE_KEY } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

function LoginForm(props) {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    user_name: "",
    user_password: "",
    g_recaptcha_response: null
  });
  const [forgotPasswordFormValues, setForgotPasswordFormValues] = useState({
    forgot_password: "",
    center_code: "",
    errors: {},
  });

  const [formErrors, setFormErrors] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [btnEnable, setBtnEnable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleForgotPasswordChange = (e) => {
    const { name, value } = e.target;
    setForgotPasswordFormValues({ ...forgotPasswordFormValues, [name]: value });
  };

  const handleCaptcha = (e) => {
    setFormValues({ ...formValues, g_recaptcha_response: e });
  };

  const validateLogin = () => {
    const { user_name, user_password, g_recaptcha_response } = formValues;
    const errors = {};
    let isValid = true;

    if (user_name === "") {
      isValid = false;
      errors.user_name = "Username is required";
    }

    if (user_password === "") {
      isValid = false;
      errors.user_password = "Password is required";
    }



    setFormErrors(errors);
    return isValid;
  };

  const validateForgotPassword = () => {
    const { forgot_password } = forgotPasswordFormValues;
    const errors = {};
    let isValid = true;

    if (forgot_password === "") {
      isValid = false;
      errors.forgot_password = "User name is required";
    }

    setForgotPasswordFormValues({ ...forgotPasswordFormValues, errors });
    return isValid;
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    if (!validateForgotPassword()) {
      return false;
    }
    setBtnEnable(true);
    props
      .callRequest("POST", API_FORGOT_PASSWORD, true, forgotPasswordFormValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          setForgotPassword(false);
          setBtnEnable(false);
        }, 5000);
      })
      .catch((e) => {
        console.log(e);
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  const handleLogInSubmit = (e) => {
    e.preventDefault();
    if (!validateLogin()) {
      return false;
    }
    setBtnEnable(true);

    props
      .callRequest("POST", API_AUTHENTICATE, false, formValues)
      .then((res) => {
        const center_str = JSON.parse(res.data.center);
        let center_obj = {
          ...center_str,
          balance_admission_count: res.data.balance_admission_count,
        };
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("token_expired_on", res.data.token_expired_on);
        localStorage.setItem("user", res.data.user);
        localStorage.setItem("center", JSON.stringify(center_obj));

        const user = JSON.parse(res.data.user);
        const center = JSON.parse(res.data.center);

        if (center.is_ongoing_completed === 0 && center.ongoing_count === 0) {
          localStorage.setItem("ongoing_count", 1);
          localStorage.setItem("is_ongoing_completed", 1);
          localStorage.setItem("admission_route_flag", 0);
        } else if (
          center.is_ongoing_completed === 0 &&
          center.ongoing_count > 0
        ) {
          localStorage.setItem("ongoing_count", 0);
          localStorage.setItem("is_ongoing_completed", 1);
          localStorage.setItem("admission_route_flag", 0);
        } else if (center.is_ongoing_completed !== 0) {
          localStorage.setItem("ongoing_count", 0);
          localStorage.setItem("is_ongoing_completed", 0);
          localStorage.setItem("admission_route_flag", 1);
        }

        if (user.is_default_pwd === 1) {
          navigate("/change-password");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        setBtnEnable(false);
        toast.error(`${err.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };

  return (
    <>
      <Form onSubmit={handleLogInSubmit}>
        <ToastContainer />
        {!forgotPassword ? (
          <div>
            <InputGroup>
              <InputGroup.Text>
                <i className="las la-envelope"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="user_name"
                placeholder="User Name"
                autoComplete="off"
                value={formValues.user_name}
                onChange={handleChange}
              />
            </InputGroup>
            <small className="error">
              {formValues.user_name === "" && formErrors.user_name}
            </small>
            <InputGroup className="my-2">
              <InputGroup.Text>
                <i className="las la-lock"></i>
              </InputGroup.Text>
              <Form.Control
                type="password"
                name="user_password"
                placeholder="Password"
                autoComplete="off"
                value={formValues.user_password}
                onChange={handleChange}
              />
            </InputGroup>
            <small className="error">
              {formValues.user_password === "" && formErrors.user_password}
            </small>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleCaptcha}
              size="invisible"
            />

          </div>
        ) : (
          <div>
            <InputGroup>
              <InputGroup.Text>
                <i className="las la-envelope"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="forgot_password"
                placeholder="Enter your user name"
                autoComplete="off"
                value={forgotPasswordFormValues.forgot_password}
                onChange={handleForgotPasswordChange}
              />
            </InputGroup>
            <small className="error">
              {forgotPasswordFormValues.forgot_password === ""
                && forgotPasswordFormValues.errors.forgot_password}
            </small>

          </div>
        )}
        {forgotPassword ? null : (
          <div className="mt-2 text-start">
            <span
              style={{ cursor: "pointer" }}
              className="text-info"
              onClick={() => setForgotPassword(true)}
            >
              Forgot password?
            </span>
          </div>
        )}

        {!forgotPassword ? (
          <div className="mt-2 text-end">
            <Button
              disabled={btnEnable ? true : false}
              type="submit"
              className="btn btn-primary mt-2"
            >
              Submit
            </Button>
          </div>
        ) : (
          <div className="">
            <div className="mt-2 text-start">
              <span
                style={{ cursor: "pointer" }}
                className="text-info"
                onClick={() => setForgotPassword(false)}
              >
                Back to logIn
              </span>
            </div>

            <Button
              type="submit"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              disabled={btnEnable ? true : false}
              onClick={(e) => handleForgotPasswordSubmit(e)}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}

export default LoginForm;
