import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { API_REPORTS_ROYALTY } from "../../config/Api";

function Royalty(props) {
    const { duration } = props;
    const [apiData, setApiData] = useState(null);
    const [royaltyAmount, setRoyaltyAmount] = useState(0);

    let center_ref = "";

    if (
        localStorage.getItem("user") !== null &&
        localStorage.getItem("center") !== null
    ) {
        const user = JSON.parse(localStorage.getItem("user"));
        const center = JSON.parse(localStorage.getItem("center"));

        if (user.role.role_name == "CENTER ADMIN") {
            center_ref = "/" + center.ref;
        }
    }

    useEffect(() => {
        fetchRoyalty();
        const interval = setInterval(() => {
            fetchRoyalty();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const fetchRoyalty = async () => {
        props.callRequest("GET", API_REPORTS_ROYALTY + '/' + duration + center_ref, true, null).then((res) => {
            setApiData(res.data);
            setRoyaltyAmount(props.displayDashboardCurrency(res.data.royalty));
        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="row d-flex align-items-center">
                        <div className="col-4">
                            <i className="las la-coins card-icon-lg"></i>
                        </div>
                        <div className="col-8">
                            <h4 className="text-muted fw-bold m-0">Royalty - <small>{duration}</small></h4>
                            <h2 className="fw-bolder m-0 ">{royaltyAmount ? royaltyAmount : 0}</h2>
                            {
                                apiData ?
                                    (<div ><small>{props.convertDate(apiData.start_date)} <span className="text-muted">to</span> {props.convertDate(apiData.end_date)}</small></div>)
                                    : ''
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default Royalty;
