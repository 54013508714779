import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import { API_COURSES_NEW_FEES, API_COURSE_VIEW } from '../../config/Api';

const NewCourseFees = (props) => {
   let new_id = "";
   const navigate = useNavigate();
   const { courseRef, convertDateStringToYMD } = props;

   const getCourseOption = [
      { label: "Select Course", value: "" },
      { label: "Cerificate Course", value: "1" },
      { label: "Diploma Course", value: "2" },
   ];

   const initialValues = {
      course_name: "",
      course_code: "",
      course_eligibility_criteria: "",
      course_content: "",
      course_duration_hours: "",
      course_duration_months: "",
      course_fees: "",
      course_royalty: "",
      course_impact: "0",
      course_type: "",
      fees_effective_from: "",
      fees_effective_to: "",
      admission_amount: [
         {
            instalment_num: 0,
            status: false,
            instalment_amt: "",
         },
      ],
   };

   const [formValues, setFormValues] = useState(initialValues);
   const [formErrors, setFormErrors] = useState({});
   const [btnEnable, setBtnEnable] = useState(false);
   const [course, setCourse] = useState({
      course_pay_amt: 0,
   });

   const handleChange = (e) => {
      const { name, value, checked } = e.target;
      if (name === "course_impact") {
         setFormValues({
            ...formValues,
            [name]: checked ? 1 : 0,
         });
      } else {
         setFormValues({
            ...formValues,
            [name]: value,
         });
      }
   };

   const handleCourseTypeChange = (event) => {
      const { value } = event.target;
      setFormValues({ ...formValues, course_type: value });
   };

   useEffect(() => {
      fetchCourse();
   }, []);

   const fetchCourse = () => {
      let courseParamsDate = convertDateStringToYMD(new Date());
      props.callRequest("GET", API_COURSE_VIEW + "/" + courseParamsDate + "/" + courseRef, true, null)
         .then((res) => {
            setFormValues({
               ...formValues,
               course_name: res.data.course.course_name,
               course_code: res.data.course.course_code,
               course_type: res.data.course.course_type,
               course_duration_hours: res.data.course.course_attributes.ca_duration_hours,
               course_duration_months: res.data.course.course_attributes.ca_duration_months,
               course_eligibility_criteria: res.data.course.course_attributes.ca_eligibility_criteria,
               course_impact: res.data.course.is_impact,
               course_content: res.data.course.course_attributes.ca_content,
            });
         }).catch((e) => {
            console.log(e);
         });
   };

   const handleAddMore = () => {
      if (formValues.admission_amount.length === 0) {
         new_id = 0;
      } else {
         const custom = formValues.admission_amount.reduce((prev, current) =>
            +prev.instalment_num > +current.instalment_num ? prev : current
         );
         new_id = custom.instalment_num + 1;
      }
      let new_element = {
         instalment_num: new_id,
         instalment_amt: "",
         status: false,
      };
      setFormValues({
         ...formValues,
         admission_amount: formValues.admission_amount.concat(new_element),
      });
   };

   const handleRemove = (id) => {
      const temp_state = formValues.admission_amount.filter(
         (item) => item.instalment_num !== id
      );
      setFormValues({ ...formValues, admission_amount: temp_state });
   };

   const handlePaymentChange = (index, field, value) => {
      const temp_state = [...formValues.admission_amount];
      temp_state[index][field] = value;
      setFormValues({ ...formValues, admission_amount: temp_state });
   };

   const validateCourse = () => {
      const {
         course_name,
         course_code,
         course_eligibility_criteria,
         course_content,
         course_duration_hours,
         course_duration_months,
         course_fees,
         course_type,
         course_royalty,
         admission_amount,
         fees_effective_from,
         fees_effective_to,
      } = formValues;
      const errors = {};
      let isValid = true;
      let course_pay_amount = 0;

      if (course_name === "") {
         isValid = false;
         errors.course_name = "Course name is required";
      }
      if (course_code === "") {
         isValid = false;
         errors.course_code = "Course code is required";
      }
      if (course_eligibility_criteria === "") {
         isValid = false;
         errors.course_eligibility_criteria =
            "Course eligibility criteria is required";
      }
      if (course_content === "") {
         isValid = false;
         errors.course_content = "Course content is required";
      }
      if (course_duration_hours === "") {
         isValid = false;
         errors.course_duration_hours = "Course duration in hours is required";
      }
      if (course_duration_months === "") {
         isValid = false;
         errors.course_duration_months = "Course duration in months is required";
      }
      if (course_fees === "") {
         isValid = false;
         errors.course_fees = "Course fees is required";
      }
      if (course_type === "") {
         isValid = false;
         errors.course_type = "Course type is required";
      }
      if (course_royalty === "") {
         isValid = false;
         errors.course_royalty = "Course royalty is required";
      }
      if (fees_effective_from === "") {
         isValid = false;
         errors.fees_effective_from = "Fees effective from is required";
      }
      if (fees_effective_to === "") {
         isValid = false;
         errors.fees_effective_to = "Fees effective to is required";
      }

      admission_amount.map((item, i) => {
         course_pay_amount += Number(item.instalment_amt);
         setCourse(prevState => { return { ...prevState, course_pay_amt: course_pay_amount }; });
         if (item.instalment_amt === "") {
            isValid = false;
            errors.instalment_amt = "Amount is requird";
         }
         if (course_pay_amount > course_fees) {
            isValid = false;
            errors.instalment_amt_error = "Please check course fees amount";
         }
         if (item.instalment_amt) {
            const temp_Data = [...formValues.admission_amount];
            let temp_state = { ...temp_Data[item.instalment_num] };
            temp_state.status = true;
            temp_Data[item.instalment_num] = temp_state;
            setFormValues({ ...formValues, admission_amount: temp_Data });
         }
      });
      setFormErrors(errors);
      return isValid;
   };

   const handleCourseFeesSubmit = (e) => {
      e.preventDefault();
      if (!validateCourse()) {
         return false;
      }
      setBtnEnable(true);

      const tempArr = formValues.admission_amount.map((item) => {
         const { status, ...rest } = item;
         return rest;
      });
      const postData = { ...formValues, admission_amount: tempArr };
      props
         .callRequest("POST", API_COURSES_NEW_FEES, true, postData)
         .then((res) => {
            toast.success(`${res.data.message}`, {
               position: toast.POSITION.TOP_CENTER,
               autoClose: 5000,
            });
            setTimeout(() => {
               navigate("/courses");
            }, 5000);
         })
         .catch((e) => {
            setBtnEnable(false);
            console.log(e);
         });
   };

   return (
      <>
         <Form onSubmit={handleCourseFeesSubmit} >
            <ToastContainer />
            <Row className="mb-3">
               <Col lg={8}>
                  <Form.Group controlId="course_name">
                     <Form.Label>Course Name</Form.Label>
                     <Form.Control
                        type="text"
                        name="course_name"
                        value={formValues.course_name || ""}
                        placeholder="Enter course name"
                        onChange={handleChange}
                        disabled
                     />
                     <small className="error">
                        {formValues.course_name === "" && formErrors.course_name}
                     </small>
                  </Form.Group>
               </Col>
               <Col lg={4}>
                  <Form.Group controlId="course_code">
                     <Form.Label>Course Code</Form.Label>
                     <Form.Control
                        type="text"
                        name="course_code"
                        value={formValues.course_code || ""}
                        placeholder="Enter course code"
                        onChange={handleChange}
                        onKeyDown={props.handleKeyPress}
                        maxLength={3}
                        disabled
                     />
                     <small className="error">
                        {formValues.course_code === "" && formErrors.course_code}
                     </small>
                  </Form.Group>
               </Col>
            </Row>

            <Row className="mb-3">
               <Col lg={4}>
                  <Form.Group controlId="course_type">
                     <Form.Label>Course Type</Form.Label>
                     <Form.Select
                        value={formValues.course_type || ""}
                        onChange={handleCourseTypeChange}
                        disabled
                     >
                        {getCourseOption.map((option, i) => (
                           <option key={i} value={option.value}>
                              {option.label}
                           </option>
                        ))}
                     </Form.Select>
                     <small className="error">
                        {formValues.course_type === "" && formErrors.course_type}
                     </small>
                  </Form.Group>
               </Col>

               <Col lg={4}>
                  <Form.Group controlId="course_duration_hours">
                     <Form.Label>Duration (Hours)</Form.Label>
                     <Form.Control
                        name="course_duration_hours"
                        placeholder="Enter course duration in hours"
                        value={formValues.course_duration_hours || ""}
                        onChange={handleChange}
                        onKeyDown={props.handleKeyPress}
                        maxLength={3}
                     />
                     <small className="error">
                        {formValues.course_duration_hours === "" &&
                           formErrors.course_duration_hours}
                     </small>
                  </Form.Group>
               </Col>
               <Col lg={4}>
                  <Form.Group controlId="course_duration_months">
                     <Form.Label>Duration (Months)</Form.Label>
                     <Form.Control
                        name="course_duration_months"
                        placeholder="Enter course duration in months"
                        value={formValues.course_duration_months || ""}
                        onChange={handleChange}
                        onKeyDown={props.handleKeyPress}
                        maxLength={2}
                     />
                     <small className="error">
                        {formValues.course_duration_months === "" &&
                           formErrors.course_duration_months}
                     </small>
                  </Form.Group>
               </Col>
            </Row>
            <Row className="mb-3">
               <Col lg={8}>
                  <Form.Group controlId="course_eligibility_criteria">
                     <Form.Label>Eligibility Criteria</Form.Label>
                     <Form.Control
                        type="text"
                        name="course_eligibility_criteria"
                        value={formValues.course_eligibility_criteria || ""}
                        placeholder="Enter eligibility criteria"
                        onChange={handleChange}
                     />
                     <small className="error">
                        {formValues.course_eligibility_criteria === "" &&
                           formErrors.course_eligibility_criteria}
                     </small>
                  </Form.Group>
               </Col>
               <Col lg={4}>
                  <Form.Group controlId="">
                     <div className="mt-3 pt-4">
                        <input
                           type="checkbox"
                           name="course_impact"
                           checked={formValues.course_impact === 1 ? true : false}
                           onChange={handleChange}
                        />
                        <span className="ms-2">Impact Course</span>
                     </div>
                  </Form.Group>
               </Col>
            </Row>
            <Row className="mb-3">
               <Col lg={12}>
                  <Form.Group controlId="course_content">
                     <Form.Label>Course Content</Form.Label>
                     <Form.Control
                        as="textarea"
                        rows={3}
                        name="course_content"
                        value={formValues.course_content || ""}
                        placeholder="Enter course content"
                        onChange={handleChange}
                     />
                     <small className="error">
                        {formValues.course_content === "" && formErrors.course_content}
                     </small>
                  </Form.Group>
               </Col>
            </Row>
            <div className="row mb-3">
               <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Payment Terms</h5>
               </div>
            </div>
            <Row className="mb-3">
               <Col lg={3}>
                  <Form.Group controlId="course_fees">
                     <Form.Label>Course Fees</Form.Label>
                     <Form.Control
                        type="number"
                        name="course_fees"
                        value={formValues.course_fees || ""}
                        placeholder={props.getCurrencyFormat(0)}
                        onChange={handleChange}
                        min={0}
                     />
                     <small className="error">
                        {formValues.course_fees === "" && formErrors.course_fees}
                     </small>
                  </Form.Group>
               </Col>

               <Col lg={3}>
                  <Form.Group controlId="course_royalty">
                     <Form.Label>Royalty (%)</Form.Label>
                     <Form.Control
                        type="text"
                        name="course_royalty"
                        value={formValues.course_royalty || ""}
                        placeholder="Enter royalty in %"
                        onChange={handleChange}
                        autoComplete="off"
                        onKeyDown={props.handleKeyPress}
                     />
                     <small className="error">
                        {formValues.course_royalty === "" && formErrors.course_royalty}
                     </small>
                  </Form.Group>
               </Col>

               <Col lg={3}>
                  <Form.Group controlId="fees_effective_from">
                     <Form.Label>Fees Effective From</Form.Label>
                     <Form.Control
                        type="date"
                        name="fees_effective_from"
                        value={formValues.fees_effective_from || ""}
                        placeholder="dd/mm/yyyy"
                        onChange={handleChange}
                     />
                     <small className="error">
                        {formValues.fees_effective_from === "" && formErrors.fees_effective_from}
                     </small>
                  </Form.Group>
               </Col>
               <Col lg={3}>
                  <Form.Group controlId="fees_effective_to">
                     <Form.Label>Fees Effective To</Form.Label>
                     <Form.Control
                        type="date"
                        name="fees_effective_to"
                        value={formValues.fees_effective_to || ""}
                        placeholder="dd/mm/yyyy"
                        onChange={handleChange}
                     />
                     <small className="error">
                        {formValues.fees_effective_to === "" && formErrors.fees_effective_to}
                     </small>
                  </Form.Group>
               </Col>
            </Row>

            <div className="mb-3">
               {formValues.admission_amount.map((data, index) => (
                  <Row className="mb-3">
                     <Col lg={3} key={data.instalment_num}>
                        <Form.Group controlId="admission_amount">
                           <Form.Label>
                              {data.instalment_num > 0
                                 ? "Installment - " + index
                                 : "Admission Amount"}
                           </Form.Label>
                           <Form.Control
                              type="number"
                              name="instalment_amt"
                              value={data.instalment_amt || ""}
                              placeholder={props.getCurrencyFormat(0)}
                              min={0}
                              onChange={(e) =>
                                 handlePaymentChange(data.instalment_num, "instalment_amt", e.target.value)
                              }
                           />
                           <small className="error">
                              {data.instalment_amt === ""
                                 ? formErrors.instalment_amt
                                 : course.course_pay_amt > formValues.course_fees
                                    ? formErrors.instalment_amt_error
                                    : ""}
                           </small>
                        </Form.Group>
                     </Col>
                     <Col lg={1}>
                        {index > 0 && (
                           <div className="text-end mt-3 pt-3">
                              <Link
                                 className="text-danger"
                                 onClick={() => handleRemove(data.instalment_num)}
                              >
                                 <i className="las la-trash"></i>
                              </Link>
                           </div>
                        )}
                     </Col>
                  </Row>
               ))}
               <div className="">
                  <Button
                     variant="success"
                     size="sm"
                     onClick={handleAddMore}
                     style={{ marginTop: "0.6rem" }}
                  >
                     <i className="las la-plus"></i> Add More
                  </Button>
               </div>
            </div>

            <div className="mt-2 text-end">
               <Button
                  disabled={btnEnable ? true : false}
                  type="submit"
                  className="btn btn-primary mt-2"
               >
                  Submit
               </Button>
            </div>
         </Form>
      </>
   )
}

export default NewCourseFees
