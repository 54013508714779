const { REACT_APP_API_DOMAIN, REACT_APP_API_WEB_DOMAIN, REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

export const API_DOMAIN = REACT_APP_API_DOMAIN + "/";
export const API_WEB_DOMAIN = REACT_APP_API_WEB_DOMAIN;

//login
export const API_AUTHENTICATE = API_DOMAIN + "authenticate";
export const API_CHANGE_PASSWORD = API_DOMAIN + "change-password";
export const API_FORGOT_PASSWORD = API_DOMAIN + "forgot-password";
export const RECAPTCHA_SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;

//dashboard
export const API_ADMISSION_COUNT = API_DOMAIN + "admission-count";
export const API_CENTER_COUNT = API_DOMAIN + "center-count";
export const API_COURSE_COUNT = API_DOMAIN + "course-count";
export const API_CHARTS_ADMISSION = API_DOMAIN + "charts/admission";
export const API_CHARTS_ROYALTY = API_DOMAIN + "charts/royalty";
export const API_REPORTS_ROYALTY = API_DOMAIN + "reports/royalty";
export const API_ADMISSIONS_PAYMENT_RECEIVED =
  API_DOMAIN + "admissions/payments-received";
export const API_CENTER_REVENUE = API_DOMAIN + "centers/revenue";
export const API_NOTIFICATIONS = API_DOMAIN + "notifications";

//study center
export const API_LISTS_DISTRICT = API_DOMAIN + "lists/district";
export const API_CENTERS_CREATE = API_DOMAIN + "centers/create";
export const API_CENTERS_UPDATE = API_DOMAIN + "centers/update";
export const API_CENTERS = API_DOMAIN + "centers";
export const API_CENTERS_VIEW = API_DOMAIN + "centers/view";
export const API_CENTERS_COURSES_ALLOCATE =
  API_DOMAIN + "centers/coursesallocate";
export const API_CENTERS_SEARCH = API_DOMAIN + "centers/search";
export const API_CENTERS_SET_OLD_COUNT = API_DOMAIN + "centers/set-old-count";

//courses
export const API_COURSES = API_DOMAIN + "courses";
export const API_COURSES_SEARCH = API_DOMAIN + "courses/search";
export const API_COURSE_CREATE = API_DOMAIN + "courses/create";
export const API_COURSE_UPDATE = API_DOMAIN + "courses/update";
export const API_COURSE_VIEW = API_DOMAIN + "courses/view";
export const API_CENTERS_COURSES_BATCHES_CREATE =
  API_DOMAIN + "centers/courses/batches/create";
export const API_COURSES_BATCHES_VIEW = API_DOMAIN + "courses/batches/view";
export const API_COURSES_NEW_FEES = API_DOMAIN + "courses/create-new-fees";
export const API_COURSES_SHIFT = API_DOMAIN + "courses/shift";

//batches
export const API_BATCHES = API_DOMAIN + "batches";
export const API_BATCHES_SEARCH = API_DOMAIN + "batches/search";
export const API_BATCHES_CREATE = API_DOMAIN + "batches/create";
export const API_LISTS_FINANCIAL_YEAR = API_DOMAIN + "lists/financialyear";
export const API_BATCHES_VIEW = API_DOMAIN + "batches/view";

//users
export const API_USERS = API_DOMAIN + "users";
export const API_LISTS_CENTER = API_DOMAIN + "lists/center";
export const API_USERS_CREATE = API_DOMAIN + "users/create";
export const API_USERS_VIEW = API_DOMAIN + "users/view";
export const API_USERS_UPDATE = API_DOMAIN + "users/update";
export const API_USER_CHANGE_PASSWORD = API_DOMAIN + "users/change-password";


//reconciliation
export const API_ADMISSIONS_RECONCILIATION =
  API_DOMAIN + "admissions/reconciliation";
export const API_ADMISSIONS_SAVE_RECONCILIATION =
  API_DOMAIN + "admissions/save-reconciliation";
export const API_CENTERS_RECONCILIATION = API_DOMAIN + "centers/reconciliation";
export const API_CENTERS_SAVE_RECONCILIATION =
  API_DOMAIN + "centers/save-reconciliation";

//reports
export const API_LISTS_COURSE = API_DOMAIN + "lists/course";
export const API_REPORTS_CENTER_WISE_ADMISSIONS =
  API_DOMAIN + "reports/center-wise-admissions";
export const API_REPORTS_COURSE_WISE_ADMISSIONS =
  API_DOMAIN + "reports/course-wise-admissions";
export const API_REPORTS_ALL_ADMISSIONS = API_DOMAIN + "reports/all-admissions";
export const API_REPORTS_CENTER_WISE_ROYALTY =
  API_DOMAIN + "reports/center-wise-royalty";
export const API_REPORTS_CENTER_WISE_REVENUE =
  API_DOMAIN + "reports/center-wise-revenue";
export const API_REPORTS_COURSE_WISE_REVENUE =
  API_DOMAIN + "reports/course-wise-revenue";
export const API_REPORTS_ALL_REVENUE = API_DOMAIN + "reports/all-revenue";
export const API_REPORTS_CENTER_WISE_OLD_ADMISSIONS = API_DOMAIN + "reports/center-wise-old-admissions";

//admission
export const API_ADMISSIONS = API_DOMAIN + "admissions";
export const API_ADMISSIONS_SEARCH = API_DOMAIN + "admissions/search";
export const API_ADMISSIONS_VIEW = API_DOMAIN + "admissions/view";
export const API_ADMISSIONS_EDIT = API_DOMAIN + "admissions/edit";
export const API_ADMISSIONS_ASSIGN_BATCH =
  API_DOMAIN + "admissions/assign-batch";
export const API_ADMISSIONS_PAYMENT_CALCULATE =
  API_DOMAIN + "admissions/payment/calculate";
export const API_ADMISSIONS_PAYMENT_SAVE =
  API_DOMAIN + "admissions/payment/save";
export const API_ADMISSIONS_UPDATE = API_DOMAIN + "admissions/update";
export const API_ADMISSIONS_SEARCH_OLD_CANDIDATE =
  API_DOMAIN + "admissions/search-old-candidate";
export const API_ADMISSIONS_CREATE_EXISTING =
  API_DOMAIN + "admissions/create-existing";
export const API_ADMISSIONS_CREATE = API_DOMAIN + "admissions/create";
export const API_ADMISSIONS_PAYMENT_CALCULATE_FEES =
  API_DOMAIN + "admissions/payment/calculate-fees";
export const API_ADMISSIONS_PAYMENT_CALCULATE_DISCOUNT =
  API_DOMAIN + "admissions/payment/calculate-discount";
export const API_COURSES_ALL = API_DOMAIN + "courses/all";

// Role & Permission
export const API_ROLES = API_DOMAIN + "roles";
export const API_ROLE_CREATE = API_DOMAIN + "roles/create";
export const API_ROLES_VIEW = API_DOMAIN + "roles/view";
export const API_FETCH_ROLES_DATA = API_DOMAIN + "roles/edit";
export const API_ROLE_UPDATE = API_DOMAIN + "roles/update";
export const API_LISTS_URLGROUP = API_DOMAIN + "lists/urlgroup";
export const API_ROLES_PERMISSIONS = API_DOMAIN + "roles/permissions";
export const API_ROLES_PERMISSIONS_SAVE = API_DOMAIN + "roles/permissions/save";
export const API_LISTS_ROLE = API_DOMAIN + "lists/role";

//Site Maintenance
export const API_IS_MAINTENANCE = API_DOMAIN + "is-maintenance";