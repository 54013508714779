import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import {
  API_WEB_DOMAIN,
  API_ADMISSIONS_UPDATE,
  API_COURSES,
  API_ADMISSIONS_EDIT,
} from "../../config/Api";
import getCroppedImg from "../../helpers/cropImage";
import Cropper from "react-easy-crop";
import { ToastContainer, toast } from "react-toastify";

function EditAdmissionForm(props) {
  const { studentRef } = props;

  let center_id = "";
  let center_ref = "";
  let user = "";
  let center = "";

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    user = JSON.parse(localStorage.getItem("user"));
    center = JSON.parse(localStorage.getItem("center"));

    if (user.role.role_name === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
      center_id = center.ct_id;
    }
  }

  const initialValues = {
    student_type: "",
    old_reg_num: "",
    fullname: "",
    guardian_name: "",
    phone: "",
    alternate_phone: "",
    email: "",
    dob: "",
    education_qualifications: "",
    stud_edu_cert: "",
    stud_caste: "GENERAL",
    stud_caste_doc: "",
    gender: "",
    stud_present_address: "",
    stud_permanent_address: "",
    course_id: "",
    batch_id: "",
    stud_pic: "",
    stud_sign: "",
    id_type: "",
    id_num: "",
    admission_date: "",
    stud_govt_id_path: "",
    payment_option: "",
    money_received: [
      {
        payments_id: 0,
        pay_by: "",
        payment_date: props.convertDateStringToYMD(new Date()),
        pmt_amt: "",
        chq_bank: "",
        chq_num: "",
        chq_date: props.convertDateStringToYMD(new Date()),
        recpt_num2: "",
        status: false,
      },
    ],
  };

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [showOldRegCertNum, setShowOldRegCertNum] = useState(false);
  const [selectedIDCard, setSelectedIDCard] = useState();
  const [selectedEducationCertificate, setSelectedEducationCertificate] =
    useState(null);
  const [selectedCasteCertificate, setSelectedCasteCertificate] =
    useState(null);
  const [showCasteUpload, setShowCasteUpload] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [isPdf, setIspdf] = useState({
    caste: true,
    govt_id: true,
    last_edu_qualification: true,
  });

  const [btnEnable, setBtnEnable] = useState(false);

  //For Image
  const [srcImg, setSrcImg] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [resultImg, setResultImg] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  //For Signature
  const [srcSign, setSrcSign] = useState(null);
  const [cropSign, setCropSign] = useState({ x: 0, y: 0 });
  const [croppedAreaPixelsSignature, setCroppedAreaPixelsSignature] =
    useState(null);
  const [zoomSign, setZoomSign] = useState(1);
  const [resultSign, setResultSign] = useState(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  useEffect(() => {
    fetchStudent();
    fetchCourseOptionsData();
  }, []);

  const fetchStudent = () => {
    props
      .callRequest("GET", API_ADMISSIONS_EDIT + "/" + studentRef, true, null)
      .then((res) => {
        setFormValues({
          ...formValues,
          fullname: res.data.admission_info.student_info.stud_fullname,
          guardian_name:
            res.data.admission_info.student_info.stud_guardian_name,
          phone: res.data.admission_info.student_info.stud_phone,
          alternate_phone: res.data.admission_info.student_info.stud_phone2,
          email: res.data.admission_info.student_info.stud_email,
          education_qualifications:
            res.data.admission_info.student_info.stud_edu_qual,
          stud_present_address:
            res.data.admission_info.student_info.stud_present_address,
          stud_permanent_address:
            res.data.admission_info.student_info.stud_permanent_address,
          dob: res.data.admission_info.student_info.stud_dob,
          gender: res.data.admission_info.student_info.stud_gender,
          id_type: res.data.admission_info.student_info.stud_govt_id_type,
          id_num: res.data.admission_info.student_info.stud_govt_id_num,
          stud_caste: res.data.admission_info.student_info.stud_caste,
          old_reg_num: res.data.admission_info.student_info.stud_reg_no,
          student_type: res.data.admission_info.student_info.is_new,
          stud_fin_year: res.data.admission_info.student_info.stud_fin_year,
          admission_date: props.convertDateStringToYMD(
            res.data.admission_info.student_info.stud_admission_date
          ),
          course_id: res.data.admission_info.course_info.course_id,
          course_name: res.data.admission_info.course_info.course_name,
          stud_edu_cert:
            res.data.admission_info.student_info.stud_edu_qual_path,
          stud_govt_id_path:
            res.data.admission_info.student_info.stud_govt_id_path,
          stud_caste_doc:
            res.data.admission_info.student_info.stud_caste_document,
          money_received: res.data.admission_info.payment_info,
        });
        setSelectedEducationCertificate(
          res.data.admission_info.student_info.stud_edu_qual_path
        );
        setSelectedIDCard(
          res.data.admission_info.student_info.stud_govt_id_path
        );
        setSelectedCasteCertificate(
          res.data.admission_info.student_info.stud_caste
        );
        setShowCasteUpload(
          res.data.admission_info.student_info.stud_caste !== "GENERAL"
        );
        setResultImg(
          API_WEB_DOMAIN + res.data.admission_info.student_info.stud_pic
        );
        setResultSign(
          API_WEB_DOMAIN + res.data.admission_info.student_info.stud_sign
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCourseOptionsData = () => {
    props
      .callRequest("GET", API_COURSES + center_ref, true, null)
      .then((res) => {
        setCourseOptions(res.data.courses);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleStudentTypeChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, student_type: value });
    setShowOldRegCertNum(value === "0");
  };

  const handleEducationCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedEducationCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_edu_cert: e.target.files[0],
        });
        setIspdf({ ...isPdf, last_edu_qualification: true });
      } else {
        setSelectedEducationCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_edu_cert: e.target.files[0],
        });
        setIspdf({ ...isPdf, last_edu_qualification: false });
      }
    }
  };

  const handleIDCardChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedIDCard(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_govt_id_path: e.target.files[0],
        });
        setIspdf({ ...isPdf, govt_id: true });
      } else {
        setSelectedIDCard(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_govt_id_path: e.target.files[0],
        });
        setIspdf({ ...isPdf, govt_id: false });
      }
    }
  };

  const handleCasteCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedCasteCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_caste_doc: e.target.files[0],
        });
        setIspdf({ ...isPdf, caste: true });
      } else {
        setSelectedCasteCertificate(e.target.files[0]);
        setFormValues({
          ...formValues,
          stud_caste_doc: e.target.files[0],
        });
        setIspdf({ ...isPdf, caste: false });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, stud_caste: value });
    setShowCasteUpload(value !== "GENERAL");
  };

  const handlePaymentChange = (index, field, value) => {
    const temp_state = formValues.money_received.map((item) => {
      if (item.payments_id === index) {
        item[field] = value
      }
      return item;
    })
    setFormValues({ ...formValues, money_received: temp_state });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setFormValues((formValues) => ({
        ...formValues,
        stud_permanent_address: formValues.stud_present_address,
      }));
    } else {
      setFormValues((formValues) => ({
        ...formValues,
        stud_permanent_address: "",
      }));
    }
  };

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
  };

  const handleShowSignatureModal = () => setShowSignatureModal(true);
  const handleCloseSignatureModal = () => setShowSignatureModal(false);

  const handleSignature = async (event) => {
    setSrcSign(URL.createObjectURL(event.target.files[0]));
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        srcImg,
        croppedAreaPixels,
        "profile"
      );

      setResultImg(croppedImage);
      setFormValues({
        ...formValues,
        stud_pic: croppedImage,
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onCropCompleteSignature = useCallback(
    (croppedArea, croppedAreaPixelsSignature) => {
      setCroppedAreaPixelsSignature(croppedAreaPixelsSignature);
    },
    []
  );

  const showCroppedImageSignature = useCallback(async () => {
    try {
      const croppedImageSignature = await getCroppedImg(
        srcSign,
        croppedAreaPixelsSignature,
        "signature"
      );

      setResultSign(croppedImageSignature);
      setFormValues({
        ...formValues,
        stud_sign: croppedImageSignature,
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixelsSignature]);

  const validateStudentForm = () => {
    const {
      student_type,
      old_reg_num,
      fullname,
      guardian_name,
      phone,
      dob,
      gender,
      education_qualifications,
      id_type,
      id_num,
      stud_caste,
      stud_present_address,
      stud_permanent_address,
      course_id,
      admission_date,
      money_received,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (student_type === 0 && old_reg_num === "") {
      isValid = false;
      errors.old_reg_num = "Old registration number is required";
    }
    if (fullname === "") {
      isValid = false;
      errors.fullname = "Student fullname is required";
    } else if (fullname !== "" && !props.checkAlphaOnly(fullname)) {
      isValid = false;
      errors.fullname_not_valid = "Valid entry only alphabets, space and dot";
    }
    if (guardian_name === "") {
      isValid = false;
      errors.guardian_name = "Father's/Husband's name is required";
    } else if (guardian_name !== "" && !props.checkAlphaOnly(guardian_name)) {
      isValid = false;
      errors.guardian_name_not_valid =
        "Valid entry only alphabets, space and dot";
    }

    if (dob === "") {
      isValid = false;
      errors.dob = "Date of Birth is required";
    } else if (dob !== "" && !props.isValidDate(dob)) {
      isValid = false;
      errors.valid_dob = "Date of Birth is not valid";
    }
    if (gender === "") {
      isValid = false;
      errors.gender = "Gender is required";
    }
    if (education_qualifications === "") {
      isValid = false;
      errors.education_qualifications =
        "Educational qualifications is required";
    }
    if (!selectedEducationCertificate) {
      isValid = false;
      errors.stud_edu_cert = "Educational Certificate upload is required";
    } else if (!isPdf.last_edu_qualification && selectedEducationCertificate) {
      isValid = false;
      errors.stud_edu_format_pdf = "Invalid file format";
    }
    if (id_type === "") {
      isValid = false;
      errors.id_type = "Government ID type is required";
    }
    if (id_num === "") {
      isValid = false;
      errors.id_num = "Government ID number is required";
    }
    if (!selectedIDCard) {
      isValid = false;
      errors.stud_govt_id_path = "Government ID card upload is required";
    } else if (selectedIDCard && !isPdf.govt_id) {
      isValid = false;
      errors.stud_govt_id_path_format_pdf = "Invalid file format";
    }
    if (stud_caste !== "GENERAL" && !selectedCasteCertificate) {
      isValid = false;
      errors.stud_caste_doc = "Caste certificate is required";
    } else if (
      stud_caste !== "GENERAL" &&
      selectedCasteCertificate &&
      !isPdf.caste
    ) {
      isValid = false;
      errors.stud_caste_format_pdf = "Invalid file format";
    }
    if (stud_present_address === "") {
      isValid = false;
      errors.stud_present_address = "Present address is required";
    }
    if (stud_permanent_address === "") {
      isValid = false;
      errors.stud_permanent_address = "Permanent address is required";
    }
    if (course_id === "") {
      isValid = false;
      errors.course_id = "Course is required";
    }
    if (!resultImg) {
      isValid = false;
      errors.stud_pic = "Student photo is required";
    }
    if (!resultSign) {
      isValid = false;
      errors.stud_sign = "Student signature is required";
    }
    if (phone === "") {
      isValid = false;
      errors.phone = "Phone number is required and must be 10 digit";
    }
    if (admission_date === "") {
      isValid = false;
      errors.admission_date = "Admission date is required";
    } else if (admission_date !== "" && !props.isValidDate(admission_date)) {
      isValid = false;
      errors.validate_admission_date = "Admission date is not valid";
    } else if (admission_date !== "" && !props.compareDate(admission_date)) {
      isValid = false;
      errors.validate_compare_admission_date = "Admission date should be less than today";
    }

    money_received.map((item, i) => {
      if (item.pay_by === "") {
        isValid = false;
        errors.pay_by = "Payment mode is required";
      }
      if (item.pay_by === "CASH") {
        if (item.payment_date == "") {
          isValid = false;
          errors.payment_date = "Payment date is required";
        } else if (
          item.payment_date !== "" &&
          !props.isValidDate(props.convertDateStringToYMD(item.payment_date))
        ) {
          isValid = false;
          errors.validate_payment_date = "Payment date is not valid";
        } else if (
          item.payment_date !== "" &&
          !props.compareDate(item.payment_date)
        ) {
          isValid = false;
          errors.valid_compare_pay_date = "Payment date should be less than today";
        }
        if (item.recpt_num2 === "") {
          isValid = false;
          errors.recpt_num2 = "Existing MR Number is required";
        }
        if (item.pmt_amt === "") {
          isValid = false;
          errors.pmt_amt = "Amount is required";
        } else if (item.pmt_amt !== "" && Number(item.pmt_amt) === 0) {
          isValid = false;
          errors.pmt_amt_zero_val = "Amount can't be zero value";
        }
        if (
          item.pay_by !== "" &&
          item.payment_date !== "" &&
          item.recpt_num2 !== "" &&
          item.pmt_amt !== ""
        ) {
          const updatedFormData = [...formValues.money_received];
          let temp_state = { ...updatedFormData[item.id] };
          temp_state.status = true;
          updatedFormData[item.id] = temp_state;
          setFormValues({ ...formValues, money_received: updatedFormData });
        }
      }
      if (["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(item.pay_by)) {
        if (item.payment_date === "") {
          isValid = false;
          errors.payment_date = "Payment date is required";
        } else if (
          item.payment_date !== "" &&
          !props.isValidDate(props.convertDateStringToYMD(item.payment_date))
        ) {
          isValid = false;
          errors.validate_payment_date = "Payment date is not valid";
        } else if (
          item.payment_date !== "" &&
          !props.compareDate(item.payment_date)
        ) {
          isValid = false;
          errors.valid_compare_pay_date = "Payment date should be less than today";
        }
        if (item.recpt_num2 === "") {
          isValid = false;
          errors.recpt_num2 = "Existing MR Number is required";
        }
        if (item.chq_num === "") {
          isValid = false;
          errors.chq_num = "Cheque No is required";
        }
        if (item.chq_date === "") {
          isValid = false;
          errors.chq_date = "Cheque date is required";
        } else if (item.chq_date !== "" && !props.isValidDate(item.chq_date)) {
          isValid = false;
          errors.validate_chq_date = "Cheque date is not valid";
        } else if (item.chq_date !== "" && !props.compareDate(item.chq_date)) {
          isValid = false;
          errors.validate_compare_chq_date = "Cheque date should be less than today";
        }
        if (item.chq_bank === "") {
          isValid = false;
          errors.chq_bank = "Bank Name is required";
        }
        if (item.pmt_amt === "") {
          isValid = false;
          errors.pmt_amt = "Amount is required";
        } else if (item.pmt_amt !== "" && Number(item.pmt_amt) === 0) {
          isValid = false;
          errors.pmt_amt_zero_val = "Amount can't be zero value";
        }
        if (
          item.payment_date !== "" &&
          item.recpt_num2 !== "" &&
          item.chq_num !== "" &&
          item.chq_date !== "" &&
          item.chq_bank !== "" &&
          item.pmt_amt !== ""
        ) {
          const updatedFormData = [...formValues.money_received];
          let temp_state = { ...updatedFormData[item.id] };
          temp_state.status = true;
          updatedFormData[item.id] = temp_state;
          setFormValues({ ...formValues, money_received: updatedFormData });
        }
      }
    });

    setFormErrors(errors);
    return isValid;
  };

  const handleStudentSubmit = (e) => {
    e.preventDefault();
    if (!validateStudentForm()) {
      return false;
    }
    setBtnEnable(true);
    const formData = new FormData();
    for (let [key, value] of Object.entries(formValues)) {
      if (key === "stud_edu_cert") {
        formData.append("stud_edu_cert", selectedEducationCertificate);
      } else if (key === "stud_govt_id_path") {
        formData.append("stud_govt_id_path", selectedIDCard);
      } else if (key === "stud_caste_doc") {
        formData.append("stud_caste_doc", selectedCasteCertificate);
      } else if (key === "stud_pic") {
        formData.append("stud_pic", resultImg);
      } else if (key === "stud_sign") {
        formData.append("stud_sign", resultSign);
      } else if (key === "money_received") {
        formData.append(
          "money_received",
          JSON.stringify(formValues.money_received)
        );
      } else {
        formData.append(key, value);
      }
    }
    props
      .callRequest(
        "POST",
        API_ADMISSIONS_UPDATE + "/" + studentRef,
        true,
        formData
      )
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          navigate("/admissions/view/" + studentRef);
        }, 5000);
      })
      .catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };



  return (
    <>
      <Form onSubmit={handleStudentSubmit}>
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="stud_fin_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Control
                type="text"
                name="stud_fin_year"
                value={formValues.stud_fin_year ?? ""}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="stud_admission_date">
              <Form.Label>Admission Date</Form.Label>
              <Form.Control
                type="date"
                name="admission_date"
                value={formValues.admission_date ?? ""}
                onChange={handleChange}

              />
              <small className="error">
                {!props.isValidDate(formValues.admission_date)
                  ? formErrors.validate_admission_date
                  : !props.compareDate(formValues.admission_date)
                    ? formErrors.validate_compare_admission_date : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="student_type">
              <Form.Label>Is Old Student?</Form.Label>
              <Form.Select
                value={formValues.student_type}
                onChange={handleStudentTypeChange}
                disabled
              >
                <option value="1">No</option>
                <option value="0">Yes</option>
              </Form.Select>
            </Form.Group>
          </Col>

          {showOldRegCertNum && (
            <Col lg={4}>
              <Form.Group controlId="old_reg_num">
                <Form.Label>Old Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="old_reg_num"
                  value={formValues.old_reg_num ?? ""}
                  placeholder="Enter Registration Number"
                  onChange={handleChange}
                  disabled
                />
                <small className="error">
                  {formValues.student_type === 0 &&
                    formValues.old_reg_num === "" &&
                    formErrors.old_reg_num}
                </small>
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="fullname">
              <Form.Label>Student Name</Form.Label>
              <Form.Control
                type="text"
                name="fullname"
                value={formValues.fullname ?? ""}
                placeholder="Student Fullname"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.fullname === ""
                  ? formErrors.fullname
                  : formValues.fullname !== "" &&
                    !props.checkAlphaOnly(formValues.fullname)
                    ? formErrors.fullname_not_valid
                    : ""}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="guardian_name">
              <Form.Label> Father's/Husband's Name</Form.Label>
              <Form.Control
                type="text"
                name="guardian_name"
                value={formValues.guardian_name ?? ""}
                placeholder="Father's/Husband's Name"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.guardian_name === ""
                  ? formErrors.guardian_name
                  : formValues.guardian_name !== "" &&
                    !props.checkAlphaOnly(formValues.guardian_name)
                    ? formErrors.guardian_name_not_valid
                    : ""}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formValues.dob ?? ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.dob === ""
                  ? formErrors.dob
                  : formValues.dob !== "" && !props.isValidDate(formValues.dob)
                    ? formErrors.valid_dob
                    : ""}
              </small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formValues.phone ?? ""}
                placeholder="e.g. 9876543210"
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
                maxLength={10}
                autoComplete="off"
              />
              <small className="error">
                {formValues.phone === "" && formErrors.phone}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="alternate_phone">
              <Form.Label>Alternate Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="alternate_phone"
                value={formValues.alternate_phone ?? ""}
                placeholder="e.g. 9876543210"
                onChange={handleChange}
                onKeyDown={props.handleKeyPress}
                maxLength={10}
                autoComplete="off"
              />
              <small className="error">
                {formValues.alternate_phone === "" &&
                  formErrors.alternate_phone}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="email">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={formValues.email ?? ""}
                placeholder="Enter Email Address"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="gender">
              <Form.Label>Gender</Form.Label>
              <select
                className="form-select"
                aria-label="Select Gender"
                name="gender"
                value={formValues.gender ?? ""}
                onChange={handleChange}
              >
                {props.getGender().map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <small className="error">
                {formValues.gender === "" && formErrors.gender}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="education_qualifications">
              <Form.Label> Last Educational Qualification</Form.Label>
              <Form.Control
                type="text"
                name="education_qualifications"
                value={formValues.education_qualifications ?? ""}
                placeholder="Enter Educational Qualification"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.education_qualifications === "" &&
                  formErrors.education_qualifications}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="stud_edu_cert">
              <Form.Label>Upload Last Qualification Certificate</Form.Label>

              {formValues ? (
                <Link
                  to={API_WEB_DOMAIN + formValues.stud_edu_qual_path}
                  target="_blank"
                >
                  <center>
                    <i className="las la-file-pdf me-4"></i>
                  </center>
                </Link>
              ) : (
                ""
              )}

              <Form.Control
                type="file"
                name="stud_edu_cert"
                onChange={handleEducationCertificateChange}
              />
              <small className="error">
                {!selectedEducationCertificate
                  ? formErrors.stud_edu_cert
                  : selectedEducationCertificate &&
                    !isPdf.last_edu_qualification
                    ? formErrors.stud_edu_format_pdf
                    : ""}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="id_type">
              <Form.Label>Government ID</Form.Label>
              <select
                className="form-select"
                aria-label="Select ID Type"
                name="id_type"
                value={formValues.id_type ?? ""}
                onChange={handleChange}
              >
                {props.getGovId().map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <small className="error">
                {formValues.id_type === "" && formErrors.id_type}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="id_num">
              <Form.Label>Government ID Number</Form.Label>
              <Form.Control
                type="text"
                name="id_num"
                value={formValues.id_num ?? ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.id_num === "" && formErrors.id_num}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="stud_govt_id_path">
              <Form.Label>Upload Government ID</Form.Label>
              <br />

              {formValues ? (
                <Link
                  to={API_WEB_DOMAIN + formValues.stud_govt_id_path}
                  target="_blank"
                >
                  <center>
                    <i className="las la-file-pdf me-4"></i>
                  </center>
                </Link>
              ) : (
                ""
              )}

              <Form.Control
                type="file"
                name="stud_govt_id_path"
                onChange={handleIDCardChange}
              />
              <small className="error">
                {!selectedIDCard
                  ? formErrors.stud_govt_id_path
                  : selectedIDCard && !isPdf.govt_id
                    ? formErrors.stud_govt_id_path_format_pdf
                    : ""}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="stud_caste">
              <Form.Label>Category</Form.Label>
              <Form.Select
                value={formValues.stud_caste ?? ""}
                onChange={handleCategoryChange}
                disabled
              >
                {props.getCategory().map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {showCasteUpload && (
            <Col lg={4}>
              <Form.Group controlId="stud_caste_doc">
                <Form.Label>Upload Caste Certificate</Form.Label>
                <br />
                {formValues ? (
                  <Link
                    to={API_WEB_DOMAIN + formValues.stud_caste_doc}
                    target="_blank"
                  >
                    <center>
                      <i className="las la-file-pdf me-1"></i>
                    </center>
                  </Link>
                ) : (
                  ""
                )}
                <Form.Control
                  type="file"
                  name="stud_caste_doc"
                  onChange={handleCasteCertificateChange}
                  accept=".pdf"
                  disabled
                />
                <small className="error">
                  {formValues.stud_caste !== "GENERAL" &&
                    !selectedCasteCertificate
                    ? formErrors.stud_caste_doc
                    : formValues.stud_caste !== "GENERAL" &&
                      selectedCasteCertificate &&
                      !isPdf.caste
                      ? formErrors.stud_caste_format_pdf
                      : ""}
                </small>
              </Form.Group>
            </Col>
          )}
        </Row>

        <Row className="mb-3">
          <Col lg={6}>
            <Form.Group controlId="stud_present_address">
              <Form.Label>Current Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="stud_present_address"
                placeholder="Enter present address"
                value={formValues.stud_present_address ?? ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.stud_present_address === "" &&
                  formErrors.stud_present_address}
              </small>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="stud_permanent_address">
              <div className="d-flex justify-content-between">
                <Form.Label>Permanent Address</Form.Label>
                <div className="d-flex justify-content-end">
                  <Form.Check
                    id="same-as-current-address"
                    type="checkbox"
                    name="same-as-current-address"
                    className="me-2"
                    checked={
                      formValues.stud_permanent_address !== "" ? true : false
                    }
                    onChange={handleCheckboxChange}
                  />
                  <Form.Label className="fw-normal">
                    Same as Current Address
                  </Form.Label>
                </div>
              </div>
              <Form.Control
                as="textarea"
                rows={3}
                name="stud_permanent_address"
                placeholder="Enter permanent address"
                value={formValues.stud_permanent_address ?? ""}
                onChange={handleChange}
              />
              <small className="error">
                {formValues.stud_permanent_address === "" &&
                  formErrors.stud_permanent_address}
              </small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={6}>
            <Form.Group controlId="stud_pic">
              <Form.Label>Upload Photo</Form.Label>

              <div className="preview-container">
                <div className="img-preview">
                  {resultImg && (
                    <div>
                      <img src={resultImg} alt="cropped" />
                    </div>
                  )}
                </div>
                <div className="ms-3">
                  <Button
                    className="btn btn-primary"
                    onClick={handleShowImageModal}
                  >
                    Choose Photo
                  </Button>
                </div>
              </div>
              <small className="error">
                {!resultImg && formErrors.stud_pic}
              </small>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group controlId="stud_sign">
              <Form.Label>Upload Signature</Form.Label>
              <div className="preview-container">
                <div className="sign-preview">
                  {resultSign && (
                    <div>
                      <img src={resultSign} alt="cropped" />
                    </div>
                  )}
                </div>
                <div className="ms-3">
                  <Button
                    className="btn btn-primary"
                    onClick={handleShowSignatureModal}
                  >
                    Choose Signature
                  </Button>
                </div>
              </div>
              <small className="error">
                {!resultSign && formErrors.stud_sign}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="course_name">
              <Form.Label>Course</Form.Label>
              <Form.Control
                type="text"
                name="course_name"
                value={formValues.course_name ?? ""}
                disabled
              />
              <small className="error">
                {formValues.course_id === "" && formErrors.course_id}
              </small>
            </Form.Group>
          </Col>
          <div className="col align-self-end">
            <Link to={`/course-shift/${studentRef}`}>
              Shift Course
            </Link>
          </div>
        </Row>
        <div className="row pb-3">
          <div className="col-xl-12 col-sm-12 mt-4">
            <h5 className="fw-bold text-muted">Money Receipt Details</h5>
          </div>
        </div>
        <div className="mb-3">
          {formValues.money_received.map((data, index) => (
            <div key={data.payments_id}>
              <Row className="mb-3">
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Payment Mode</Form.Label>
                    <Form.Select
                      name="pay_by"
                      value={data.pay_by ?? ""}
                      onChange={(e) =>
                        handlePaymentChange(
                          data.payments_id,
                          "pay_by",
                          e.target.value
                        )
                      }
                    >
                      {props.getPaymentMode().map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <small className="error">
                      {!data.status && data.pay_by === ""
                        ? formErrors.pay_by
                        : ""}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Payment Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="payment_date"
                      value={props.convertDateStringToYMD(data.payment_date)}
                      onChange={(e) =>
                        handlePaymentChange(
                          data.payments_id,
                          "payment_date",
                          e.target.value
                        )
                      }
                    />
                    <small className="error">
                      {data.payment_date === "" ? formErrors.payment_date :
                        !props.isValidDate(data.payment_date)
                          ? formErrors.validate_payment_date
                          : !props.compareDate(data.payment_date) ? formErrors.valid_compare_pay_date : ""}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Existing MR Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="recpt_num2"
                      value={data.recpt_num2 ?? ""}
                      placeholder="Enter Money Receipt Number"
                      onChange={(e) =>
                        handlePaymentChange(
                          data.payments_id,
                          "recpt_num2",
                          e.target.value
                        )
                      }
                      autoComplete="off"
                    />
                    <small className="error">
                      {!data.status && data.recpt_num2 === ""
                        ? formErrors.recpt_num2
                        : ""}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Amount Paid</Form.Label>
                    <Form.Control
                      type="number"
                      name="pmt_amt"
                      value={data.pmt_amt ?? ""}
                      min={0}
                      onChange={(e) =>
                        handlePaymentChange(
                          data.payments_id,
                          "pmt_amt",
                          e.target.value
                        )
                      }
                    />
                    <small className="error">
                      {!data.status && data.pmt_amt === ""
                        ? formErrors.pmt_amt
                        : data.pmt_amt !== "" &&
                          Number(data.pmt_amt) === 0
                          ? formErrors.pmt_amt_zero_val
                          : ""}
                    </small>
                    {/* <small className="error">
                      {course.course_pay_amt >
                        (course.course_data.course_fees - formValues.total_discount)
                        && formErrors.course_pay_amt_err}
                    </small> */}
                  </Form.Group>
                </Col>


              </Row>

              {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(data.pay_by) && (
                <Row className="mb-3">
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Cheque/DD/Ref. No</Form.Label>
                      <Form.Control
                        type="number"
                        name="chq_num"
                        value={data.chq_num ?? ""}
                        placeholder="Enter Cheque No."
                        onChange={(e) =>
                          handlePaymentChange(
                            data.payments_id,
                            "chq_num",
                            e.target.value
                          )
                        }
                      />
                      <small className="error">
                        {!data.status && data.chq_num === ""
                          ? formErrors.chq_num
                          : ""}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Cheque/DD Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="chq_date"
                        value={data.chq_date ?? ""}
                        onChange={(e) =>
                          handlePaymentChange(
                            data.payments_id,
                            "chq_date",
                            e.target.value
                          )
                        }
                      />
                      <small className="error">
                        {!data.status && data.chq_date === ""
                          ? formErrors.chq_date
                          : data.chq_date !== "" &&
                            !props.isValidDate(data.chq_date)
                            ? formErrors.validate_chq_date
                            : formValues.chq_date !== "" && !props.compareDate(data.chq_date) ? formErrors.validate_compare_chq_date : ""}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="chq_bank"
                        value={data.chq_bank ?? ""}
                        placeholder="Enter Bank Name"
                        onChange={(e) =>
                          handlePaymentChange(
                            data.payments_id,
                            "chq_bank",
                            e.target.value
                          )
                        }
                      />
                      <small className="error">
                        {!data.status && data.chq_bank === ""
                          ? formErrors.chq_bank
                          : ""}
                      </small>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </div>
          ))}
        </div>

        <div className="mt-2 text-end">
          <Button
            disabled={btnEnable ? true : false}
            type="submit"
            className="btn btn-primary mt-2"
          >
            Submit
          </Button>
        </div>
      </Form>

      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Form>
          <Modal.Header>
            <Modal.Title>Select Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="file"
              name="stud_pic"
              onChange={handleImage}
              accept=".jpg,.png"
            />
            <br />
            <div>
              {srcImg && (
                <div
                  style={{
                    height: "200px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Cropper
                    image={srcImg}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cropButton"
              onClick={() => {
                showCroppedImage();
                handleCloseImageModal();
              }}
            >
              Crop
            </Button>
            <Button variant="secondary" onClick={handleCloseImageModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSignatureModal}
        onHide={handleCloseSignatureModal}
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Select Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="file"
              name="stud_sign"
              onChange={handleSignature}
              accept=".jpg,.png"
            />
            <br />
            <div>
              {srcSign && (
                <div
                  style={{
                    height: "200px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Cropper
                    image={srcSign}
                    crop={cropSign}
                    zoom={zoomSign}
                    aspect={30 / 7}
                    onCropChange={setCropSign}
                    onCropComplete={onCropCompleteSignature}
                    onZoomChange={setZoomSign}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cropButton"
              onClick={() => {
                showCroppedImageSignature();
                handleCloseSignatureModal();
              }}
            >
              Crop
            </Button>
            <Button variant="secondary" onClick={handleCloseSignatureModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditAdmissionForm;
