import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  API_ADMISSIONS_PAYMENT_CALCULATE_FEES,
  API_ADMISSIONS_CREATE,
  API_ADMISSIONS_PAYMENT_CALCULATE_DISCOUNT,
} from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import PaymentDetails from "./PaymentDetails";
import NewPreviewAdmission from "./popup/NewPreviewAdmission";

const NewAdmissionPayment = (props) => {
  const { formInfo, validateStudentForm, handleKeyPress } = props;
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    payable_amount: "",
    amount_paid: "",
    payment_option: "",
    pay_by: "",
    payment_date: props.convertDateStringToYMD(new Date()),
    chq_num: "",
    chq_date: "",
    chq_bank: "",
    original_course_fee: "",
    total_discount: "",
    max_special_discount: "",
    special_reason: "",
    special_discount: "",
    discount_type: [],
  });
  const [showPaymentInfo, setShowPaymentInfo] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [paymentCount] = useState(0);
  const [paymentAmount] = useState(0);
  const [btnEnable, setBtnEnable] = useState(true);
  const [feesBreakup, setFeesBreakup] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    selectedOption && handlePaymentOptions(selectedOption);
  }, [
    formInfo.student_type,
    formInfo.stud_caste,
    formInfo.course_id_to,
    formInfo.course_id_from,
    selectedOption,
  ]);

  const handlePaymentOptions = (payment_type) => {
    const postData = {
      payment_type: payment_type,
      student_type: formInfo.student_type,
      stud_caste: formInfo.stud_caste,
      admission_date: formInfo.admission_date,
      course_id_to: formInfo.course_id_to,
      course_id_from: formInfo.course_id_from,
      course_code_to: formInfo.course_code_to,
      course_code_from: formInfo.course_code_from,
      special_reason: formValues.special_reason,
      special_discount: formValues.special_discount,
    };

    props.callRequest("POST", API_ADMISSIONS_PAYMENT_CALCULATE_DISCOUNT, true, postData)
      .then((res) => {
        setFormValues(prevState => {
          return {
            ...prevState,
            original_course_fee: res.data.fees.course_fees,
            total_discount: res.data.fees.total_discount,
            max_special_discount: res.data.fees.max_special_discount,
            discount_type: res.data.fees.discount_type,
          };
        });
      }).catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    selectedOption && fetchFeesBreakUp();
  }, [
    selectedOption,
    formInfo.course_id_to,
    formInfo.course_id_from,
    formValues.special_discount,
    formValues.amount_paid,
  ]);

  const fetchFeesBreakUp = () => {
    const postData = {
      payment_type: selectedOption,
      student_type: formInfo.student_type,
      stud_caste: formInfo.stud_caste,
      admission_date: formInfo.admission_date,
      course_id_to: formInfo.course_id_to,
      course_id_from: formInfo.course_id_from,
      course_code_to: formInfo.course_code_to,
      course_code_from: formInfo.course_code_from,
      special_reason: formValues.special_reason,
      special_discount: formValues.special_discount,
    };
    props.callRequest(
      "POST",
      API_ADMISSIONS_PAYMENT_CALCULATE_FEES,
      true,
      postData
    ).then((res) => {
      setFeesBreakup(res.data.fees_breakup);
    }).catch((e) => {
      console.log(e);
    });
  };

  const handlePaymentChange = (e) => {
    const payment_type = e.target.value;
    setSelectedOption(payment_type);
    setIsValidAmount(payment_type === "1");
    handlePaymentOptions(e.target.value);
    setFormValues({ ...formValues, special_reason: "", special_discount: "" });
  };

  const handleUpdatePaymentChange = (e) => {
    setFormValues({ ...formValues, amount_paid: e.target.value });
    setIsValidAmount(true);
  };

  const handleUpdateCustomPaymentChange = (e) => {
    const { original_course_fee, total_discount, special_discount } = formValues;
    const maxValue = Number(original_course_fee) - Number(total_discount) - Number(special_discount) - Number(paymentAmount);
    const value = Number(e.target.value);

    if (value > 0 && value <= maxValue) {
      setFormValues({ ...formValues, amount_paid: value });
      setIsValidAmount(true);
    } else {
      setFormValues({ ...formValues, amount_paid: "" });
      setIsValidAmount(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSpecialDiscount = (e) => {
    const { name, value } = e.target;
    if (name === "special_reason") {
      setFormValues({ ...formValues, special_reason: value });
    } else if (name === "special_discount") {
      setFormValues({ ...formValues, special_discount: value });
    }
  };

  const validateSubmit = () => {
    const { pay_by, payment_date, chq_num, chq_date, chq_bank,
      special_discount, max_special_discount } = formValues;
    const errors = {};
    let isValid = true;

    if (!validateStudentForm()) {
      isValid = false;
    }
    if (pay_by === "") {
      isValid = false;
      errors.pay_by = "Payment mode is required";
    }
    if (formInfo.admission_date !== payment_date) {
      isValid = false;
      errors.validate_admission_payment_date = "Payment date should be same as admission date";
    }
    if (pay_by === "CASH") {
      if (!props.isValidDate(payment_date)) {
        isValid = false;
        errors.validate_payment_date = "Payment date is not valid";
      }
      else if (!props.compareDate(payment_date)) {
        isValid = false;
        errors.valid_compare_pay_date = "Payment date can't be future date";
      }
    }
    if (["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(pay_by)) {
      if (chq_num === "") {
        isValid = false;
        errors.chq_num = "Cheque / DD No. is required";
      }
      if (chq_date === "") {
        isValid = false;
        errors.chq_date = "Cheque / DD Date is required";
      } else if (chq_date !== "" && !props.isValidDate(chq_date)) {
        isValid = false;
        errors.validate_chq_date = "Cheque date is not valid";
      } else if (!props.compareDate(chq_date)) {
        isValid = false;
        errors.valid_compare_chq_date = "Cheque date should be less than today";
      }
      if (chq_bank === "") {
        isValid = false;
        errors.chq_bank = "Bank name is required";
      }
      if (payment_date === "") {
        isValid = false;
        errors.payment_date = "Payment date is required";
      } else if (payment_date !== "" && !props.isValidDate(payment_date)) {
        isValid = false;
        errors.validate_payment_date = "Payment date is not valid";
      }
    }
    if (Number(special_discount) > Number(max_special_discount)) {
      isValid = false;
      errors.max_special_discount =
        `Discount can't greater than ${props.getCurrencyFormat(max_special_discount)}`;
    }
    if (!isValidAmount) {
      isValid = false;
      errors.is_installment = "Installment is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleConfirm = () => {
    if (!validateSubmit()) {
      return false;
    }
    setShowPreview(true);
  };

  const handleStudentSave = () => {
    setBtnEnable(true);
    const { total_discount, max_special_discount, special_reason, special_discount, ...rest } = formValues;
    const { original_course_fee, amount_paid } = rest;
    const payable_amount = Number(original_course_fee) - Number(total_discount) - Number(special_discount);
    const discountTypeArr = [...rest.discount_type, {
      label: special_reason,
      value: "",
      amount: Number(special_discount)
    }]

    let amountPaid = "";
    if (selectedOption === "1") {
      amountPaid = Number(payable_amount);
    } else if (amount_paid !== "") {
      amountPaid = Number(amount_paid);
    }

    const newFormState = { ...rest, discount_type: JSON.stringify(discountTypeArr), payment_option: selectedOption, payable_amount: payable_amount, amount_paid: amountPaid };
    const data = { ...newFormState, ...formInfo };

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    props
      .callRequest("POST", API_ADMISSIONS_CREATE, true, formData)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });

        setTimeout(() => {
          navigate("/admissions/view/" + res.data.ref_code);
        }, 5000);
      })
      .catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  };

  const handlePaymentModeChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, pay_by: value ? value : "" });
    if (value !== "CASH") {
      setShowPaymentInfo(value);
    } else {
      setShowPaymentInfo("CASH");
    }
  };

  const handleCheckConditon = (e) => {
    let checked = e.target.checked;
    setBtnEnable(!checked);
  };

  const displayDiscountBreakUp =
    formValues.discount_type.length > 0 ? (
      formValues.discount_type.map((discount, i) => {
        return (
          <tr key={i}>
            <td className="ps-5">
              {discount.label}
            </td>
            <td className="discount text-end">
              {props.getCurrencyFormat(discount.amount)}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="2">No discount found</td>
      </tr>
    );

  const displayFeesBreakUp = () => {
    return (
      feesBreakup.map((installment, i) => {
        return i === paymentCount ? (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <div className="d-flex align-items-center">
              <input
                type="radio"
                name="pay_amount"
                value={installment.ins_amount}
                onChange={handleUpdatePaymentChange}
              />
              <div className="ms-3">
                <h6>Installment - {i + 1}</h6>
                <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
                <small className="error">
                  {!isValidAmount && formErrors.is_installment}
                </small>
              </div>
            </div>
          </div>
        ) : (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <h6>Installment - {i + 1}</h6>
            <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
          </div>
        );
      }));
  };

  const displayCustomFeesBreakUp = () => {
    return (
      feesBreakup.map((installment, i) => {
        return i === paymentCount ? (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h6>Installment - {i + 1}</h6>
                <input
                  type="text"
                  className="form-control"
                  name="custom_pay_amount"
                  defaultValue={installment.ins_amount}
                  onBlur={handleUpdateCustomPaymentChange}
                />
                <small className="error">
                  {!isValidAmount && formErrors.is_installment}
                </small>
              </div>
            </div>
          </div>
        ) : (
          <div key={i} className="my-2 me-5 px-2 left-border">
            <h6>Installment - {i + 1}</h6>
            <span>{props.getCurrencyFormat(installment.ins_amount)}</span>
          </div>
        );
      })
    );
  };

  const feesDetails = (formValues) => {
    const {
      original_course_fee,
      max_special_discount,
      special_reason,
      special_discount,
      total_discount,
    } = formValues;

    return (
      <Table className="table table-striped table-hover">
        <tbody>
          <tr className="fw-bold">
            <td><h5 className="fw-bold  m-0 p-0">Original Course Fees</h5></td>
            <td className="text-end">
              <h5 className="fw-bold  m-0 p-0">
                {props.getCurrencyFormat(original_course_fee)}
              </h5>
            </td>
          </tr>

          <tr className="fw-bold">
            <td colSpan={2}><h5 className="fw-bold  m-0 p-0">Available Discount(s)</h5></td>
          </tr>

          {displayDiscountBreakUp}

          {
            max_special_discount > 0 && (
              <>
                <tr className="fw-bold">
                  <td><h5 className="fw-bold  m-0 p-0">Special Discount</h5></td>
                  <td className="text-end">&nbsp;</td>
                </tr>
                <tr>
                  <td className="discount">
                    <input
                      type="text"
                      className="form-control"
                      name="special_reason"
                      defaultValue={special_reason}
                      onBlur={(e) => handleSpecialDiscount(e)}
                      placeholder="Reason for special discount"
                    />
                  </td>
                  <td className="discount w-25">
                    <input
                      type="text"
                      className="form-control text-end"
                      name="special_discount"
                      defaultValue={special_discount}
                      onBlur={(e) => handleSpecialDiscount(e)}
                      placeholder={"<= " + props.getCurrencyFormat(max_special_discount)}
                      onKeyDown={handleKeyPress}
                      min={0}
                    />
                    <small className="error">
                      {Number(formValues.special_discount) > Number(formValues.max_special_discount)
                        && formErrors.max_special_discount}
                    </small>
                  </td>
                </tr>
              </>
            )
          }
          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Total Discount</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold  m-0 p-0">
                {props.getCurrencyFormat(Number(special_discount) + Number(total_discount))}
              </h5>
            </td>
          </tr>

          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Payable Amount</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold  m-0 p-0">
                {props.getCurrencyFormat(
                  original_course_fee - total_discount - special_discount
                )}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Amount Paid</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold m-0 p-0">
                {props.getCurrencyFormat(paymentAmount)}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 className="fw-bold m-0 p-0">Amount Due</h5>
            </td>
            <td className="text-end">
              <h5 className="fw-bold m-0 p-0">
                {props.getCurrencyFormat(
                  original_course_fee -
                  total_discount -
                  special_discount -
                  paymentAmount
                )}
              </h5>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <div className="payment-section">
        <ToastContainer />
        {(formInfo.course_id_to !== "" || formInfo.course_id_from !== "") && (
          <div className="card-body">
            <div className="project">
              <div className="row pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Payment Option</h5>
                </div>
                <div className="col-xl-4 col-sm-6 mt-2">
                  <input
                    type="radio"
                    name="payment_type"
                    value="1"
                    checked={selectedOption === "1"}
                    onChange={handlePaymentChange}
                  />
                  <span className="ms-2">Full Payment</span>
                </div>
                <div className="col-xl-4 col-sm-6 mt-2">
                  <input
                    type="radio"
                    name="payment_type"
                    value="2"
                    checked={selectedOption === "2"}
                    onChange={handlePaymentChange}
                  />
                  <span className="ms-2">Part Payment</span>
                </div>
                <div className="col-xl-4 col-sm-6 mt-2">
                  <input
                    type="radio"
                    name="payment_type"
                    value="3"
                    checked={selectedOption === "3"}
                    onChange={handlePaymentChange}
                  />
                  <span className="ms-2">Custom Payment</span>
                </div>
              </div>

              <div className="row border-bottom pb-3 px-5">
                {selectedOption === "1" && (
                  feesDetails(formValues)
                )}

                {selectedOption === "2" && (
                  <>
                    {feesDetails(formValues)}
                    <tr>
                      <td colSpan="2">
                        <div className="d-flex justify-content-start flex-wrap">
                          {displayFeesBreakUp()}
                        </div>
                      </td>
                    </tr>
                  </>
                )}

                {selectedOption === "3" && (
                  <>
                    {feesDetails(formValues)}
                    < tr >
                      <td colSpan="2">
                        <div className="d-flex justify-content-start flex-wrap">
                          {displayCustomFeesBreakUp()}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </div>
            </div>

            {isValidAmount ? (
              <PaymentDetails
                formValues={formValues}
                formInfo={formInfo}
                formErrors={formErrors}
                showPaymentInfo={showPaymentInfo}
                handlePaymentModeChange={handlePaymentModeChange}
                handleChange={handleChange}
                {...props}
              />
            ) : null}
          </div>
        )}
        <div className="mt-5 d-flex justify-content-start align-items-start">
          <div className="mt-1">
            <input
              type="checkbox"
              name="check_conditions"
              onChange={handleCheckConditon} className="form-check-input"
            />
          </div>
          <div className="ms-3">
            I hereby provide my voluntary consent to the Webel Franchisee Management System to use my Aadhaar/PAN/Voter ID/Driving Licence/Passport for the sole purpose of identity verification on the website. I am aware that this data will be managed in compliance with data privacy regulations. I verify the accuracy of the information I've shared with the Authorised Training Center of WBEIDC Ltd. By accessing the website, I affirm my agreement to these conditions.
          </div>
        </div>
        <div className="mt-2 text-end">
          <Button
            disabled={btnEnable ? true : false}
            className="btn btn-primary mt-2"
            onClick={() => handleConfirm()}
          >
            Confirm
          </Button>
        </div>
      </div >
      <Modal show={showPreview} centered backdrop="static" size="xl">
        <Form>
          <Modal.Header>
            <Modal.Title>Admission Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewPreviewAdmission
              formValues={formValues}
              formInfo={formInfo}
              setShowPreview={setShowPreview}
              handleStudentSave={handleStudentSave}
              paymentCount={paymentCount}
              selectedOption={selectedOption}
              paymentAmount={paymentAmount}
              showPaymentInfo={showPaymentInfo}
              feesBreakup={feesBreakup}
              {...props}
            />
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default NewAdmissionPayment;
