import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const PaymentDetails = ({
  getPaymentMode,
  formValues,
  formErrors,
  showPaymentInfo,
  handlePaymentModeChange,
  handleChange,
  isValidDate,
  compareDate,
  formInfo,
}) => {
  return (
    <div className="mt-2">
      <div className="col-xl-12 col-sm-12 mt-4">
        <h5 className="fw-bold text-muted">Payment Details</h5>
      </div>
      <Row className="mb-3 mt-3">
        <Col lg={4}>
          <Form.Group>
            <Form.Label>Payment Mode</Form.Label>
            <Form.Select
              name="pay_by"
              value={formValues.pay_by}
              onChange={handlePaymentModeChange}
            >
              {getPaymentMode().map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <small className="error">
              {formValues.pay_by === "" && formErrors.pay_by}
            </small>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="payment_date">
            <Form.Label>Payment Date</Form.Label>
            <Form.Control
              type="date"
              name="payment_date"
              value={formValues.payment_date}
              onChange={handleChange}
              max={formInfo.admission_date}
            />
            <small className="error">
              {!isValidDate(formValues.payment_date)
                ? formErrors.validate_payment_date
                : !compareDate(formValues.payment_date)
                  ? formErrors.valid_compare_pay_date :
                  formInfo.admission_date !== formValues.payment_date ? formErrors.validate_admission_payment_date : ""}
            </small>
          </Form.Group>
        </Col>
      </Row>

      {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(showPaymentInfo) && (
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="">
              <Form.Label>Cheque/DD/Ref No.</Form.Label>
              <Form.Control
                type="text"
                name="chq_num"
                value={formValues.chq_num}
                placeholder="Enter Cheque/DD Number"
                onChange={handleChange}
                autoComplete="off"
              />
              <small className="error">
                {formValues.chq_num === "" && formErrors.chq_num}
              </small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="date">
              <Form.Label>Cheque/DD/Ref Date</Form.Label>
              <Form.Control
                type="date"
                name="chq_date"
                value={formValues.chq_date}
                onChange={handleChange}
                max={formInfo.admission_date}
              />
              <small className="error">
                {formValues.chq_date === ""
                  ? formErrors.chq_date
                  : formValues.chq_date !== "" &&
                    !isValidDate(formValues.chq_date)
                    ? formErrors.validate_chq_date
                    : !compareDate(formValues.chq_date)
                      ? formErrors.valid_compare_chq_date : ""
                }
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="chq_bank"
                value={formValues.chq_bank}
                onChange={handleChange}
                placeholder="Enter Bank Name"
                autoComplete="off"
              />
              <small className="error">
                {formValues.chq_bank === "" && formErrors.chq_bank}
              </small>
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PaymentDetails;
