import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { API_BATCHES, API_BATCHES_SEARCH } from "../../config/Api";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../helpers/DataTableSettings";

const ListBatch = (props) => {

  const batchSearchOptions = [
    { value: 'Select', label: 'Select options' },
    { value: 'COURSE NAME', label: 'Diploma in Computer' },
    { value: 'FINANCIAL YEAR', label: `${props.getFinancialYear()}` }
  ];

  let center_ref = "";

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    if (user.role.role_name === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
    }
  }

  const [batchFormValues, setBatchFormValues] = useState({
    search_by: "",
    search_keyword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [batchData, setBatchData] = useState([]);
  const [showBatch, setShowBatch] = useState(false);
  const [filterText, setFilterText] = useState("");
  const searchParam = [
    "ba_batch_name",
    "ba_fy",
    "ba_start_date",
    "ba_end_date",
    "course_name",
  ];
  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [close, setClose] = useState(false);

  const navigate = useNavigate();

  const handleBatchToggle = () => setShowBatch(!showBatch);

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = () => {
    props.callRequest("GET", API_BATCHES + center_ref, true, null).then((res) => {
      setBatchData(res.data.batches);
      setLoadingIndicator(false);
    }).catch((e) => {
      console.log(e);
    });
  };

  const handleBatchFilterChange = (e) => {
    const { name, value } = e.target;
    setBatchFormValues({ ...batchFormValues, [name]: value, search_keyword: '' });
  };

  const getBatchFilterData = () => {
    const selectedOption = batchSearchOptions.find(option => option.value === batchFormValues.search_by);
    return selectedOption ? `e.g. ${selectedOption.label}` : 'Search Keyword';
  };

  const validateBatchFilter = () => {
    const { search_by, search_keyword } = batchFormValues;
    const errors = {};
    let isValid = true;

    if (search_by === "" || search_by === undefined) {
      isValid = false;
      errors.search_by = "Search By is required";
    }
    if (search_keyword === "" || search_keyword === undefined) {
      isValid = false;
      errors.search_keyword = "Search Keyword is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const searchBatch = () => {
    if (!validateBatchFilter()) {
      return false;
    }
    props.callRequest("POST", API_BATCHES_SEARCH + center_ref, true, batchFormValues).then((res) => {
      handleBatchToggle();
      setClose(true);
    }).catch((e) => {
      console.log(e);
    });
  };

  const columns = [
    {
      name: <h5>Batch Name</h5>,
      selector: (row) => row.ba_batch_name,
      sortable: true,
      cell: (row) => (
        <Link to={`/batches/view/${row.ba_ref}`}>{row.ba_batch_name}</Link>
      ),
    },
    {
      name: <h5>Financial year</h5>,
      selector: (row) => row.ba_fy,
      sortable: true,
    },
    {
      name: <h5>Start Date</h5>,
      selector: (row) => props.getFormatedDate(row.ba_start_date),
      sortable: true,
    },
    {
      name: <h5>End Date</h5>,
      selector: (row) => props.getFormatedDate(row.ba_end_date),
      sortable: true,
    },
    {
      name: <h5>Course</h5>,
      selector: (row) => row.course.course_name,
      sortable: true,
      cell: (row) => (
        <div className="d-flex">
          <Link
            to={`/courses/view/${row.course.course_ref}`}
            style={{ marginLeft: "1rem" }}
          >
            {row.course.course_name}
          </Link>
        </div>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>All Batch</h5>
          <div className="d-flex justify-content-end">
            {!close ?
              (
                <Button
                  className="link-action"
                  onClick={() => {
                    handleBatchToggle();
                    setBatchFormValues("");
                  }}
                >
                  <i className="las la-filter me-2"></i>Filter
                </Button>
              ) : (
                <Button
                  className="link-action"
                  onClick={() => {
                    setClose(false);
                    fetchBatches();
                  }}
                >
                  <i className="las la-times me-2"></i>Filter
                </Button>
              )}

            <Button
              className="link-action ms-3"
              onClick={() => navigate("/batches/new")}
            >
              New Batch
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Modal
            show={showBatch}
            onHide={handleBatchToggle}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col lg={5}>
                    <Form.Group className="mb-3" controlId="search_by">
                      <Form.Label>Search By</Form.Label>
                      <select
                        className="form-select"
                        name="search_by"
                        onChange={handleBatchFilterChange}
                        value={batchFormValues.search_by || ""}
                      >
                        {batchSearchOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.value}
                          </option>
                        ))}
                      </select>
                      <small className="error">{!batchFormValues.search_by && formErrors.search_by}</small>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="search_keyword">
                      <Form.Label>Search Keyword</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={getBatchFilterData()}
                        name="search_keyword"
                        value={batchFormValues.search_keyword || ""}
                        onChange={(event) => setBatchFormValues({ ...batchFormValues, search_keyword: event.target.value })}
                      />
                      <small className="error">
                        {!batchFormValues.search_keyword && formErrors.search_keyword}
                      </small>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleBatchToggle}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  searchBatch();
                }}
              >
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
          {batchData ? (
            <DataTable
              columns={columns}
              data={DataTableSettings.filterItems(
                batchData,
                searchParam,
                filterText
              )}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              progressPending={loadingIndicator}
              subHeader
              fixedHeaderScrollHeight="400px"
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
            />
          ) : (
            "No batch found"
          )}
        </div>
      </div>
    </div>
  );
};

export default ListBatch;
