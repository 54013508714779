import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../helpers/cropImage";
import {
  API_ADMISSIONS_SEARCH_OLD_CANDIDATE,
  API_COURSES,
  API_LISTS_FINANCIAL_YEAR,
  API_ADMISSIONS_CREATE_EXISTING,
  API_CENTERS_SET_OLD_COUNT,
} from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import OldPreviewAdmission from "./popup/OldPreviewAdmission";

function OldAdmissionForm(props) {



  const navigate = useNavigate();
  const admissionCount = localStorage.getItem("ongoing_count");
  const admission_route_flag = localStorage.getItem("admission_route_flag");

  let center_id = "";
  let center_ref = "";
  let user = "";
  let center = "";
  let new_id = "";

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    user = JSON.parse(localStorage.getItem("user"));
    center = JSON.parse(localStorage.getItem("center"));

    if (user.role.role_name === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
      center_id = center.ct_id;
    }
  }

  useEffect(() => {
    if (admission_route_flag === "1") {
      navigate("/admissions/new");
    }
  }, [admission_route_flag]);

  const initialValues = {
    finacial_year: "",
    student_type: "",
    old_reg_num: "",
    fullname: "",
    guardian_name: "",
    phone: "",
    alternate_phone: "",
    email: "",
    dob: "",
    education_qualifications: "",
    stud_edu_cert: "",
    stud_caste: "GENERAL",
    stud_caste_doc: "",
    gender: "",
    stud_present_address: "",
    stud_permanent_address: "",
    center_id: `${center_id}`,
    course_id: "",
    batch_id: "",
    stud_pic: "",
    stud_sign: "",
    id_type: "",
    id_num: "",
    stud_govt_id_path: "",
    stud_reg_no2: "",
    center_count: "",
    admission_date: props.convertDateStringToYMD(new Date()),
    total_discount: 0,
    payment_option: "",
    money_received: [
      {
        id: 0,
        pay_by: "",
        payment_date: props.convertDateStringToYMD(new Date()),
        payment_amount: "",
        chq_bank: "",
        chq_num: "",
        chq_date: props.convertDateStringToYMD(new Date()),
        recpt_num2: "",
        status: false,
      },
    ],
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [showOldRegCertNum, setShowOldRegCertNum] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [course, setCourse] = useState({
    course_data: {},
    course_pay_amt: 0,
  });
  const [selectedIDCard, setSelectedIDCard] = useState();
  const [selectedEducationCertificate, setSelectedEducationCertificate] = useState();
  const [selectedCasteCertificate, setSelectedCasteCertificate] = useState();
  const [showCasteUpload, setShowCasteUpload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);

  const [isPdf, setIspdf] = useState({
    caste: true,
    govt_id: true,
    last_edu_qualification: true,
  });

  const [btnEnable, setBtnEnable] = useState(true);

  //For Image
  const [srcImg, setSrcImg] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [resultImg, setResultImg] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  //For Signature
  const [srcSign, setSrcSign] = useState(null);
  const [cropSign, setCropSign] = useState({ x: 0, y: 0 });
  const [croppedAreaPixelsSignature, setCroppedAreaPixelsSignature] = useState(null);
  const [zoomSign, setZoomSign] = useState(1);
  const [resultSign, setResultSign] = useState(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);


  const [showPreview, setShowPreview] = useState(false);
  const toggleShowPreview = () => setShowPreview(!showPreview);

  //payment
  const handleAddMore = () => {
    if (formValues.money_received.length === 0) {
      new_id = 0;
    } else {
      const custom = formValues.money_received.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = custom.id + 1;
    }
    let new_element = {
      id: new_id,
      pay_by: "",
      payment_date: "",
      payment_amount: "",
      chq_bank: "",
      chq_num: "",
      chq_date: "",
      recpt_num2: "",
      status: false,
    };
    setFormValues({
      ...formValues,
      money_received: formValues.money_received.concat(new_element),
    });
  };

  const handlePaymentChange = (index, field, value) => {
    const updatedFormData = [...formValues.money_received];
    updatedFormData[index][field] = value;
    setFormValues({ ...formValues, money_received: updatedFormData });
  };

  const handleRemove = (index) => {
    const updatedFormData = formValues.money_received.filter(
      (item) => item.id !== index
    );
    setFormValues({ ...formValues, money_received: updatedFormData });
  };

  const handleCheckConditon = (e) => {
    let checked = e.target.checked;
    setBtnEnable(!checked);
  };


  //Old Student Count
  const [showOldStudentCount, setShowOldStudentCount] = useState(false);

  useEffect(() => {
    if (admissionCount === "1") {
      setShowOldStudentCount(true);
    } else {
      setShowOldStudentCount(false);
    }
  }, [admissionCount]);

  const handleStudentCountSave = () => {
    let isValid = true;
    let errors = {};
    if (formValues.center_count === "") {
      isValid = false;
      errors.center_count = "Student count is required";
      setFormErrors({ ...formErrors, center_count: errors.center_count });
    } else if (formValues.center_count !== "" && formValues.center_count < 1) {
      isValid = false;
      errors.center_count_less_one =
        "Student count can not be 0 or minus value";
      setFormErrors({
        ...formErrors,
        center_count_less_one: errors.center_count_less_one,
      });
    } else {
      setConfirmBox(true);
    }
  };

  const confirmStudentCountSave = () => {
    let center_temp_ref = center_ref.split("/")[1];
    let student_obj = {
      center_count: formValues.center_count,
      center_ref: center_temp_ref,
    };

    props
      .callRequest("POST", API_CENTERS_SET_OLD_COUNT, true, student_obj)
      .then((res) => {
        setShowOldStudentCount(false);
        toast.success(`Your student admission count saved successfully`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          localStorage.removeItem("ongoing_count");
          navigate("/admissions/old");
        }, 5000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchFinYearOptions();
    fetchCourseOptions();
  }, []);

  const fetchFinYearOptions = () => {
    props
      .callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null)
      .then((res) => {
        setFinYearOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
        props.showDialog("error", e.data.message, 2000);
      });
  };

  const fetchCourseOptions = () => {
    props
      .callRequest("GET", API_COURSES + center_ref, true, null)
      .then((res) => {
        setCourseOptions(res.data.courses);
      })
      .catch((e) => {
        console.log(e);
        props.showDialog("error", e.data.message, 2000);
      });
  };

  const fetchOldCandidateData = (param) => {
    props
      .callRequest(
        "GET",
        API_ADMISSIONS_SEARCH_OLD_CANDIDATE + "/" + param,
        true,
        null
      )
      .then((res) => {
        setFormValues({
          ...formValues,
          fullname: res.data.admission_info.student_info.stud_fullname,
          guardian_name:
            res.data.admission_info.student_info.stud_guardian_name,
          phone: res.data.admission_info.student_info.stud_phone,
          alternate_phone: res.data.admission_info.student_info.stud_phone2,
          email: res.data.admission_info.student_info.stud_email,
          dob: res.data.admission_info.student_info.stud_dob,
          education_qualifications:
            res.data.admission_info.student_info.stud_edu_qual,
          stud_edu_cert:
            res.data.admission_info.student_info.stud_edu_qual_path,
          stud_caste: res.data.admission_info.student_info.stud_caste,
          gender: res.data.admission_info.student_info.stud_gender,
          stud_present_address:
            res.data.admission_info.student_info.stud_present_address,
          stud_permanent_address:
            res.data.admission_info.student_info.stud_permanent_address,
          id_type: res.data.admission_info.student_info.stud_govt_id_type,
          id_num: res.data.admission_info.student_info.stud_govt_id_num,
          stud_govt_id_path:
            res.data.admission_info.student_info.stud_govt_id_path,
          center_id: res.center_id,
        });

        setShowCasteUpload(
          res.data.admission_info.student_info.stud_caste !== "GENERAL"
        );
        setShowForm(true);
      })
      .catch((e) => {
        console.log(e);
        props.showDialog("error", e.response.data.message, 2000);
        setShowForm(true);
      });
  };

  // const handleStudentTypeChange = (event) => {
  //   const { value } = event.target;
  //   setFormValues({ ...formValues, student_type: value });

  //   if (value === "0") {
  //     setShowForm(false);
  //     setShowOldRegCertNum(true);
  //   } else if (value === "1") {
  //     setShowForm(true);
  //     setShowOldRegCertNum(false);
  //   } else {
  //     setShowForm(false);
  //     setShowOldRegCertNum(false);
  //   }
  // };

  const handleEducationCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedEducationCertificate(e.target.files[0]);
        setIspdf({ ...isPdf, last_edu_qualification: true });
      } else {
        setSelectedEducationCertificate(e.target.files[0]);
        setIspdf({ ...isPdf, last_edu_qualification: false });
      }
    }
  };

  const handleIDCardChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedIDCard(e.target.files[0]);
        setIspdf({ ...isPdf, govt_id: true });
      } else {
        setSelectedIDCard(e.target.files[0]);
        setIspdf({ ...isPdf, govt_id: false });
      }
    }
  };

  const handleCasteCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedCasteCertificate(e.target.files[0]);
        setIspdf({ ...isPdf, caste: true });
      } else {
        setSelectedCasteCertificate(e.target.files[0]);
        setIspdf({ ...isPdf, caste: false });
      }
    }
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setFormValues({ ...formValues, stud_caste: value });
    setShowCasteUpload(value !== "GENERAL");
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setFormValues((formValues) => ({
        ...formValues,
        stud_permanent_address: formValues.stud_present_address,
      }));
    } else {
      setFormValues((formValues) => ({
        ...formValues,
        stud_permanent_address: "",
      }));
    }
  };

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
  };

  const handleShowSignatureModal = () => setShowSignatureModal(true);
  const handleCloseSignatureModal = () => setShowSignatureModal(false);

  const handleSignature = async (event) => {
    setSrcSign(URL.createObjectURL(event.target.files[0]));
  };

  const handleBlur = (event) => {
    if (formValues.old_reg_num !== "") {
      fetchOldCandidateData(event.target.value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "course_id") {
      const courseFound = courseOptions.find((item) => item.course_id === Number(value));
      if (courseFound) {
        setCourse({ ...course, course_data: courseFound });
      } else {
        setCourse({ ...course, course_data: { course_fees: "" } });
      }
      setFormValues({ ...formValues, course_id: value });
    } else if (name === "batch_id") {
      setFormValues({ ...formValues, batch_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateStudentSubmit = () => {
    const {
      finacial_year,
      student_type,
      old_reg_num,
      fullname,
      guardian_name,
      phone,
      dob,
      gender,
      education_qualifications,
      id_type,
      id_num,
      stud_caste,
      stud_present_address,
      stud_permanent_address,
      course_id,
      stud_reg_no2,
      admission_date,
      payment_option,
      total_discount,
      money_received,
    } = formValues;

    const errors = {};
    let isValid = true;

    let course_pay_amount = 0;
    let course_fees = 0;
    let discount_percentage = (Number(total_discount) * 100) / Number(course.course_data.course_fees);

    if (finacial_year === "") {
      isValid = false;
      errors.finacial_year = "Financial year is required";
    }
    if (student_type === 0 && old_reg_num === "") {
      isValid = false;
      errors.old_reg_num = "Old registration number is required";
    }
    if (fullname === "") {
      isValid = false;
      errors.fullname = "Student fullname is required";
    } else if (fullname !== "" && !props.checkAlphaOnly(fullname)) {
      isValid = false;
      errors.fullname_not_valid = "Valid entry only alphabets, space and dot";
    }
    if (guardian_name === "") {
      isValid = false;
      errors.guardian_name = "Father's/Husband's name is required";
    } else if (guardian_name !== "" && !props.checkAlphaOnly(guardian_name)) {
      isValid = false;
      errors.guardian_name_not_valid =
        "Valid entry only alphabets, space and dot";
    }
    if (dob === "") {
      isValid = false;
      errors.dob = "Date of Birth is required";
    } else if (dob !== "" && !props.isValidDate(dob)) {
      isValid = false;
      errors.valid_dob = "Date of Birth is not valid";
    }
    if (gender === "") {
      isValid = false;
      errors.gender = "Gender is required";
    }
    if (education_qualifications === "") {
      isValid = false;
      errors.education_qualifications =
        "Educational qualifications is required";
    }
    if (!selectedEducationCertificate) {
      isValid = false;
      errors.stud_edu_cert = "Educational Certificate upload is required";
    } else if (!isPdf.last_edu_qualification && selectedEducationCertificate) {
      isValid = false;
      errors.stud_edu_format_pdf = "Invalid file format";
    }

    if (id_type === "") {
      isValid = false;
      errors.id_type = "Government ID type is required";
    }
    if (id_num === "") {
      isValid = false;
      errors.id_num = "Government ID number is required";
    }
    if (!selectedIDCard) {
      isValid = false;
      errors.stud_govt_id_path = "Government ID card upload is required";
    } else if (selectedIDCard && !isPdf.govt_id) {
      isValid = false;
      errors.stud_govt_id_path_format_pdf = "Invalid file format";
    }

    if (stud_caste !== "GENERAL" && !selectedCasteCertificate) {
      isValid = false;
      errors.stud_caste_doc = "Caste certificate is required";
    } else if (
      stud_caste !== "GENERAL" &&
      selectedCasteCertificate &&
      !isPdf.caste
    ) {
      isValid = false;
      errors.stud_caste_format_pdf = "Invalid file format";
    }
    if (stud_present_address === "") {
      isValid = false;
      errors.stud_present_address = "Present address is required";
    }
    if (stud_permanent_address === "") {
      isValid = false;
      errors.stud_permanent_address = "Permanent address is required";
    }
    if (course_id === "") {
      isValid = false;
      errors.course_id = "Course is required";
    }
    if (stud_reg_no2 === "") {
      isValid = false;
      errors.stud_reg_no2 = "Registration number is required";
    }
    if (!resultImg) {
      isValid = false;
      errors.stud_pic = "Student photo is required";
    }
    if (!resultSign) {
      isValid = false;
      errors.stud_sign = "Student signature is required";
    }
    if (!props.isValidDate(admission_date)) {
      isValid = false;
      errors.validate_admission_date = "Admission date is not valid";
    } else if (!props.compareDate(formValues.admission_date)) {
      isValid = false;
      errors.validate_compare_admission_date = "Admission date should be less than today";
    }
    if (payment_option === "") {
      isValid = false;
      errors.payment_option = "Payment option is required";
    }
    if (Math.round(discount_percentage) > 25) {
      isValid = false;
      errors.discount_percentage = "Total discount can't be more than 25% of Course Fees";
    }
    if (phone === "") {
      isValid = false;
      errors.phone = "Phone number is required and must be 10 digit";
    }

    money_received.map((item, i) => {
      course_fees = course.course_data.course_fees - total_discount;
      course_pay_amount += Number(item.payment_amount);
      setCourse(prevState => { return { ...prevState, course_pay_amt: course_pay_amount }; });
      if (course_pay_amount > course_fees) {
        isValid = false;
        errors.course_pay_amt_err = "Please check paid amount";
      }
      if (item.pay_by === "") {
        isValid = false;
        errors.pay_by = "Payment mode is required";
      }
      if (item.pay_by === "CASH") {
        console.log(item.payment_date, 'old admin date => payment_date');
        if (item.payment_date == "") {
          isValid = false;
          errors.payment_date = "Payment date is required";
        } else if (
          item.payment_date !== "" &&
          !props.isValidDate(item.payment_date)
        ) {
          isValid = false;
          errors.validate_payment_date = "Payment date is not valid";
        } else if (
          item.payment_date !== "" &&
          !props.compareDate(item.payment_date)
        ) {
          isValid = false;
          errors.valid_compare_pay_date = "Payment date should be less than today";
        }
        if (item.recpt_num2 === "") {
          isValid = false;
          errors.recpt_num2 = "Existing MR Number is required";
        }
        if (item.payment_amount === "") {
          isValid = false;
          errors.payment_amount = "Amount is required";
        } else if (item.payment_amount !== "" && Number(item.payment_amount) === 0) {
          isValid = false;
          errors.payment_amount_zero_val = "Amount can't be zero value";
        }
        if (
          item.pay_by !== "" &&
          item.payment_date !== "" &&
          item.recpt_num2 !== "" &&
          item.payment_amount !== ""
        ) {
          const updatedFormData = [...formValues.money_received];
          let temp_state = { ...updatedFormData[item.id] };
          temp_state.status = true;
          updatedFormData[item.id] = temp_state;
          setFormValues({ ...formValues, money_received: updatedFormData });
        }
      }
      if (["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(item.pay_by)) {
        if (item.payment_date === "") {
          isValid = false;
          errors.payment_date = "Payment date is required";
        } else if (
          item.payment_date !== "" &&
          !props.isValidDate(item.payment_date)
        ) {
          isValid = false;
          errors.validate_payment_date = "Payment date is not valid";
        } else if (
          item.payment_date !== "" &&
          !props.compareDate(item.payment_date)
        ) {
          isValid = false;
          errors.valid_compare_pay_date = "Payment date should be less than today";
        }
        if (item.recpt_num2 === "") {
          isValid = false;
          errors.recpt_num2 = "Existing MR Number is required";
        }
        if (item.chq_num === "") {
          isValid = false;
          errors.chq_num = "Cheque No is required";
        }
        if (item.chq_date === "") {
          isValid = false;
          errors.chq_date = "Cheque date is required";
        } else if (item.chq_date !== "" && !props.isValidDate(item.chq_date)) {
          isValid = false;
          errors.validate_chq_date = "Cheque date is not valid";
        } else if (item.chq_date !== "" && !props.compareDate(item.chq_date)) {
          isValid = false;
          errors.validate_compare_chq_date = "Cheque date should be less than today";
        }
        if (item.chq_bank === "") {
          isValid = false;
          errors.chq_bank = "Bank Name is required";
        }
        if (item.payment_amount === "") {
          isValid = false;
          errors.payment_amount = "Amount is required";
        } else if (item.payment_amount !== "" && Number(item.payment_amount) === 0) {
          isValid = false;
          errors.payment_amount_zero_val = "Amount can't be zero value";
        }
        if (
          item.payment_date !== "" &&
          item.recpt_num2 !== "" &&
          item.chq_num !== "" &&
          item.chq_date !== "" &&
          item.chq_bank !== "" &&
          item.payment_amount !== ""
        ) {
          const updatedFormData = [...formValues.money_received];
          let temp_state = { ...updatedFormData[item.id] };
          temp_state.status = true;
          updatedFormData[item.id] = temp_state;
          setFormValues({ ...formValues, money_received: updatedFormData });
        }
      }
    });

    setFormErrors(errors);
    return isValid;
  };

  const handleConfirm = () => {
    if (!validateStudentSubmit()) {
      return false;
    }
    setShowPreview(true)
  }
  const handleStudentSave = () => {
    setBtnEnable(true);
    const formData = new FormData();
    for (let [key, value] of Object.entries(formValues)) {
      if (key === "stud_edu_cert") {
        formData.append("stud_edu_cert", selectedEducationCertificate);
      } else if (key === "stud_govt_id_path") {
        formData.append("stud_govt_id_path", selectedIDCard);
      } else if (key === "stud_caste_doc") {
        formData.append("stud_caste_doc", selectedCasteCertificate);
      } else if (key === "stud_pic") {
        formData.append("stud_pic", resultImg);
      } else if (key === "stud_sign") {
        formData.append("stud_sign", resultSign);
      } else if (key === "money_received") {
        formData.append(
          "money_received",
          JSON.stringify(formValues.money_received)
        );
      } else if (key === "student_type") {
        formData.append("student_type", 1); // New student
      } else {
        formData.append(key, value);
      }
    }

    props
      .callRequest("POST", API_ADMISSIONS_CREATE_EXISTING, true, formData)
      .then((res) => {
        if (
          res.data.balance_admission_count > 0 &&
          res.data.is_ongoing_completed === 0
        ) {
          toast.success(
            `Existing student data saved successfully. Total admission left : ${res.data.balance_admission_count}`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            }
          );
          setTimeout(() => {
            window.location.replace("/admissions/old");
          }, 5000);
        } else {
          toast.success(
            `You have successfully completed all the existing admissions`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            }
          );
          setTimeout(() => {
            localStorage.removeItem("admission_route_flag");
            navigate("/admissions/new");
          }, 5000);
        }
      })
      .catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      });
  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        srcImg,
        croppedAreaPixels,
        "profile"
      );

      setResultImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onCropCompleteSignature = useCallback(
    (croppedArea, croppedAreaPixelsSignature) => {
      setCroppedAreaPixelsSignature(croppedAreaPixelsSignature);
    },
    []
  );

  const showCroppedImageSignature = useCallback(async () => {
    try {
      const croppedImageSignature = await getCroppedImg(
        srcSign,
        croppedAreaPixelsSignature,
        "signature"
      );

      setResultSign(croppedImageSignature);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixelsSignature]);

  return (
    <>
      <Form >
        <ToastContainer />
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="finacial_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Select name="finacial_year" onChange={handleChange}>
                <option value="">Select Financial Year</option>
                {Object.keys(finYearOptions).map((key) => (
                  <option key={key} value={finYearOptions[key]}>
                    {finYearOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">
                {formValues.finacial_year === "" && formErrors.finacial_year}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="">
              <Form.Label>Admission Date</Form.Label>
              <Form.Control
                type="date"
                name="admission_date"
                value={formValues.admission_date || ""}
                onChange={handleChange}
              />
              <small className="error">
                {!props.isValidDate(formValues.admission_date)
                  ? formErrors.validate_admission_date
                  : !props.compareDate(formValues.admission_date)
                    ? formErrors.validate_compare_admission_date : ""}
              </small>
            </Form.Group>
          </Col>
          {/* <Col lg={4}>
            <Form.Group controlId="student_type">
              <Form.Label>Does the student admitted earlier?</Form.Label>
              <Form.Select
                value={formValues.student_type}
                onChange={handleStudentTypeChange}
              >
                <option value="">Select</option>
                <option value="1">No</option>
                <option value="0">Yes</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

          {/* {showOldRegCertNum && (
            <Col lg={4}>
              <Form.Group controlId="old_reg_num">
                <Form.Label>Old Registration / Certificate Number</Form.Label>
                <Form.Control
                  type="text"
                  name="old_reg_num"
                  value={formValues.old_reg_num}
                  placeholder="Enter Registration / Certificate Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="error">
                  {formValues.student_type === 0 &&
                    formValues.old_reg_num === "" &&
                    formErrors.old_reg_num}
                </small>
              </Form.Group>
            </Col>
          )} */}
        </Row>

        {/* {showForm && ( */}
        <>
          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="fullname">
                <Form.Label>Student Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullname"
                  value={formValues.fullname}
                  placeholder="Student Fullname"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="error">
                  {formValues.fullname === ""
                    ? formErrors.fullname
                    : formValues.fullname !== "" &&
                      !props.checkAlphaOnly(formValues.fullname)
                      ? formErrors.fullname_not_valid
                      : ""}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="guardian_name">
                <Form.Label> Father's/Husband's Name</Form.Label>
                <Form.Control
                  type="text"
                  name="guardian_name"
                  value={formValues.guardian_name}
                  placeholder="Father's/Husband's Name"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="error">
                  {formValues.guardian_name === ""
                    ? formErrors.guardian_name
                    : formValues.guardian_name !== "" &&
                      !props.checkAlphaOnly(formValues.guardian_name)
                      ? formErrors.guardian_name_not_valid
                      : ""}
                </small>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group controlId="dob">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formValues.dob}
                  onChange={handleChange}
                />
                <small className="error">
                  {formValues.dob === ""
                    ? formErrors.dob
                    : formValues.dob !== "" &&
                      !props.isValidDate(formValues.dob)
                      ? formErrors.valid_dob
                      : ""}
                </small>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formValues.phone}
                  placeholder="e.g. 9876543210"
                  onChange={handleChange}
                  onKeyDown={props.handleKeyPress}
                  maxLength={10}
                />
                <small className="error">
                  {formValues.phone === "" && formErrors.phone}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="alternate_phone">
                <Form.Label>Alternate Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="alternate_phone"
                  value={formValues.alternate_phone}
                  placeholder="e.g. 9876543210"
                  onChange={handleChange}
                  onKeyDown={props.handleKeyPress}
                  maxLength={10}
                />
                <small className="error">
                  {formValues.alternate_phone === "" &&
                    formErrors.alternate_phone}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="email">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={formValues.email}
                  placeholder="Enter Email Address"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="gender">
                <Form.Label>Gender</Form.Label>
                <select
                  className="form-select"
                  aria-label="Select Gender"
                  name="gender"
                  value={formValues.gender}
                  onChange={handleChange}
                >
                  {props.getGender().map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formValues.gender === "" && formErrors.gender}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="education_qualifications">
                <Form.Label> Last Educational Qualification</Form.Label>
                <Form.Control
                  type="text"
                  name="education_qualifications"
                  value={formValues.education_qualifications}
                  placeholder="Enter Educational Qualification"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="error">
                  {formValues.education_qualifications === "" &&
                    formErrors.education_qualifications}
                </small>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group controlId="stud_edu_cert">
                <Form.Label> Upload Last Qualification Certificate</Form.Label>
                <Form.Control
                  type="file"
                  name="stud_edu_cert"
                  onChange={handleEducationCertificateChange}
                  accept=".pdf"
                />
                <small className="error">
                  {!selectedEducationCertificate
                    ? formErrors.stud_edu_cert
                    : selectedEducationCertificate &&
                      !isPdf.last_edu_qualification
                      ? formErrors.stud_edu_format_pdf
                      : ""}
                </small>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="id_type">
                <Form.Label>Government ID</Form.Label>
                <select
                  className="form-select"
                  aria-label="Select ID Type"
                  name="id_type"
                  value={formValues.id_type}
                  onChange={handleChange}
                >
                  {props.getGovId().map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formValues.id_type === "" && formErrors.id_type}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="id_num">
                <Form.Label>Government ID Number</Form.Label>
                <Form.Control
                  type="text"
                  name="id_num"
                  placeholder="Govt. ID Number"
                  value={formValues.id_num}
                  onChange={handleChange}
                />
                <small className="error">
                  {formValues.id_num === "" && formErrors.id_num}
                </small>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="stud_govt_id_path">
                <Form.Label>Upload Government ID</Form.Label>
                <br />
                <Form.Control
                  type="file"
                  name="stud_govt_id_path"
                  onChange={handleIDCardChange}
                  accept=".pdf"
                />
                <small className="error">
                  {!selectedIDCard
                    ? formErrors.stud_govt_id_path
                    : selectedIDCard && !isPdf.govt_id
                      ? formErrors.stud_govt_id_path_format_pdf
                      : ""}
                </small>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="stud_caste">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  value={formValues.stud_caste}
                  onChange={handleCategoryChange}
                >
                  {props.getCategory().map((value, i) => (
                    <option key={i} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {showCasteUpload && (
              <Col lg={4}>
                <Form.Group controlId="stud_caste_doc">
                  <Form.Label>Upload Caste Certificate</Form.Label>
                  <Form.Control
                    type="file"
                    name="stud_caste_doc"
                    onChange={handleCasteCertificateChange}
                    accept=".pdf"
                  />
                  <small className="error">
                    {formValues.stud_caste !== "GENERAL" &&
                      !selectedCasteCertificate
                      ? formErrors.stud_caste_doc
                      : formValues.stud_caste !== "GENERAL" &&
                        selectedCasteCertificate &&
                        !isPdf.caste
                        ? formErrors.stud_caste_format_pdf
                        : ""}
                  </small>
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row className="mb-3">
            <Col lg={6}>
              <Form.Group controlId="stud_present_address">
                <Form.Label>Current Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="stud_present_address"
                  placeholder="Enter present address"
                  value={formValues.stud_present_address}
                  onChange={handleChange}
                />
                <small className="error">
                  {formValues.stud_present_address === "" &&
                    formErrors.stud_present_address}
                </small>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="stud_permanent_address">
                <div className="d-flex justify-content-between">
                  <Form.Label>Permanent Address</Form.Label>
                  <div className="d-flex justify-content-end">
                    <Form.Check
                      id="same-as-current-address"
                      type="checkbox"
                      name="same-as-current-address"
                      className="me-2"
                      onChange={handleCheckboxChange}
                    />
                    <Form.Label className="fw-normal">
                      Same as Current Address
                    </Form.Label>
                  </div>
                </div>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="stud_permanent_address"
                  placeholder="Enter permanent address"
                  value={formValues.stud_permanent_address}
                  onChange={handleChange}
                />
                <small className="error">
                  {formValues.stud_permanent_address === "" &&
                    formErrors.stud_permanent_address}
                </small>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6}>
              <Form.Group controlId="stud_pic">
                <Form.Label>Upload Photo</Form.Label>
                <div className="preview-container">
                  <div className="img-preview">
                    {resultImg && (
                      <div>
                        <img src={resultImg} alt="cropped" />
                      </div>
                    )}
                  </div>
                  <div className="ms-3">
                    <Button
                      className="btn btn-primary"
                      onClick={handleShowImageModal}
                    >
                      Choose Photo
                    </Button>
                  </div>
                </div>
                <small className="error">
                  {!resultImg && formErrors.stud_pic}
                </small>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="stud_sign">
                <Form.Label>Upload Signature</Form.Label>
                <div className="preview-container">
                  <div className="sign-preview">
                    {resultSign && (
                      <div>
                        <img src={resultSign} alt="cropped" />
                      </div>
                    )}
                  </div>
                  <div className="ms-3">
                    <Button
                      className="btn btn-primary"
                      onClick={handleShowSignatureModal}
                    >
                      Choose Signature
                    </Button>
                  </div>
                </div>
                <small className="error">
                  {!resultSign && formErrors.stud_sign}
                </small>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="course_id">
                <Form.Label>Course</Form.Label>
                <Form.Select name="course_id" onChange={handleChange}>
                  <option value="">Select Course</option>
                  {courseOptions.map((value) => (
                    <option key={value.course_ref} value={value.course_id}>
                      {value.course_name}
                    </option>
                  ))}
                </Form.Select>
                <small className="error">
                  {formValues.course_id === "" && formErrors.course_id}
                </small>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group controlId="course_fee">
                <Form.Label>Course Fees</Form.Label>
                <Form.Control
                  type="text"
                  name="course_fee"
                  value={course.course_data.course_fees}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group controlId="stud_reg_no2">
                <Form.Label>Existing Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="stud_reg_no2"
                  placeholder="Existing Registration Number"
                  value={formValues.stud_reg_no2}
                  onChange={handleChange}
                />
                <small className="error">
                  {formValues.stud_reg_no2 === "" && formErrors.stud_reg_no2}
                </small>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <Form.Group controlId="payment_option">
                <Form.Label>Payment Option</Form.Label>
                <Form.Select
                  name="payment_option"
                  value={formValues.payment_option}
                  onChange={handleChange}
                >
                  {props.getPayment().map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <small className="error">
                  {formValues.payment_option === "" &&
                    formErrors.payment_option}
                </small>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group controlId="">
                <Form.Label>Total Discount Amount (Rs.)</Form.Label>
                <Form.Control
                  type="number"
                  name="total_discount"
                  value={formValues.total_discount}
                  onChange={handleChange}
                  min={0}
                />
                <small className="error">
                  {formErrors.discount_percentage}
                </small>
              </Form.Group>
            </Col>


            <Col lg={4}>
              <Form.Group>
                <Form.Control
                  id="center_id"
                  type="hidden"
                  name="center_id"
                  value={formValues.center_id}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="row pb-3">
            <div className="col-xl-12 col-sm-12 mt-4">
              <h5 className="fw-bold text-muted">Money Receipt Details</h5>
            </div>
          </div>
          <div className="mb-3">
            {formValues.money_received.map((data, index) => (
              <div key={data.id}>
                <Row className="mb-3">
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Select
                        name="pay_by"
                        value={data.pay_by}
                        onChange={(e) =>
                          handlePaymentChange(
                            data.id,
                            "pay_by",
                            e.target.value
                          )
                        }
                      >
                        {props.getPaymentMode().map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                      <small className="error">
                        {!data.status && data.pay_by === ""
                          ? formErrors.pay_by
                          : ""}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Payment Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="payment_date"
                        value={data.payment_date || ""}
                        onChange={(e) =>
                          handlePaymentChange(
                            data.id,
                            "payment_date",
                            e.target.value
                          )
                        }
                      />
                      <small className="error">
                        {data.payment_date === "" ? formErrors.payment_date :
                          !props.isValidDate(data.payment_date)
                            ? formErrors.validate_payment_date
                            : !props.compareDate(data.payment_date) ? formErrors.valid_compare_pay_date : ""}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Existing MR Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="recpt_num2"
                        value={data.recpt_num2}
                        placeholder="Enter Money Receipt Number"
                        onChange={(e) =>
                          handlePaymentChange(
                            data.id,
                            "recpt_num2",
                            e.target.value
                          )
                        }
                        autoComplete="off"
                      />
                      <small className="error">
                        {!data.status && data.recpt_num2 === ""
                          ? formErrors.recpt_num2
                          : ""}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Amount Paid</Form.Label>
                      <Form.Control
                        type="number"
                        name="payment_amount"
                        value={data.payment_amount}
                        min={0}
                        onChange={(e) =>
                          handlePaymentChange(
                            data.id,
                            "payment_amount",
                            e.target.value
                          )
                        }
                      />
                      <small className="error">
                        {!data.status && data.payment_amount === ""
                          ? formErrors.payment_amount
                          : data.payment_amount !== "" &&
                            Number(data.payment_amount) === 0
                            ? formErrors.payment_amount_zero_val
                            : ""}
                      </small>
                      <small className="error">
                        {course.course_pay_amt >
                          (course.course_data.course_fees - formValues.total_discount)
                          && formErrors.course_pay_amt_err}
                      </small>
                    </Form.Group>
                  </Col>

                  <Col lg={1}>
                    {index > 0 && (
                      <div className="text-end mt-3 pt-3">
                        <Link
                          className="text-danger"
                          onClick={() => handleRemove(data.id)}
                        >
                          <i className="las la-trash"></i>
                        </Link>
                      </div>
                    )}
                  </Col>
                </Row>

                {["CHEQUE", "DEMAND DRAFT", "UPI", "NET BANKING"].includes(data.pay_by) && (
                  <Row className="mb-3">
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label>Cheque/DD/Ref. No</Form.Label>
                        <Form.Control
                          type="number"
                          name="chq_num"
                          value={data.chq_num}
                          placeholder="Enter Cheque No."
                          onChange={(e) =>
                            handlePaymentChange(
                              data.id,
                              "chq_num",
                              e.target.value
                            )
                          }
                        />
                        <small className="error">
                          {!data.status && data.chq_num === ""
                            ? formErrors.chq_num
                            : ""}
                        </small>
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label>Cheque/DD Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="chq_date"
                          value={data.chq_date}
                          onChange={(e) =>
                            handlePaymentChange(
                              data.id,
                              "chq_date",
                              e.target.value
                            )
                          }
                        />
                        <small className="error">
                          {!data.status && data.chq_date === ""
                            ? formErrors.chq_date
                            : data.chq_date !== "" &&
                              !props.isValidDate(data.chq_date)
                              ? formErrors.validate_chq_date
                              : formValues.chq_date !== "" && !props.compareDate(data.chq_date) ? formErrors.validate_compare_chq_date : ""}
                        </small>
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="chq_bank"
                          value={data.chq_bank}
                          placeholder="Enter Bank Name"
                          onChange={(e) =>
                            handlePaymentChange(
                              data.id,
                              "chq_bank",
                              e.target.value
                            )
                          }
                        />
                        <small className="error">
                          {!data.status && data.chq_bank === ""
                            ? formErrors.chq_bank
                            : ""}
                        </small>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
            <div className="d-flex justify-content-start">
              <Button
                variant="success"
                size="sm"
                onClick={handleAddMore}
                style={{ marginTop: "-0.3rem" }}
              >
                <i className="las la-plus"></i> Add More
              </Button>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-start align-items-start">
            <div className="mt-1">
              <input
                type="checkbox"
                name="check_conditions"
                onChange={handleCheckConditon} className="form-check-input"
              />
            </div>
            <div className="ms-3">
              I hereby provide my voluntary consent to the Webel Franchisee Management System to use my Aadhaar/PAN/Voter ID/Driving Licence/Passport for the sole purpose of identity verification on the website. I am aware that this data will be managed in compliance with data privacy regulations. I verify the accuracy of the information I've shared with the Authorised Training Center of WBEIDC Ltd. By accessing the website, I affirm my agreement to these conditions.
            </div>
          </div>

          <div className="mt-2 text-end">
            <Button
              disabled={btnEnable ? true : false}
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => handleConfirm()}
            >
              Confirm
            </Button>

          </div>
        </>
        {/* )} */}
      </Form>

      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Form>
          <Modal.Header>
            <Modal.Title>Select Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="file"
              name="stud_pic"
              onChange={handleImage}
              accept=".jpg,.png"
            />
            <br />
            <div>
              {srcImg && (
                <div
                  style={{
                    height: "200px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Cropper
                    image={srcImg}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cropButton"
              onClick={() => {
                showCroppedImage();
                handleCloseImageModal();
              }}
            >
              Crop
            </Button>
            <Button variant="secondary" onClick={handleCloseImageModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSignatureModal}
        onHide={handleCloseSignatureModal}
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Select Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="file"
              name="stud_sign"
              onChange={handleSignature}
              accept=".jpg,.png"
            />
            <br />
            <div>
              {srcSign && (
                <div
                  style={{
                    height: "200px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Cropper
                    image={srcSign}
                    crop={cropSign}
                    zoom={zoomSign}
                    aspect={30 / 7}
                    onCropChange={setCropSign}
                    onCropComplete={onCropCompleteSignature}
                    onZoomChange={setZoomSign}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cropButton"
              onClick={() => {
                showCroppedImageSignature();
                handleCloseSignatureModal();
              }}
            >
              Crop
            </Button>
            <Button variant="secondary" onClick={handleCloseSignatureModal}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showOldStudentCount} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>Enter Old Student Admission Count</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmBox ? (
            <p>
              Please confirm that your center currently has a total of{" "}
              <span className="fw-bold">{formValues.center_count}</span>{" "}
              admitted candidates starting from April 1, 2023. Once you submit
              this data, you will not be able to add any new candidate
              information in the future. If you wish to modify the number,
              please select the <span className="fw-bold">"Cancel"</span>{" "}
              button. Otherwise, click the{" "}
              <span className="fw-bold">"Save & Proceed"</span> button.
            </p>
          ) : (
            <>
              <Form.Control
                type="number"
                name="center_count"
                value={formValues.center_count}
                onChange={handleChange}
                placeholder="Enter Old Student Number"
                autoComplete="off"
                min={0}
              />
              <small className="error">
                {formValues.center_count === ""
                  ? formErrors.center_count
                  : formValues.center_count !== "" &&
                    formValues.center_count < 1
                    ? formErrors.center_count_less_one
                    : ""}
              </small>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {confirmBox ? (
            <>
              <Button variant="secondary" onClick={() => setConfirmBox(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={confirmStudentCountSave}>
                Save & Proceed
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleStudentCountSave}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showPreview} onHide={toggleShowPreview} centered backdrop="static" size="xl">
        <Form>
          <Modal.Header>
            <Modal.Title>Admission Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OldPreviewAdmission
              formValues={formValues}
              setShowPreview={setShowPreview}
              handleStudentSave={handleStudentSave}
              courseOptions={courseOptions}
              resultImg={resultImg}
              resultSign={resultSign}
              selectedEducationCertificate={selectedEducationCertificate}
              selectedIDCard={selectedIDCard}
              selectedCasteCertificate={selectedCasteCertificate}
              showCasteUpload={showCasteUpload}
              {...props}
            />
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

export default OldAdmissionForm;
