import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_LISTS_CENTER, API_USERS_UPDATE, API_USERS_VIEW } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";

function EditUser(props) {
    const { userRef } = props;
    const navigate = useNavigate();

    const getRoleOption = [
        { label: "Select Role", value: "" },
        { label: "Super Admin", value: "1" },
        { label: "Management", value: "2" },
        { label: "Operation Admin", value: "3" },
        { label: "Data Entry Operator", value: "4" },
        { label: "Study Center", value: "5" },
    ];

    const initialValues = {
        user_ref: "",
        user_name: "",
        fullname: "",
        email: "",
        phone: "",
        u_role_id: "",
        alternate_email: "",
        alternate_phone: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [isCenterOptionsVisible, setIsCenterOptionsVisible] = useState(false);
    const [btnEnable, setBtnEnable] = useState(false);

    useEffect(() => {
        fetchCenterOptions();
        fetchUser();
    }, []);

    const fetchCenterOptions = () => {
        props.callRequest("GET", API_LISTS_CENTER, true, null).then((res) => {
            setCenterOptions(res.data.items);
        }).catch((e) => {
            console.log(e);
        });
    };

    const fetchUser = () => {
        props.callRequest("GET", API_USERS_VIEW + '/' + userRef, true, null).then((res) => {
            setFormValues({
                ...formValues,
                user_ref: res.data.user.u_ref,
                user_name: res.data.user.u_username,
                fullname: res.data.user.u_fullname,
                phone: res.data.user.u_phone,
                email: res.data.user.u_email,
                u_role_id: res.data.user.u_role_id,
            });

            if (res.data.user.u_role_id === 5) {
                setIsCenterOptionsVisible(true);
            }
        }).catch((e) => {
            console.log(e);
        });
    };

    const handleUserChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleUserRoleChange = (event) => {
        const { value } = event.target;
        setFormValues({ ...formValues, u_role_id: value });
        setIsCenterOptionsVisible(value === "5");
    };

    const validateSubmit = () => {
        const { fullname, phone, email, u_role_id, alternate_email } = formValues;
        const errors = {};
        let isValid = true;

        if (fullname === "") {
            isValid = false;
            errors.fullname = "Fullname is required";
        } else if (fullname !== "" && !props.checkAlphaOnly(fullname)) {
            isValid = false;
            errors.fullname_not_valid = "Valid entry only alphabets, space and dot";
        }
        if (phone === "") {
            isValid = false;
            errors.phone = "Phone number is required";
        }
        if (email === "") {
            isValid = false;
            errors.email = "Email address is required";
        } else if (email !== "" && !props.validateEmail(email)) {
            isValid = false;
            errors.email_not_valid = "Email is not valid";
        }
        if (alternate_email !== "" && !props.validateEmail(alternate_email)) {
            isValid = false;
            errors.alternate_email_not_valid = "Alternate email is not valid";
        }
        if (u_role_id === "") {
            isValid = false;
            errors.u_role_id = "Role is required";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateSubmit()) {
            return false;
        }
        setBtnEnable(true);
        props.callRequest("POST", API_USERS_UPDATE, true, formValues).then((res) => {
            toast.success(`${res.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
            });
            setTimeout(() => {
                navigate("/users");
            }, 5000);

        }).catch((e) => {
            setBtnEnable(false);
            console.log(e);
        });
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <ToastContainer />
                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group controlId="u_role_id">
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                name="u_role_id"
                                value={formValues.u_role_id ?? ""}
                                onChange={handleUserRoleChange}
                                className="form-select"
                            >
                                {getRoleOption.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                            <small className="error">
                                {formValues.u_role_id === "" && formErrors.u_role_id}
                            </small>
                        </Form.Group>
                    </Col>

                    {isCenterOptionsVisible && (
                        <Col lg={8}>
                            <Form.Group controlId="u_ct_ref">
                                <Form.Label>Center</Form.Label>
                                <Form.Select
                                    className="form-select"
                                    name="u_ct_ref"
                                    value={formValues.u_ct_ref ?? ""}
                                    onChange={handleUserChange}
                                >
                                    <option value="">Select Center</option>
                                    {Object.keys(centerOptions).map((key) => (
                                        <option key={key} value={key}>
                                            {centerOptions[key]}
                                        </option>
                                    ))}
                                </Form.Select>
                                <small className="error">
                                    {formValues.u_ct_ref === "" && formErrors.u_ct_ref}
                                </small>
                            </Form.Group>
                        </Col>
                    )}
                    <Col lg={4}>
                        <Form.Group controlId="user_name">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                name="user_name"
                                type="text"
                                value={formValues.user_name ?? ""}
                                disabled
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group controlId="fullname">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                name="fullname"
                                type="text"
                                value={formValues.fullname ?? ""}
                                placeholder="Enter full name"
                                onChange={handleUserChange}
                            ></Form.Control>
                            <small className="error">
                                {formValues.fullname === ""
                                    ? formErrors.fullname
                                    : formValues.fullname !== "" &&
                                        !props.checkAlphaOnly(formValues.fullname)
                                        ? formErrors.fullname_not_valid
                                        : ""}
                            </small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={formValues.phone ?? ""}
                                placeholder="Enter Phone number"
                                onKeyDown={props.handleKeyPress}
                                onChange={handleUserChange}
                                maxLength={10}
                            />
                            <small className="error">
                                {formValues.phone === "" && formErrors.phone}
                            </small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="alternate_phone">
                            <Form.Label> Alternate Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="alternate_phone"
                                value={formValues.alternate_phone ?? ""}
                                placeholder="Enter alternate phone number"
                                onChange={handleUserChange}
                                onKeyDown={props.handleKeyPress}
                                maxLength={10}
                            />
                            <small className="error">
                                {formValues.alternate_phone === "" && formErrors.alternate_phone}
                            </small>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={4}>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                value={formValues.email ?? ""}
                                placeholder="Enter Email Address"
                                onChange={handleUserChange}
                            />
                            <small className="error">
                                {formValues.email === ""
                                    ? formErrors.email
                                    : formValues.email !== "" &&
                                        !props.validateEmail(formValues.email)
                                        ? formErrors.email_not_valid
                                        : ""}
                            </small>
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="alternate_email">
                            <Form.Label> Alternate Email </Form.Label>
                            <Form.Control
                                type="text"
                                name="alternate_email"
                                value={formValues.alternate_email ?? ""}
                                placeholder="Enter alternate Email Address"
                                onChange={handleUserChange}
                            />
                            <small className="error">
                                {formValues.alternate_email !== "" &&
                                    !props.validateEmail(formValues.alternate_email)
                                    && formErrors.alternate_email_not_valid
                                }
                            </small>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="mt-2 text-end">
                    <Button disabled={btnEnable ? true : false}
                        type="submit" className="btn btn-primary mt-2">
                        Submit
                    </Button>
                    <input type="hidden" name="user_ref" value={formValues.user_ref} />
                </div>
            </Form>
        </>
    );
}

export default EditUser;
