import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const OldAdmissionData = () => {
  return (
    <>
      <Row className="mb-4">
        <Col lg={6}>
          <Card style={{ width: "20rem" }}>
            <Card.Body>
              <div className="d-flex">
                <i
                  className="las la-download me-2"
                  style={{ fontSize: "2rem" }}
                ></i>
                <Card.Title>Download Admission Template</Card.Title>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card.Link href="" target="_blank">
                  Download Link
                </Card.Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card style={{ width: "20rem" }}>
            <Card.Body>
              <div className="d-flex">
                <i
                  className="las la-download me-2"
                  style={{ fontSize: "2rem" }}
                ></i>
                <Card.Title>Download Payment Template</Card.Title>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card.Link href="#">download Link</Card.Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <Card style={{ width: "20rem" }}>
            <Card.Body>
              <div className="d-flex">
                <i
                  className="las la-upload me-2"
                  style={{ fontSize: "2rem" }}
                ></i>
                <Card.Title>Upload Admission Details</Card.Title>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card.Link href="#">Upload Link</Card.Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card style={{ width: "20rem" }}>
            <Card.Body>
              <div className="d-flex">
                <i
                  className="las la-upload me-2"
                  style={{ fontSize: "2rem" }}
                ></i>
                <Card.Title>Upload payment Details</Card.Title>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card.Link href="#">Upload Link</Card.Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OldAdmissionData;
