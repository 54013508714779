import { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_USERS } from "../../config/Api";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../helpers/DataTableSettings";
import RoleBasedButton from "../shared/RoleBasedButton";

function ListUser(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_role = user.role.role_name;
  const [userData, setUserData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const searchParam = [
    "u_fullname",
    "u_username",
    "u_phone",
    "role_name",
  ];
  const [loadingIndicator, setLoadingIndicator] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    props.callRequest("GET", API_USERS, true, null).then((res) => {
      setUserData(res.data.users);
      setLoadingIndicator(false);
    }).catch((e) => {
      console.log(e);
    });
  };

  const columnsArr = [
    {
      name: <h5>Full name</h5>,
      selector: (row) => row.u_fullname,
      sortable: true,
      cell: (row) => (
        <Link to={`/users/view/${row.u_ref}`}>{row.u_fullname}</Link>
      ),
    },
    {
      name: <h5>User name</h5>,
      selector: (row) => row.u_username,
      sortable: true,
    },
    {
      name: <h5>Phone No. & Email id</h5>,
      selector: (row) => row.u_phone,
      sortable: false,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span>{row.u_phone}</span>
          <span>{row.u_email}</span>
        </div>
      ),
    },
    {
      name: <h5>Role</h5>,
      selector: (row) => row.role.role_name,
      sortable: true,
    },
    {
      name: <h5>Action</h5>,
      width: "10%",
      cell: (row) => (
        <RoleBasedButton
          type="edit_user"
          url={`/users/edit/${row.u_ref}`}
          user_role={user_role}
        />
      ),
    },
  ];

  const columns = user_role === "SUPERADMIN" || user_role === "ADMIN"
    ? columnsArr : columnsArr.slice(0, -1);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>Available Users</h5>
          <RoleBasedButton
            type="add_user"
            url={"/users/new"}
            user_role={user_role}
          />
        </div>
        <div className="card-body">
          {userData ? (
            <DataTable
              columns={columns}
              data={DataTableSettings.filterItems(
                userData,
                searchParam,
                filterText
              )}
              pagination
              paginationPerPage={DataTableSettings.paginationPerPage}
              paginationRowsPerPageOptions={
                DataTableSettings.paginationRowsPerPageOptions
              }
              progressPending={loadingIndicator}
              subHeader
              fixedHeaderScrollHeight="400px"
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
            />
          ) : (
            "No user found"
          )}
        </div>
      </div>
    </>
  );
}

export default ListUser;
