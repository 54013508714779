import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { API_WEB_DOMAIN } from "../../config/Api";

function Topbar(props) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { showTopbar } = props;
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
      return;
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      setUserInfo(user);
    }
  }, [isAuthenticated, navigate]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_expired_on");
    localStorage.removeItem("user");
    localStorage.removeItem("center");
    setUserInfo(null);
    navigate("/");
  };

  return (
    <header
      className={showTopbar ? "pc-header mob-header-active" : "pc-header"}
    >
      {userInfo ? (
        <div className="header-wrapper">
          <div className="me-auto my-auto d-block">
            <h1>Webel Computer Training Center</h1>
          </div>

          <div className="ms-auto">
            <ul className="list-unstyled">
              <li className="dropdown pc-h-item">
                <a
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={API_WEB_DOMAIN + userInfo.u_pic}
                    alt={userInfo ? userInfo?.u_fullname : "user logo"}
                    className="user-avtar"
                  />
                  <span>
                    <span className="user-name">
                      {userInfo?.u_fullname}
                      <br />
                      <span className="user-desc">
                        Logged in as: {userInfo?.role.role_name}
                      </span>
                    </span>
                  </span>
                </a>

                <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                  <Link
                    to={`/users/view/${userInfo?.u_ref}`}
                    className="dropdown-item"
                  >
                    <i className="las la-user"></i>
                    <span>Profile</span>
                  </Link>

                  <Link to="/change-password" className="dropdown-item">
                    <i className="las la-lock"></i>
                    <span>Change Password</span>
                  </Link>

                  <Link
                    to={API_WEB_DOMAIN + "upload/Help-Guide.pdf"}
                    target="_blank"
                    className="dropdown-item"
                  >
                    <i className="las la-question"></i>
                    <span>Help</span>
                  </Link>

                  <a href="#" onClick={logout} className="dropdown-item">
                    <i className="las la-sign-out-alt"></i>
                    <span>Logout</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </header>
  );
}

export default Topbar;
