import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  API_LISTS_CENTER,
  API_LISTS_FINANCIAL_YEAR,
  API_REPORTS_CENTER_WISE_ROYALTY,
} from "../../config/Api";

function ViewCenterRoyaltyReport(props) {
  const [loader, setLoader] = useState(false);

  const [formValues, setFormValues] = useState({
    financial_year: "",
    month: "",
    center_id: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [centerOptions, setCenterOptions] = useState([]);
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const monthOptions = Object.values(props.getMonth());

  useEffect(() => {
    fetchCenterOptions();
    fetchFinYearOptions();
  }, []);

  const fetchCenterOptions = () => {
    props
      .callRequest("GET", API_LISTS_CENTER, true, null)
      .then((res) => {
        setCenterOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchFinYearOptions = () => {
    props
      .callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null)
      .then((res) => {
        setFinYearOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "financial_year") {
      setFormValues({ ...formValues, financial_year: value });
    } else if (name === "month") {
      setFormValues({ ...formValues, month: value });
    } else if (name === "center_id") {
      setFormValues({ ...formValues, center_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateSubmit = () => {
    const { financial_year, month, center_id } = formValues;
    const errors = {};
    let isValid = true;

    if (financial_year === "") {
      isValid = false;
      errors.financial_year = "Please select the financial year";
    }

    if (month === "") {
      isValid = false;
      errors.month = "Please select the month";
    }

    if (center_id === "") {
      isValid = false;
      errors.center_id = "Please select the franchisee";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return false;
    }
    setLoader(true);
    setSearchData([]);
    props
      .callRequest("POST", API_REPORTS_CENTER_WISE_ROYALTY, true, formValues)
      .then((res) => {
        setSearchData(res.data);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (
      formValues.financial_year === "" ||
      formValues.month === "" ||
      formValues.center_id === ""
    ) {
      setSearchData([]);
    }
  }, [formValues]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="border-bottom pb-3">
          <Col lg={3}>
            <Form.Group controlId="financial_year">
              <Form.Label>Financial Year</Form.Label>
              <Form.Select name="financial_year" onChange={handleChange}>
                <option value="">Select</option>
                {Object.keys(finYearOptions).map((key) => (
                  <option key={key} value={finYearOptions[key]}>
                    {finYearOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.financial_year === "" && formErrors.financial_year}</small>
            </Form.Group>
          </Col>

          <Col lg={3}>
            <Form.Group controlId="month">
              <Form.Label>Month</Form.Label>
              <Form.Select name="month" onChange={handleChange}>
                <option value="">Select</option>
                {monthOptions.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.month === "" && formErrors.month}</small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="center_id">
              <Form.Label>Franchisee</Form.Label>
              <Form.Select name="center_id" onChange={handleChange}>
                <option value="">Select Franchisee</option>
                {Object.keys(centerOptions).map((key) => (
                  <option key={key} value={key}>
                    {centerOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formValues.center_id === "" && formErrors.center_id}</small>
            </Form.Group>
          </Col>

          <Col lg={2} className="text-end">
            <div className="mt-3 pt-1"></div>
            <Button type="submit" className="btn btn-primary mt-2">
              Search
            </Button>
          </Col>
        </Row>

        <Row className="border-bottom py-3">
          <Col lg={12}>
            {loader && (
              <img src="/assets/images/loader.gif" alt="" />
            )}
            {searchData.count > 0 && searchData ? (
              <div className="">
                <h6>
                  Total <strong>{searchData.count}</strong> record(s) found
                </h6>
                {/* <Link to={searchData.file_path} target="_blank">
                  <div className="d-flex align-items-center">
                    <i className="las la-file-download me-2"></i> Download in
                    excel
                  </div>
                </Link> */}

                <embed src={searchData.file_path} type="application/pdf" width="100%" height="600px" />
              </div>
            ) : (
              null
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ViewCenterRoyaltyReport;
