import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import LoginScreen from "../screens/authentication/LoginScreen";
import ChangePasswordScreen from "../screens/authentication/ChangePasswordScreen";
import DashboardScreen from "../screens/DashboardScreen";

import StudyCenterScreen from "../screens/studycenter/StudyCenterScreen";
import NewStudyCenterScreen from "../screens/studycenter/NewStudyCenterScreen";
import ViewStudyCenterScreen from "../screens/studycenter/ViewStudyCenterScreen";
import EditStudyCenterScreen from "../screens/studycenter/EditStudyCenterScreen";

import AdmissionScreen from "../screens/admission/AdmissionScreen";
import NewAdmissionScreen from "../screens/admission/NewAdmissionScreen";
import ViewAdmissionScreen from "../screens/admission/ViewAdmissionScreen";
import PaymentAdmissionScreen from "../screens/admission/PaymentAdmissionScreen";
import EditAdmissionScreen from "../screens/admission/EditAdmissionScreen";
import BatchAssignScreen from "../screens/admission/BatchAssignScreen";
import OldAdmissionScreen from "../screens/admission/OldAdmissionScreen";
import OldAdmissionDataScreen from "../screens/admission/OldAdmissionDataScreen";

import CourseScreen from "../screens/course/CourseScreen";
import NewCourseScreen from "../screens/course/NewCourseScreen";
import ViewCourseScreen from "../screens/course/ViewCourseScreen";
import EditCourseScreen from "../screens/course/EditCourseScreen";
import NewCourseFeesScreen from "../screens/course/NewCourseFeesScreen";
import BatchViewScreen from "../screens/course/BatchViewScreen";

import UserScreen from "../screens/user/ListUserScreen";
import NewUserScreen from "../screens/user/NewUserScreen";
import ViewUserScreen from "../screens/user/ViewUserScreen";
import EditUserScreen from "../screens/user/EditUserScreen";

import BatchScreen from "../screens/batch/ListBatchScreen";
import NewBatchScreen from "../screens/batch/NewBatchScreen";
import ViewBatchScreen from "../screens/batch/ViewBatchScreen";

import ReportScreen from "../screens/report/ReportScreen";
import ViewReportScreen from "../screens/report/ViewReportScreen";
import ReconciliationScreen from "../screens/reconciliation/ReconciliationScreen";

import ReconciliationCenterScreen from "../screens/reconciliation/ReconciliationCenterScreen";

import PermissionsScreen from "../screens/role/PermissionsScreen";
import RoleScreen from "../screens/role/RoleScreen";
import NewRoleScreen from "../screens/role/NewRoleScreen";
import EditRoleScreen from "../screens/role/EditRoleScreen";
import ViewRoleScreen from "../screens/role/ViewRoleScreen";

import NotFoundScreen from "../screens/NotFoundScreen";
import Error403Screen from "../screens/Error403Screen";
import Error404Screen from "../screens/Error404Screen";
import ErrorScreen from "../screens/ErrorScreen";
import ShiftCourseScreen from "../screens/course/ShiftCourseScreen";


export default function Router(props) {
  const { isAuthenticated, guard } = useAuth();

  return (
    <>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route exact path="/" element={<LoginScreen {...props} />} />
            <Route exact path="*" element={<Navigate to="/" replace />} />
          </>
        ) : isAuthenticated && guard.is_default_pwd === 1 ? (
          <>
            <Route
              path="/change-password"
              element={<ChangePasswordScreen {...props} />}
            />
            <Route exact path="*" element={<Navigate to="/change-password" replace />} />
          </>
        ) : (
          <>
            <Route path="/dashboard" element={<DashboardScreen {...props} />} />

            <Route
              path="/study-centers"
              element={<StudyCenterScreen {...props} />}
            />
            <Route
              path="/study-centers/new"
              element={<NewStudyCenterScreen {...props} />}
            />
            <Route
              path="/study-centers/view/:center_ref"
              element={<ViewStudyCenterScreen {...props} />}
            />
            <Route
              path="/study-centers/edit/:center_ref"
              element={<EditStudyCenterScreen {...props} />}
            />

            <Route
              path="/admissions"
              element={<AdmissionScreen {...props} />}
            />
            <Route
              path="/admissions/new"
              element={<NewAdmissionScreen {...props} />}
            />
            <Route
              path="/admissions/view/:student_ref"
              element={<ViewAdmissionScreen {...props} />}
            />
            <Route
              path="/admissions/payment/:student_ref"
              element={<PaymentAdmissionScreen {...props} />}
            />
            <Route
              path="/admissions/edit/:student_ref"
              element={<EditAdmissionScreen {...props} />}
            />
            <Route
              path="/admissions/batch_assign/:student_ref"
              element={<BatchAssignScreen {...props} />}
            />
            <Route
              path="/admissions/old"
              element={<OldAdmissionScreen {...props} />}
            />
            <Route
              path="/admissions/old/upload"
              element={<OldAdmissionDataScreen {...props} />}
            />

            <Route path="/courses" element={<CourseScreen {...props} />} />
            <Route
              path="/courses/new"
              element={<NewCourseScreen {...props} />}
            />
            <Route
              path="/courses/view/:course_ref"
              element={<ViewCourseScreen {...props} />}
            />
            <Route
              path="/courses/edit/:course_ref"
              element={<EditCourseScreen {...props} />}
            />
            <Route
              path="/courses/new_fees/:course_ref"
              element={<NewCourseFeesScreen {...props} />}
            />
            <Route
              path="/course-shift/:student_ref"
              element={<ShiftCourseScreen {...props} />}
            />
            <Route
              path="/courses/batches/view/:batch_ref"
              element={<BatchViewScreen {...props} />}
            />

            <Route path="/users" element={<UserScreen {...props} />} />
            <Route path="/users/new" element={<NewUserScreen {...props} />} />
            <Route
              path="/users/view/:user_ref"
              element={<ViewUserScreen {...props} />}
            />
            <Route
              path="/users/edit/:user_ref"
              element={<EditUserScreen {...props} />}
            />

            <Route
              path="/change-password"
              element={<ChangePasswordScreen {...props} />}
            />

            <Route path="/batches" element={<BatchScreen {...props} />} />
            <Route
              path="/batches/new"
              element={<NewBatchScreen {...props} />}
            />
            <Route
              path="/batches/view/:ba_ref"
              element={<ViewBatchScreen {...props} />}
            />

            <Route path="/reports" element={<ReportScreen {...props} />} />
            <Route
              path="/reports/:report_type"
              element={<ViewReportScreen {...props} />}
            />

            <Route
              path="/reconciliation"
              element={<ReconciliationScreen {...props} />}
            />

            <Route
              path="/center-reconciliation"
              element={<ReconciliationCenterScreen {...props} />}
            />

            <Route path="/roles" element={<RoleScreen {...props} />} />
            <Route
              path="/roles/new"
              element={<NewRoleScreen {...props} />}
            />
            <Route
              path="/roles/edit/:role_ref"
              element={<EditRoleScreen {...props} />}
            />
            <Route
              path="/roles/view/:role_ref"
              element={<ViewRoleScreen {...props} />}
            />
            <Route
              path="/roles/permissions"
              element={<PermissionsScreen {...props} />}
            />

            <Route path="*" element={<NotFoundScreen />} />
            <Route path="/error-403" element={<Error403Screen />} />
            <Route path="/error-404" element={<Error404Screen />} />
            <Route path="/error" element={<ErrorScreen />} />
          </>
        )}
      </Routes>
    </>
  );
}
