import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { API_COURSES_BATCHES_VIEW } from "../../config/Api";

function ViewBatch(props) {
  const { batchRef } = props;
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);
  const [studentData, setStudentData] = useState([]);
  const [studentCount, setStudentCount] = useState(0);

  useEffect(() => {
    fetchBatch();
  }, []);

  const fetchBatch = () => {
    props.callRequest("GET", API_COURSES_BATCHES_VIEW + '/' + batchRef, true, null).then((res) => {
      setApiData(res.data.batch);
      if (res.data.batch.students.length > 0) {
        setStudentCount(res.data.batch.students.length);
        setStudentData(res.data.batch.students);
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  const displayStudents =
    studentCount > 0 ? (
      studentData.map((student, i) => {
        return (
          <tr key={student.stud_id}>
            <td>
              <Link to={`/admissions/view/${student.stud_ref}`}>
                {student.stud_fullname}
              </Link>
            </td>
            <td className="text-center">{student.stud_reg_no}</td>
            <td className="text-end">
              {props.getCurrencyFormat(student.stud_applicable_course_amount)}
            </td>
            <td className="text-end">
              {props.getCurrencyFormat(student.stud_total_discount_amount)}
            </td>
            <td className="text-end">
              {props.getCurrencyFormat(student.stud_total_paid)}
            </td>
            <td className="text-end">
              {props.getCurrencyFormat(student.stud_total_due)}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="6">No student found</td>
      </tr>
    );

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View Batch</h5>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-6 col-lg-6">
                  <div>
                    <h4 className="fw-bold">{apiData.ba_batch_name}</h4>
                    <h5 className="fw-bold mt-2">
                      <Link to={`/courses/view/${apiData.course.course_ref}`}>
                        {apiData.course.course_name}
                      </Link>
                    </h5>
                    <span>Financial Year: {apiData.batch_financial_year}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <h5 className="fw-bold">
                    <Link to={`/study-centers/view/${apiData.center.ct_ref}`}>
                      {apiData.center.ct_name} [{apiData.center.ct_code}]
                    </Link>
                  </h5>
                  <div className="d-flex align-items-center">
                    <i className="las la-map-marker me-2"></i>
                    {apiData.center.ct_address}
                  </div>

                  <div className="d-flex align-items-center">
                    <i className="las la-phone me-2"></i>
                    <span>{apiData.center.ct_phone}</span>
                  </div>

                  <div className="d-flex align-items-center">
                    <i className="las la-envelope me-2"></i>
                    <span>{apiData.center.ct_email}</span>
                  </div>
                </div>
              </div>

              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-sm-12 mt-4">
                  <h5 className="fw-bold text-muted">Students</h5>
                </div>
                <Table className="table table-striped table-hover table-responsive">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="text-center">Registration #</th>
                      <th className="text-end">Course Fees</th>
                      <th className="text-end">Discount</th>
                      <th className="text-end">Amount Paid</th>
                      <th className="text-end">Amount Due</th>
                    </tr>
                  </thead>
                  <tbody>{displayStudents}</tbody>
                </Table>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewBatch;
