import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_USERS_VIEW, API_USER_CHANGE_PASSWORD, API_WEB_DOMAIN } from "../../config/Api";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

function ViewUser(props) {
  const navigate = useNavigate();
  const { userRef } = props;
  const [apiData, setApiData] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [formValues, setFormValues] = useState({ password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [btnEnable, setBtnEnable] = useState(false);

  const handlePasswordClose = () => setChangePassword(false);
  const handlePasswordShow = () => setChangePassword(true);

  const togglePasswordVisibility = (type) => {
    setShowPassword(!showPassword)
  };

  const validatePassword = () => {
    const { password, } = formValues;
    const errors = {};
    let isValid = true;

    if (password === "") {
      isValid = false;
      errors.password = "Password is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  let center_ref = "";
  let user_role_id = "";
  let user_role_name = "";
  let permitted_role_ids = [1, 2, 3];

  if (
    localStorage.getItem("user") !== null &&
    localStorage.getItem("center") !== null
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const center = JSON.parse(localStorage.getItem("center"));

    user_role_id = user.role.role_id;
    user_role_name = user.role.role_name;

    if (user_role_name === "CENTER ADMIN") {
      center_ref = "/" + center.ref;
    }
  }

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    props
      .callRequest("GET", API_USERS_VIEW + "/" + userRef, true, null)
      .then((res) => {
        setApiData(res.data.user);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (!validatePassword()) {
      return false;
    }
    setBtnEnable(true);
    props.callRequest("POST", API_USER_CHANGE_PASSWORD + "/" + userRef, true, formValues)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(() => {
          navigate("/users");
        }, 5000);
      }).catch((e) => {
        setBtnEnable(false);
        toast.error(`${e.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        console.log(e);
      });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View User</h5>
          <div className="d-flex justify-content-end">
            <Button
              className="link-action"
              onClick={() => {
                navigate(`/users/edit/${apiData.u_ref}`);
              }}
            >
              <i className="las la-edit me-2"></i>Edit
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-11 col-lg-11">
                  <div className="d-flex align-items-center justify-content-start">
                    {apiData.u_pic === null ? (
                      <img
                        src="/assets/images/avatar.png"
                        className="admission-profile-thumb me-3"
                      />
                    ) : (
                      <img
                        src={API_WEB_DOMAIN + apiData.u_pic}
                        alt=""
                        className="admission-profile-thumb me-3"
                      />
                    )}
                    <div className="ms-3">
                      <h4 className="fw-bold m-0 me-2">{apiData.u_fullname}</h4>
                      <h5>{apiData.role.role_name}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-3">
                <div className="col-xl-6 col-lg-6 mt-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-phone me-2"></i>
                    <span>{apiData.u_phone}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="las la-envelope me-2"></i>
                    <span>{apiData.u_email}</span>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 mt-4">
                  <div className="d-flex align-items-center">
                    <i className="las la-user me-2"></i>
                    <span>{apiData.u_username}</span>
                  </div>
                  <div className="d-flex align-items-center">
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
          <div className="text-start">
            <Link variant="primary" onClick={handlePasswordShow}>
              Change Password
            </Link>
          </div>
        </div>
      </div>
      <Modal show={changePassword} onHide={handlePasswordClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <ToastContainer />
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formValues.password || ""}
                  placeholder="Enter Password"
                  autoComplete="off"
                  onChange={handlePasswordChange}
                />
                <InputGroup.Text
                  onClick={() => togglePasswordVisibility()}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`las ${showPassword.password ? "la-eye-slash" : "la-eye"
                      }`}
                  />
                </InputGroup.Text>
              </InputGroup>
              <small className="error">
                {formValues.password === "" && formErrors.password}
              </small>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePasswordClose}>
            Close
          </Button>
          <Button disabled={btnEnable ? true : false}
            variant="primary" onClick={handlePasswordSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewUser;
